import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { getAbsoluteURL, cleanPath } from './common/common';
import { Helmet } from "react-helmet-async";
import Config from './Config';


import { useRootStore } from './hooks/use-stores';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

import { LANG_QUERY_PARAM } from './common/localization';

const App = observer( (props) => {
        const loc = useLocation();
        const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const rootStore = useRootStore();
	const [ready, setReady] = useState(false);

	//we can't use useEffect because it runs AFTER OUR CHILD COMPONENTS ARE RENDERED,
	//but we can defer rendering of the <Outlet> so that we can set the lang before rendering child routes
	useEffect( () => {
		rootStore.setLanguageBySearchParams(searchParams);
		/*setTimeout( () => { //test lang switch
			setSearchParams({ hl: 'en' });
		}, 10000);*/
		setReady(true);
	}, [searchParams.get(LANG_QUERY_PARAM)]);

        React.useEffect( () => {
                const cleanedPath = cleanPath(loc.pathname);
                if (cleanedPath.indexOf(Config.MOUNT_POINT) !== 0) {
                        const redirectTo = getAbsoluteURL(cleanedPath);
                        console.log('redirecting to mount point', redirectTo);
                        navigate(redirectTo);
                }
        }, []);

        return (<div className="App">
		<Helmet>
			<html lang={rootStore.appLang} />
			<title>Vini d'eccellenza a Milano | Enoteca Ricciardi</title>
		</Helmet>
		{ready &&
		<Outlet />
		}
	</div>
        );
} );

export default App;
