const MOUNT_POINT = process.env.REACT_APP_MOUNT_POINT;
const API_ROOT = process.env.REACT_APP_FEATHERS_URL;
const FRONTEND_BASE = process.env.REACT_APP_FRONTEND_BASE;
const FRONTEND_ROOT = process.env.REACT_APP_FRONTEND_ROOT;
const PUBLIC_URL = process.env.PUBLIC_URL

const Conf = {
        MOUNT_POINT
        ,FRONTEND_BASE
        ,FRONTEND_ROOT
        ,API_ROOT
}

export default Conf;
