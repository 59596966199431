import React, { useState, useContext, createContext } from "react";
  
import rootStoreInstance from '../stores/root-store';

const RootStoreContext = createContext();

// Provider component that wraps your app and makes the root store object ...
// ... available to any child component that calls useRootStore().
export function ProvideRootStore({ children }) {
        return <RootStoreContext.Provider value={rootStoreInstance}>{children}</RootStoreContext.Provider>;
}

// Hook for child components to get the root object and each single store...
// ... and re-render when it changes.
export const useRootStore = () => {
        return useContext(RootStoreContext);
}
export const useAuthStore = () => {
        return useContext(RootStoreContext).authStore;
}
export const useUserStore = () => {
        return useContext(RootStoreContext).userStore;
}
export const useCourseStore = () => {
        return useContext(RootStoreContext).courseStore;
}
export const useTeachersBusyTimeRangesStore = () => {
        return useContext(RootStoreContext).teachersBusyTimeRangesStore;
}
export const useProductStore = () => {
        return useContext(RootStoreContext).productStore;
}
export const useOrderStore = () => {
        return useContext(RootStoreContext).orderStore;
}
export const useOptionListsStore = () => {
        return useContext(RootStoreContext).optionListsStore;
}
/*export const useMultiCartsStore = () => {
        return useContext(RootStoreContext).multiCartsStore;
}*/
export const useSnipcartStore = () => {
        return useContext(RootStoreContext).snipcartStore;
}

