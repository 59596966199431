import { makeObservable, observable, action, autorun } from 'mobx'
import AuthStore from './auth-store';
import UserStore from './user-store';
import ProductStore from './product-store';
import OrderStore from './order-store';
import OptionListsStore from './optionlists-store';
//import MultiCartsStore from './multi-carts';
import FeathersInstance from '../common/feathers';
import SnipcartStore from './snipcart-store';
import localizedStrings, { DEFAULT_LANGUAGE, LANG_QUERY_PARAM, getAvailableLanguages } from '../common/localization';
import { snipcartLangOverrides } from '../common/localization';

class RootStore {
	static observableAnnotations = {
		classNames: observable 
		,lastUpdatedCartId: observable
		,autoPrintOrder: observable
		,isBusinessUser: observable
		,setClassNamesWithPushOrRemove: action
		,setLastUpdatedCartId: action
		,setAutoPrintOrder: action
		,setBusinessUser: action
		,appLang: observable
		,setLanguageBySearchParams: action
	}
	classNames = [];
	lastUpdatedCartId = null;
	autoPrintOrder = false;
	appLang = DEFAULT_LANGUAGE;
	isBusinessUser = false;

	constructor() {
		this.app = FeathersInstance.app;
		this.services = FeathersInstance.services;

		this._snipcartReady = new Promise( (resolve, reject) => {
			document.addEventListener('snipcart.ready', () => {
				console.log('snipcart ready');
				resolve();
			});
		});
		//setInterval( () => { this.isBusinessUser = !this.isBusinessUser; console.log('is business user') }, 6000);

		this.authStore = new AuthStore(this);
		this.userStore = new UserStore(this);
		this.productStore = new ProductStore(this);
		this.orderStore = new OrderStore(this);
		//this.multiCartsStore = new MultiCartsStore(this);
		this.optionListsStore = new OptionListsStore(this);
		this.snipcartStore = new SnipcartStore(this);

		this.authStore.reAuthenticate();

		makeObservable(this, RootStore.observableAnnotations);
	}

	setClassNamesWithPushOrRemove(type, val) {
		if (type == 'push') {
			this.classNames.push(val);
		}
		else {
			this.classNames.remove(val);
		}
	}
	setLastUpdatedCartId(val) {
		this.lastUpdatedCartId = val;
	}
	setAutoPrintOrder(val) {
		this.autoPrintOrder = val;
	}

	setLanguageBySearchParams(searchParams) {
		const available = getAvailableLanguages();
		if (searchParams.has(LANG_QUERY_PARAM) && available.indexOf(searchParams.get(LANG_QUERY_PARAM).toLowerCase()) !== -1 ) {
			this.appLang = searchParams.get(LANG_QUERY_PARAM).toLowerCase();
		}
		console.log('setting lang', this.appLang);
		localizedStrings.setLanguage( this.appLang );
		this._snipcartReady.then( () => {
			window.Snipcart.api.session.setLanguage(this.appLang, snipcartLangOverrides[this.appLang] || null);
		});
	}

	setBusinessUser(val) {
		this.isBusinessUser = val;
	}
}

//this is a singleton
const RootStoreInstance = new RootStore();

export default RootStoreInstance;
