import React, { useState, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import Breadcrumbs from '../components/breadcrumbs';
import {Button} from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Divider } from 'primereact/divider';
import { useProductStore, useOptionListsStore } from '../hooks/use-stores';
import Config from '../Config';
import Defs from '../common/defs';
import localized from '../common/localization';
import { useRootStore } from '../hooks/use-stores';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import { getProductInternalColorClass, getProductVanityName, getSnipcartDiscountProperties
	,getLocalizedDescriptionProperties, getLocalizedDescription
} from '../common/common';
import { ProgressSpinner } from 'primereact/progressspinner';

import COUNTRIES_OPTIONS from '../common/countries-options';


const ProductPage = observer( (props) => {

	const rootStore = useRootStore()
	const lang = useMemo(() => rootStore.appLang, [rootStore.appLang])

	const params = useParams();
	//console.log(params);
	const productStore = useProductStore();
	const [product, setProduct] = useState();
	const [qtyValue, setQtyValue] = useState(1);

	const vanityName = useMemo( () => {
		if (!product) {
			return '';
		}
		return getProductVanityName(product);
	}, [product]);

	const stock = useMemo( () => {
		return product && product.stocks instanceof Object && Math.max(product.stocks.Ecommerce, 0) ?
			product.stocks.Ecommerce : 0;
	}, [product]);
	const stockStatus = useMemo( () => {
		if (stock > Defs.PRODUCT_LOW_STOCK_LIMIT) {
			return {
				key: 'instock'
				,text: localized.InStock
			}
		}
		else if (stock > 0) {
			return {
				key: 'lowstock'
				,text: localized.LowStock
			}
		}
		return {
			key: 'nostock'
			,text: localized.NoStock
		}
	}, [stock]);

	const hasDiscount = useMemo( () => {
		return product && product.discountRate > 0;
	}, [product]);

	const countryTxt = useMemo( () => {
		if (!product) {
			return null;
		}
		let found = COUNTRIES_OPTIONS.find(o => o.code == product.country);
		return found ? (localized[found.name] || found.name) : (product.country || '');
	}, [product, rootStore.appLang]);

	const localizedDescription = useMemo( () => {
		if (!product) {
			return null;
		}
		return getLocalizedDescription(product, lang);
	}, [product, rootStore.appLang]);

	useEffect( () => {
		productStore.get(params.id, {
			query: {
				_hl: productStore.rootStore.appLang
				,'$select': ['_id', 'name', 'netPrice', 'grossPrice', 'producerName', 'year', 'bottleSize', 'origCategory', 'origSubcategory'
					,'country', 'region', 'subregion', 'grapesBlendTxt', 'denomination', 'vinification', 'cruClassification', 'producerCategory'
					,'cuveeType', 'productionPhilosophy', 'dosage', 'agingTxt', 'aging', 'package'
					,'abv', 'stocks.Ecommerce', 'sku', 'daneaId', 'isPublished', 'vatPerc', 'discountRate', 'images'
					,...getLocalizedDescriptionProperties(lang)
					,'isNotShippable'
				]
			}
		}).then( (data) => {
			console.log(data);
			data._colorClass = getProductInternalColorClass(data);
			setProduct(data);
		}).catch( err => {
		});
	}, [lang]);

	const getProductName = () => {
		const extraInfo = (product.year || product.bottleSize) ? ` (${[product.year, product.bottleSize].filter(v => v).join(' - ')})` : '';
		return `${product.name} - ${product.producerName}${extraInfo}`;
	}

	const addToCart = () => {
		const snipcartObj = {
			id: product._id,
			name: getProductName().slice(0,127),
			price: product.netPrice,
			url: `/ecommerce-webhooks/products?id=${product._id}`,
			quantity: qtyValue,
			metadata: { sku: product.sku, vatPerc: product.vatPerc },
			...getSnipcartDiscountProperties(product),
			...(product.isNotShippable ? { shippable: false } : null)
		}
		console.log(snipcartObj);
		window.Snipcart.api.cart.items.add(snipcartObj).then( (info) => {
			console.log(info);
		}).catch( err => {
			console.error(err);
		});

	}

	if (!product) {
		return <div className="product-detail-loader"><ProgressSpinner style={{width: '100px', height: '100px'}} strokeWidth="2" fill="var(--beige-medium)" animationDuration="1.5s"/></div>
	}

	/*const productImg = product.images instanceof Array && product.images.length > 0 && product.images[0] instanceof Object ?
		`${Config.FRONTEND_BASE}/uploads/${product.images[0].path}` : `${process.env.PUBLIC_URL}/img/new.png`;*/
	const productImgSmall = product.images instanceof Array && product.images.length > 0 && product.images[0] instanceof Object ?
		`${Config.FRONTEND_BASE}/img-transforms/500h-webp/${product.images[0].path}.webp` : `${Config.FRONTEND_ROOT}/img/bottle.svg`;
    
	return (<React.Fragment>
	<Helmet>
		<title>{`${vanityName} | Enoteca Ricciardi`}</title>
		<script type="application/ld+json">{
		JSON.stringify({
			"@context": "https://schema.org",
			"@type": "Product",
			"productID": product._id,
			"name": vanityName,
			//"description":"Unisex Facebook T-shirt, Small",
			"url": `${Config.FRONTEND_ROOT}/products/view/${product._id}/${vanityName}`,
			"image": productImgSmall,
			"brand": {
				"@type": "Brand",
				"name": product.producerName
			},
			"offers": [
				{
					"@type": "Offer",
					"price": product.grossPrice,
					"priceCurrency": "EUR",
					"itemCondition": "https://schema.org/NewCondition",
					"availability": stock > 0 ? "https://schema.org/InStock" : "https://schema.org/OutOfStock"
				}
			]
		})
		}</script>
		<meta property="og:title" content={`${vanityName} | Enoteca Ricciardi`} />
		<meta property="og:image" content={productImgSmall} />
	</Helmet>
	<Breadcrumbs />
	<ScrollToTopOnMount />

	<div className="product-page--container">
	  <div className="product-page--content ">
	    <div className="grid grid-nogutter">
	      <div className="product-page--left-col">
			<div className="product-page--image-container">
			  <img src={productImgSmall} className="h-18rem" alt={vanityName} height="300" />
			</div>                
	      </div>
	      <div className="product-page--right-col col ">
			<span>{product.producerName}</span><br/>
			<h1 className="">
			  {product.name} {product.year ? product.year : ''}
			</h1>
			<div className="product-first-facts">

			  {product.bottleSize && <React.Fragment>
			  <div className={`wine-color-badge ${product._colorClass || 'circle-neutral'}`}></div><span className="divider"></span>
			  <span>{product.bottleSize}</span>{' '}<span className="divider"></span>{' '}
			  </React.Fragment>
			  }
			  {product.abv &&
			  <span>{product.abv}</span>
			  }
			</div>

			<div className="product-price">
			  {hasDiscount ? <div>
				<div className="discount-perc"><span>-{Number(product.discountRate*100).toFixed(0)}%</span></div>
			  	<span className="discount-price">{Number(product.grossPrice*(1-product.discountRate)).toFixed(2)} €</span>
			  	<span className="original-price">{Number(product.grossPrice).toFixed(2)} €</span>
			  	
			  </div> : <div>
			  	{Number(product.grossPrice).toFixed(2)} €
			  </div>
			  }
			</div>
			<span className={`product-badge status-${stockStatus.key}`}>{stockStatus.text}</span>
			<div className="product-quantity-selector">
			    <InputNumber inputId="horizontal" min={stock == 0 ? 0 : 1} max={stock} value={qtyValue} onValueChange={(e) => setQtyValue(e.value)} showButtons buttonLayout="horizontal" step={1}
				  decrementButtonClassName="product-card__quantity__button" incrementButtonClassName="product-card__quantity__button" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"/>
			    <Button label={localized.Buy} disabled={stock == 0} onClick={addToCart} icon="pi pi-shopping-cart" className="buy-button p-button-rounded" />
			
			</div> 
			{localizedDescription &&
			<div className="product-description">
				<p dangerouslySetInnerHTML={{__html: localizedDescription }}></p>
			</div> 
			}
			<div className="product-facts">
			  
			  {[Defs.CATEGORY_WINE, Defs.CATEGORY_CHAMPAGNE, Defs.CATEGORY_SPUMANTE].indexOf(product.origCategory) !== -1 &&
			  <dl>
			    <dt>{localized.WineCategory}</dt>
			    <dd>{localized[`c_${product.origCategory}`] || product.origCategory}</dd>
			  </dl>
			  }
			  <dl>
			    <dt>{localized.WineType}</dt>
			    <dd>{product._origSubcategoryRef || product.origSubcategory}</dd>
			  </dl>
			  {product.grapesBlendTxt &&
			  <dl>
			    <dt>{localized.Grapes}</dt>
			    <dd>{product.grapesBlendTxt}</dd>
			  </dl>
			  }
			  {product.country &&
			  <dl>
			    <dt>{localized.Country}</dt>
			    <dd>{countryTxt}</dd>
			  </dl>
			  }
			  {product._regionRef &&
			  <dl>
			    <dt>{localized.Region}</dt>
			    <dd>{product._regionRef}</dd>
			  </dl>
			  }
			  {product._subregionRef &&
			  <dl>
			    <dt>{localized.Zone}</dt>
			    <dd>{product._subregionRef}</dd>
			  </dl>
			  }
			  {product._denominationRef &&
			  <dl>
			    <dt>{localized.Origin}</dt>
			    <dd>{product._denominationRef}</dd>
			  </dl>
			  }
			  {product._vinificationRef &&
			  <dl>
			    <dt>{localized.Vinification}</dt>
			    <dd>{product._vinificationRef}</dd>
			  </dl>
			  }
			  {product._cruClassificationRef &&
			  <dl>
			    <dt>{localized.CruClassification}</dt>
			    <dd>{product._cruClassificationRef}</dd>
			  </dl>
			  }
			  {product._producerCategoryRef &&
			  <dl>
			    <dt>{localized.ProducerCategory}</dt>
			    <dd>{product._producerCategoryRef}</dd>
			  </dl>
			  }
			  {product._cuveeTypeRef &&
			  <dl>
			    <dt>{localized.CuveeType}</dt>
			    <dd>{product._cuveeTypeRef}</dd>
			  </dl>
			  }
			  {product._productionPhilosophyRef &&
			  <dl>
			    <dt>{localized.ProductionPhilosophy}</dt>
			    <dd>{product._productionPhilosophyRef}</dd>
			  </dl>
			  }
			  {product._dosageRef &&
			  <dl>
			    <dt>{localized.Dosage}</dt>
			    <dd>{product._dosageRef}</dd>
			  </dl>
			  }
			  {product._agingTxtRef &&
			  <dl>
			    <dt>{localized.AgingTxt}</dt>
			    <dd>{product._agingTxtRef}</dd>
			  </dl>
			  }
			  {product._packageRef &&
			  <dl>
			    <dt>{localized.Package}</dt>
			    <dd>{product._packageRef}</dd>
			  </dl>
			  }
			 
			</div>
	      </div>
	    </div>
	  


	  </div>
	  
	</div>
	</React.Fragment>
	);
} );

export default ProductPage

