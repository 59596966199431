import { makeAutoObservable, observable, computed, action, autorun } from "mobx";

class AuthStore {
	id;
	email;
	firstName;
	lastName;
	fullUserObj = {};
	roles = [];

	token = null;

	isAuthenticated = null;
	rootStore;
	initializedPromise;
	_isLoggingIn = false;

	constructor(rootStore) {
		makeAutoObservable(this, {
			rootStore: false
			,initializedPromise: false
			,fullUserObj: observable.shallow
			,reAuthenticate: action
			,login: action
		});
		this.rootStore = rootStore;

		let _deferred = {};
		this.initializedPromise = new Promise( (resolve, reject) => {
			_deferred.resolve = resolve;
		});
		this.initializedPromise._resolve = _deferred.resolve;

		//setTimeout( () => this.logout(), 10000);
	}

	setToken(token) {
		this.token = token;
	}

	setUser(user) {
		console.log('setting user', user);
		let props = [{ key: '_id', mapped: 'id' }, 'email', 'firstName', 'lastName', 'roles'];
		props.forEach( prop => {
			let { key, mapped } = prop instanceof Object ? prop : { key: prop, mapped: prop };
			if (Object.prototype.hasOwnProperty.call(user, key)) {
				this[mapped] = user[key];
			}
		});
		this.fullUserObj = user;
		this.rootStore.setBusinessUser(user.billingAddress?.isBusiness || false);
	}

	reAuthenticate() {
		this._isLoggingIn = true;
		return this.rootStore.app.reAuthenticate().then( action(data => {
			console.log('re-auth: ok');
			console.log(data);
			if (data.user) {
				this.setUser(data.user);
			}
			const token = data.authentication?.accessToken || data.accessToken;
			if (token) {
				this.setToken(token)
			}
			//put this after set user and set token so that autorun works with all the data already set
			this.isAuthenticated = true;
		})).catch(action(() => {
			console.log('re-auth: failed');
			this.isAuthenticated = false;
		})).then( action( () => {
			this._isLoggingIn = false;
			this.initializedPromise._resolve(this.isAuthenticated);
		}));
	}

	login(email, password) {
		this._isLoggingIn = true;
		console.log('logging in');
		let errObj = null;
		return this.rootStore.app.authenticate({
			strategy: 'local'
			,email
			,password
		}).then( action(data => {
			if (data.user) {
				this.setUser(data.user);
			}
			const token = data.authentication?.accessToken || data.accessToken;
			if (token) {
				this.setToken(token)
			}
			//put this after set user and set token so that autorun works with all the data already set
			this.isAuthenticated = true;
			console.log('login successful');
		})).catch( action(err => {
			this.isAuthenticated = false;
			console.warn('login failed');
			errObj = err;
		})).then( action(() => {
			this._isLoggingIn = false;
			if (errObj) {
				throw errObj;
			}
		}));
	}

	signup(data) {
		return this.rootStore.services.users.create({
			email: data.email
			,password: data.password
			//,firstName: data.firstName
			//,lastName: data.lastName
		});
	}

	logout() {
		return this.rootStore.app.logout().then( action(() => {
			this.isAuthenticated = false;
		}));
	}

	verifySignup(verifyToken) {
		return this.rootStore.services.authManagement.create({
			action: "verifySignupLong"
			,value: verifyToken
		});
	}

	sendPasswordResetEmail(email, opts) {
		opts = opts || null;
		return this.rootStore.services.authManagement.create({
			action: 'sendResetPwd'
			,value: { email }
			,notifierOptions: { source: 'client', ...opts }
		});
	}

	confirmPasswordReset(token, password) {
		return this.rootStore.services.authManagement.create({
			action: 'resetPwdLong'
			,value: {
				token
				,password
			}
		});
	}
}

export default AuthStore;
