import { useEffect } from 'react';
import Breadcrumbs from '../components/breadcrumbs';
import { useRootStore } from '../hooks/use-stores';
import { observer } from 'mobx-react-lite';
import { Helmet } from "react-helmet-async";
import localized from '../common/localization';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';

import { data_it, data_en } from './Privacy-html';

const Privacy = observer( function(props) {
	const rootStore = useRootStore();
	const data = rootStore.appLang == 'it' ? data_it:data_en;

	useEffect( () => {
		var w = window, d = document;
		var loader = function() {
			var s = d.createElement("script"),
			tag = d.getElementsByTagName("script")[0];
			s.src = "https://cdn.iubenda.com/iubenda.js";
			tag.parentNode.insertBefore(s, tag); 
		};
		setTimeout(loader, 200);
	}, [rootStore.appLang]);

	return (
	<>
		<Helmet>
			<title>Privacy Policy | Enoteca Ricciardi Milano</title>
			<script type="application/ld+json">{
			JSON.stringify({
				"@context": "https://schema.org",
				"@type": "WebPage",
				"name": `Privacy Policy - Enoteca Ricciardi Milano`,
				"description": "Privacy Policy"
			})
			}</script>
			<meta property="og:title" content={`Privacy Policy - Enoteca Ricciardi Milano`} />
			<meta property="og:description" content={localized.PrivacyDesc} />
			<meta property="og:image" content="https://www.enoteca-ricciardi.it/shop/img/er-logo-bott.svg" />
		</Helmet>

		<ScrollToTopOnMount />
		<Breadcrumbs></Breadcrumbs>
		<div className="page-content-container">
			<div className="page-content">
				<div dangerouslySetInnerHTML={{__html: data }}>
				</div>
			</div>	
		</div>

	</>
	);
})

export default Privacy;
