import Breadcrumbs from '../components/breadcrumbs';
import { useRootStore } from '../hooks/use-stores';
import { observer } from 'mobx-react-lite';
import { Helmet } from "react-helmet-async";
import localized from '../common/localization';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';

import { data_it, data_en } from './Contacts-html';

const Contacts = observer( function(props) {
	const rootStore = useRootStore();
	const data = rootStore.appLang == 'it' ? data_it:data_en;
	return (
	<>
		<Helmet>
			<title>{localized.Contacts} | Enoteca Ricciardi Milano</title>
			<script type="application/ld+json">{
			JSON.stringify({
				"@context": "https://schema.org",
				"@type": "LocalBusiness",
				"image": "https://www.enoteca-ricciardi.it/shop/img/er-logo-bott.svg",
				"name": `${localized.Contacts} - Enoteca Ricciardi Milano`,
				"description": `${localized.ContactsDesc}`,
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "piazza Vesuvio 11",
					"addressLocality": "Milano",
					"addressRegion": "MI",
					"postalCode": "20144",
					"addressCountry": "IT"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 45.4605697,
					"longitude": 9.1578439
				},
				"url": "https://www.enoteca-ricciardi.it/shop/contact-us",
				"telephone": "+390289402627",
				"priceRange": "€€"
			})
			}</script>
			<meta property="og:title" content={`${localized.Contacts} - Enoteca Ricciardi Milano`} />
			<meta property="og:description" content={localized.ContactsDesc} />
			<meta property="og:image" content="https://www.enoteca-ricciardi.it/shop/img/er-logo-bott.svg" />
		</Helmet>
		<ScrollToTopOnMount />
		<Breadcrumbs></Breadcrumbs>
		<div className="page-content-container">
			<div className="page-content">
				<div dangerouslySetInnerHTML={{__html: data }}>
				</div>
			</div>
		</div>
	</>
	);
})

export default Contacts;
