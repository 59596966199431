import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams, Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { ProgressBar } from 'primereact/progressbar';
//import { Skeleton } from 'primereact/skeleton';
import { Dropdown } from 'primereact/dropdown';
import IsMobile from 'is-mobile';
import { useProductStore } from '../hooks/use-stores';
import useUrlParamsMap from '../hooks/use-urlparamsmap';
import useDataViewSkip from '../hooks/use-dataview-skip';
import { parseSearchParams, parseUrlParamsIntoFilters, fetchProducts
	,getAbsoluteURL, mapProductToCardObj, getProductVanityName } from '../common/common';
import { handlePlainFilter, handleOrFilters, handleAndFilters } from '../common/searchFilters';
import ProductCard from '../components/product-card';
import HeaderDesktop from '../components/header';
import ResultsHeader from '../partials/results-header';
import Pagination from '../partials/pagination';
import OtherCards from '../partials/other-cards';
import Footer from '../components/footer';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import localized from '../common/localization';
import DataViewSkeleton from '../partials/dataview-skeleton';

const Products = observer( (props) => {
	const isMobile = IsMobile();
	const [urlParamsMap, params] = useUrlParamsMap();
	const [searchParams, setSearchParams] = useSearchParams();
	const productStore = useProductStore();
	const [products, setProducts] = useState(null);
	const lang = productStore.rootStore.appLang;
	/*supports {
		text: '', producerName: ''
		,origCategory: '', origSubcategory: ''
		,bottleSize: '', year: ''
		,country: '', region: '', subregion: ''
		,priceFrom: null, priceTo: null
	})*/
	const advSearchFilters = useMemo( () => productStore.filters.slice(), [productStore.filters, productStore.filters.length]);
	const [layout, setLayout] = useState('grid');
	const [sortKey, setSortKey] = useState(null);
	const [sortOrder, setSortOrder] = useState(null);
	const [sortField, setSortField] = useState(null);
	const pageContainerRef = useRef();
	const dataViewRows = isMobile ? 16 : 36;

	/*useEffect( () => {
		//console.log(pageContainerRef);
		const paginatorClicked = (e) => {
			console.log(e.target.className);
			if (e.target && e.target.className && e.target.className.match(/paginator-element|p-paginator-icon/g)) {
				setTimeout( () => {
					window.scrollTo({
						top: 0,
						behavior: "smooth"
					});
				}, 100);
			}
		}

		let curr = pageContainerRef.current;

		if (pageContainerRef.current) {
			curr.addEventListener('click', paginatorClicked, false);
		}
		return () => {
			curr.removeEventListener('click', paginatorClicked, false);
		}
	}, [pageContainerRef.current]);
	*/

	useEffect( () => {
		//console.log(params);
		const filters = parseUrlParamsIntoFilters(urlParamsMap);
		const otherFilters = parseSearchParams(searchParams);
		productStore.setFilters([...filters, ...otherFilters]);
	}, [urlParamsMap, searchParams]);

	useEffect( () => {
		fetchProducts(productStore, lang);
	}, [lang]);

	/*const deleteFilterByNdx = (ndx) => {
		let f = productStore.filters[ndx];
		if (f.text) {
			searchParams.delete('q');
			setSearchParams({ ...searchParams });
			return;
		}
		productStore.removeFilterByNdx(ndx);
	}*/

	const filteredProducts = useMemo( () => {
		//console.log('filters', advSearchFilters);
		//console.log('recalculating filtered products');
		return productStore.items.filter(i => {
			let stock = i.stocks instanceof Object && Math.max(i.stocks.Ecommerce || 0, 0) ?
				i.stocks.Ecommerce : 0;
			return stock > 0;
		}).filter( record => {
			let valid = handleAndFilters(advSearchFilters, record);
			return valid;
		}).map( i => {
			const vanityName = getProductVanityName(i);
			return {
				...i
				,__vanityName: vanityName
				,__detailPath: getAbsoluteURL(`/products/view/${i._id}/${vanityName}`, lang)
			}
		});
	}, [productStore.items, productStore.items.length, advSearchFilters, lang]);

	const [skip, setSkipQueryParam, dataViewTemplate] = useDataViewSkip({ dataViewRows, totalItems: filteredProducts.length, pageContainerRef });

	const sortOptions = [
		{label: localized.PriceHighToLow, value: '!grossPrice'},
		{label: localized.PriceLowToHigh, value: 'grossPrice'},
	];

	const onSortChange = (event) => {
		const value = event.value;

		if (value.indexOf('!') === 0) {
			setSortOrder(-1);
			setSortField(value.substring(1, value.length));
			setSortKey(value);
		}
		else {
			setSortOrder(1);
			setSortField(value);
			setSortKey(value);
		}
	}

	const renderListItem = (data) => {
		return <div>TODO: list item</div>;
	}

	const renderGridItem = (data) => {
		const prodObj = mapProductToCardObj(data);
		return <ProductCard key={data._id} product={prodObj} detailPath={data.__detailPath} />
	}

	const itemTemplate = (product, layout) => {
		if (!product) {
			return;
		}

		if (layout === 'list')
			return renderListItem(product);
		else if (layout === 'grid')
			return renderGridItem(product);
	}

	const renderHeader = () => {
		return (
			<div className="grid grid-nogutter">
			<div className="col-6" style={{textAlign: 'left'}}>
			<Dropdown className="sorting-dropdown" options={sortOptions} value={sortKey} optionLabel="label" placeholder={localized.SortByPrice} onChange={onSortChange}/>
			</div>
			{/*<div className="col-6" style={{textAlign: 'right'}}>
			<DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
			</div>*/}
			</div>
		);
	}

	const header = renderHeader();

	return (
		<div>
			<ScrollToTopOnMount />
			<ResultsHeader searchFilters={advSearchFilters} filteredProducts={filteredProducts} />

			<div className="cards-container" ref={pageContainerRef}>
				{productStore.items.length == 0 && <>
					<ProgressBar mode="indeterminate" color="#d6a529" style={{ height: '8px' }}></ProgressBar>
					{/*<Skeleton width="30rem" height="30rem" className="mb-2"></Skeleton>*/}
					<DataViewSkeleton />
				</>}
				{productStore.items.length > 0 &&
				<DataView value={filteredProducts} layout={layout} header={header}
				 first={skip} onPage={setSkipQueryParam} paginatorTemplate={dataViewTemplate}
				 itemTemplate={itemTemplate} paginator rows={dataViewRows} pageLinkSize={4}
				 emptyMessage={<div className="no-results">
				 	<h2>{localized.NoResults}</h2>
				 </div>}
				 sortOrder={sortOrder} sortField={sortField} />
				}
			</div>

		</div>	
		
	);
} );

export default Products;
