import React, { useState, useEffect, Component, useRef } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

const DataViewSkeleton = (props) => {
	
		

	return (
		<div className="dataview-placeholder">
			<div className="col-6 sm:col-6 md:col-6 lg:col-4 xl:col-3 dw-single-placeholder">
				<div className="dw-sp-content">
					<ProgressSpinner style={{width: '100px', height: '100px'}} strokeWidth="2" fill="var(--beige-medium)" animationDuration="1.5s"/>
				</div>
			</div>
			<div className="col-6 sm:col-6 md:col-6 lg:col-4 xl:col-3 dw-single-placeholder">
				<div className="dw-sp-content">
					<ProgressSpinner style={{width: '100px', height: '100px'}} strokeWidth="2" fill="var(--beige-medium)" animationDuration="1.5s"/>
				</div>
			</div>
			<div className="col-6 sm:col-6 md:col-6 lg:col-4 xl:col-3 dw-single-placeholder">
				<div className="dw-sp-content">
					<ProgressSpinner style={{width: '100px', height: '100px'}} strokeWidth="2" fill="var(--beige-medium)" animationDuration="1.5s"/>
				</div>
			</div>
			<div className="col-6 sm:col-6 md:col-6 lg:col-4 xl:col-3 dw-single-placeholder">
				<div className="dw-sp-content">
					<ProgressSpinner style={{width: '100px', height: '100px'}} strokeWidth="2" fill="var(--beige-medium)" animationDuration="1.5s"/>
				</div>
			</div>
			<div className="col-6 sm:col-6 md:col-6 lg:col-4 xl:col-3 dw-single-placeholder">
				<div className="dw-sp-content">
				</div>
			</div>
			<div className="col-6 sm:col-6 md:col-6 lg:col-4 xl:col-3 dw-single-placeholder">
				<div className="dw-sp-content">
				</div>
			</div>
			<div className="col-6 sm:col-6 md:col-6 lg:col-4 xl:col-3 dw-single-placeholder">
				<div className="dw-sp-content">
				</div>
			</div>
			<div className="col-6 sm:col-6 md:col-6 lg:col-4 xl:col-3 dw-single-placeholder">
				<div className="dw-sp-content">
				</div>
			</div>
			<div className="col-6 sm:col-6 md:col-6 lg:col-4 xl:col-3 dw-single-placeholder">
				<div className="dw-sp-content">
				</div>
			</div>
			<div className="col-6 sm:col-6 md:col-6 lg:col-4 xl:col-3 dw-single-placeholder">
				<div className="dw-sp-content">
				</div>
			</div>
			<div className="col-6 sm:col-6 md:col-6 lg:col-4 xl:col-3 dw-single-placeholder">
				<div className="dw-sp-content">
				</div>
			</div>
			<div className="col-6 sm:col-6 md:col-6 lg:col-4 xl:col-3 dw-single-placeholder">
				<div className="dw-sp-content">
				</div>
			</div>	
		</div>
	)
};

export default DataViewSkeleton;
