
const strings = {
	en:{
		g_France: 'France'
		,g_Chile: 'Chile'
		,g_Greece: 'Greece'
		,g_Israel: 'Israel'
		,g_Italy: 'Italy'
		,g_NewZeland: 'New Zeland'
		,g_Portugal: 'Portugal'
		,g_Spain: 'Spain'
		,g_USA: 'United States of America'
	},
	it: {
		g_France: 'Francia'
		,g_Chile: 'Cile'
		,g_Greece: 'Grecia'
		,g_Israel: 'Israele'
		,g_Italy: 'Italia'
		,g_NewZeland: 'Nuova Zelanda'
		,g_Portugal: 'Portogallo'
		,g_Spain: 'Spagna'
		,g_USA: 'Stati Uniti d\'America'
	}
}

export default strings;
