const data_it = `
<h1>Pagamenti</h1>
<p>Tutti i pagamenti sul nostro sito sono assolutamente sicuri e puoi scegliere di pagare in diversi modi, elencati di seguito</p>
<h2 class='unbordered'>Carta di credito e carte prepagate dei circuiti Visa e Mastercard</h2>
<p>Se decidi di pagare con carta di credito non hai alcuna commissione e non rischi assolutamente nulla, perché non trattiamo e non conserviamo i dati dei titoli di pagamento (p.e.: numeri delle carte di credito), i quali sono trattati dai relativi fornitori del servizio di pagamento. L’effettivo pagamento avviene infatti dopo aver trasferito l’Utente su una pagina protetta e criptata del servizio bancario. Solo a transazione avvenuta, il fornitore del servizio bancario ci comunica l’esito del pagamento, senza fornire alcuna delle informazioni riguardanti la carta di credito utilizzata. Per tale ragione, non abbiamo alcun potere sull’eventuale rifiuto della carta di credito utilizzata per il pagamento. Ricciardi Srl non potrà pertanto essere ritenuta in alcun modo responsabile per conseguenze dirette o indirette derivanti dall’utilizzo della carta di credito da parte dell’utente per eseguire il pagamento dei prodotti e/o servizi acquistati.</p> Per ogni acquisto il limite di pagamento con carta di credito è pari ad € 1500,00.<p></p>
<ul class='payments-list'>
    <li><img src="${process.env.PUBLIC_URL}/img/payments/card_visa.png" alt='Visa' /></li>
    <li><img src="${process.env.PUBLIC_URL}/img/payments/card_visaElectron.png" alt='Visa Electron' /></li>
    <li><img src="${process.env.PUBLIC_URL}/img/payments/card_visaVerified.png" alt='Visa Verified' /></li>
    <li><img src="${process.env.PUBLIC_URL}/img/payments/card_mastercard.png" alt='Mastercard' /></li>
    <li><img src="${process.env.PUBLIC_URL}/img/payments/card_mastercardSecure.png" alt='Mastercard SecureCode' /></li>
</ul><br />
<h2 class='unbordered'>PayPal</h2>
<p>Se decidi di pagare tramite PayPal, dopo la conferma dell'ordine, sarai reindirizzato per il pagamento sul sito di Paypal.</p>
<ul class='payments-list'>
    <li><img src="${process.env.PUBLIC_URL}/img/payments/card_paypal.png" alt='PayPal' /></li>
</ul><br />
<h2 class='unbordered'>Bonifico bancario</h2>
<p>Se decidi di pagare tramite bonifico bancario, al momento dell'ordine ti sarà inviata una mail con le informazioni per effettuare il pagamento. In questo caso dobbiamo ricevere il pagamento prima della spedizione della merce. Il pagamento dovra’ avvenire entro 5 giorni a partire dalla data dell’ordine. Dopo la scadenza l’ordine verrà annullato. La causale del bonifico dovrà riportare il numero dell’ordine.<br /><br />Il bonifico dovrà essere intestato a:<br /><br />ENOTECA RICCIARDI SRL<br />BANK ACCOUNT : BANCO BPM - MILANO<br />IBAN : IT35 K0503401743 000000001629 <br />SWIFT BAPPIT 21675</p>
<ul class='payments-list'>
    <li><img src="${process.env.PUBLIC_URL}/img/payments/card_bonifico.png" alt='Bonfico Bancario' /></li>
</ul>
`;

const data_en = `
<h1>Payments</h1>
<p>All payments made on our website are 100% secure and you can choose to pay in different ways, listed below.</p>
<h2 class='unbordered'>Credit Card and prepaid card (Visa and Mastercard)</h2>
<p>If you decide to pay by credit card, you will not incur any additional fee or risk, because we do not process nor retain any data of payment instruments (e.g.: credit card numbers), which are processed by the relevant payment service providers. The actual payment is made after the User has been transferred to a secure encrypted page of the banking service. After the completion of the transaction, the banking service provider informs us of the outcome of the payment, without providing any information about the credit card used. For this reason, we can take no action if the credit card used for payment is declined. Therefore, we shall not be held in any way responsible for any direct and indirect consequences arising from the use of the credit card by the User to pay for purchased products and/or services.</p>
<p>For every purchase the maximum payment accepted with a credit card is up to 1500 euro.</p>
<ul class='payments-list'>
    <li><img src="${process.env.PUBLIC_URL}/img/payments/card_visa.png" alt='Visa' /></li>
    <li><img src="${process.env.PUBLIC_URL}/img/payments/card_visaElectron.png" alt='Visa Electron' /></li>
    <li><img src="${process.env.PUBLIC_URL}/img/payments/card_visaVerified.png" alt='Visa Verified' /></li>
    <li><img src="${process.env.PUBLIC_URL}/img/payments/card_mastercard.png" alt='Mastercard' /></li>
    <li><img src="${process.env.PUBLIC_URL}/img/payments/card_mastercardSecure.png" alt='Mastercard SecureCode' /></li>
</ul><br />
<h2 class='unbordered'>PayPal</h2>
<p>If you decide to pay with PayPal, after the order confirmation you will be redirected to the Paypal website to complete the payment process.</p>
<ul class='payments-list'>
    <li><img src="${process.env.PUBLIC_URL}/img/payments/card_paypal.png" alt='PayPal' /></li>
</ul><br />
<h2 class='unbordered'>Bank Transfer</h2>
<p>If you decide to pay by bank transfer, upon order placement we will send you an email with information on how to make the payment. In this case, we must receive payment before shipping the goods. Payment shall be made within 5 days from the order date. After the expiry date, the order will be cancelled. Do not forget to put the order number in the “Reason of payment” field.<br /><br />The bank transfer should be made payable to:<br /><br />ENOTECA RICCIARDI SRL<br />BANK ACCOUNT : BANCO BPM - MILANO<br />IBAN : IT35 K0503401743 000000001629<br />SWIFT BAPPIT 21675</p>
<ul class='payments-list'>
    <li><img src="${process.env.PUBLIC_URL}/img/payments/card_bonifico.png" alt='Bonfico Bancario' /></li>
</ul>
`;

export {data_it, data_en};

