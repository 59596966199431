import { tokenizedTextSearch } from '../common/common';

const ADVANCED_TEXT_SEARCH_COLUMNS = ['name', 'producerName', 'year', 'sku', 'bottleSize', 'grapesBlendTxt']; //make sure this is in the $select in the query

const FILTER_TYPE_TO_PARAM_MAP = {
	'region': 'reg'
	,'subregion': 'subreg'
	,'agingTxt': 'agetx'
	,'aging': 'age'
	,'vinification': 'vnfn'
	,'cruClassification': 'crucls'
	,'cuveeType': 'cuvee'
	,'producerCategory': 'prdcrcy'
	,'productionPhilosophy': 'phil'
};

const FILTERS_IN_SEARCH_PARAMS = ['q', 'year', 'price', 'reg', 'subreg', 'agetx', 'age', 'vnfn', 'crucls', 'dosage', 'cuvee', 'prdcrcy', 'phil'];

const handlePlainFilter = (filter, record) => {
	let valid = true;
	if (filter.text) {
		valid &= tokenizedTextSearch(filter.text, record, ADVANCED_TEXT_SEARCH_COLUMNS);
	}
	if (valid && filter.producerName) {
		valid &= (record.producerName == filter.producerName);
	}
	if (valid && filter.origCategory) {
		valid &= (String(record.origCategory).toLowerCase() == String(filter.origCategory).toLowerCase());
	}
	if (valid && filter.origSubcategory) {
		valid &= (String(record.origSubcategory).toLowerCase() == String(filter.origSubcategory).toLowerCase());
	}
	if (valid && filter.bottleSize) {
		valid &= (record.bottleSize == filter.bottleSize);
	}
	if (valid && filter.country) {
		valid &= (String(record.country).toUpperCase() == String(filter.country).toUpperCase());
	}
	if (valid && filter.type == 'year') {
		valid &= (record.year == filter.value);
	}
	if (valid && filter.type == 'region') {
		valid &= (record.region == filter.value);
	}
	if (valid && filter.type == 'subregion') {
		valid &= (record.subregion == filter.value);
	}
	if (valid && filter.type == 'agingTxt') {
		valid &= (record.agingTxt == filter.value);
	}
	if (valid && filter.type == 'vinification') {
		valid &= (record.vinification == filter.value);
	}
	if (valid && filter.type == 'cruClassification') {
		valid &= (record.cruClassification == filter.value);
	}
	if (valid && filter.type == 'dosage') {
		valid &= (record.dosage == filter.value);
	}
	if (valid && filter.type == 'cuveeType') {
		valid &= (record.cuveeType == filter.value);
	}
	if (valid && filter.type == 'producerCategory') {
		valid &= (record.producerCategory == filter.value);
	}
	if (valid && filter.type == 'productionPhilosophy') {
		valid &= (record.productionPhilosophy == filter.value);
	}

	if (valid && filter.priceFrom > 0) {
		valid &= (record.grossPrice >= filter.priceFrom);
	}
	if (valid && filter.priceTo > 0) {
		valid &= (record.grossPrice <= filter.priceTo);
	}
	if (valid && filter.agingFrom > 0) {
		valid &= (record.aging >= filter.agingFrom);
	}
	if (valid && filter.agingTo > 0) {
		valid &= (record.aging <= filter.agingTo);
	}
	if (valid && filter.discount) {
		valid &= (record.discountRate > 0)
	}
	//!!!ADD FILTER LOGIC HERE!!!
	return valid;
}

const handleOrFilters = (filter, record) => {
	let valid = filter.some( singleFilter => {
		if (singleFilter instanceof Array) {
			return handleAndFilters(singleFilter, record);
		}
		if (singleFilter.or) {
			return handleOrFilters(singleFilter.or, record);
		}
		return handlePlainFilter(singleFilter, record);
	});
	return valid;
};

const handleAndFilters = (filter, record) => {
	let valid = filter.every( singleFilter => {
		if (singleFilter instanceof Array) {
			return handleAndFilters(singleFilter, record);
		}
		if (singleFilter.or) {
			return handleOrFilters(singleFilter.or, record);
		}
		return handlePlainFilter(singleFilter, record);
	});
	return valid;
};

export {
	handlePlainFilter, handleOrFilters, handleAndFilters
	,FILTERS_IN_SEARCH_PARAMS
	,FILTER_TYPE_TO_PARAM_MAP
};
