import React, { useState } from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';

const Breadcrumbs = (props) => {
    
    const items = [
        {label: 'Computer'},
        {label: 'Notebook'},
        {label: 'Accessories'},
        {label: 'Backpacks'},
        {label: 'Item'}
    ];

    const home = { icon: 'pi pi-home', url: 'https://www.primefaces.org/primereact/showcase' }

    return null; /*(
        <div>
            <div className="breadcrumbs-container">
                <BreadCrumb className="breadcrumb-content" model={items} home={home} />
            </div>
        </div>*
    );*/
}

export default Breadcrumbs;
