import React, { useEffect, useRef } from 'react';
import { useRouteError } from 'react-router-dom';
import { Messages } from 'primereact/messages';
import { useRootStore } from '../hooks/use-stores';
import { Link } from "react-router-dom";
import HeaderDesktop from '../components/header';
import Footer from '../components/footer';
import LoginForm from '../partials/form-login';
import ServiceColumn from '../components/service-column';
import { ScrollTop } from 'primereact/scrolltop';
import { getAbsoluteURL } from '../common/common';

function ErrorBoundary(props) {
	let error = useRouteError();
	const rootStore = useRootStore();
	const msgs = useRef(null);
	console.error(error);

	useEffect( () => {
		msgs.current.show([
			{ severity: 'warn', summary: 'Error', detail: JSON.stringify(error), sticky: true }
		]);
	}, []);

	return (<div className="wrapper">
		<div className="main-layout-wrapper">
			<div className="logo-column">
				<Link to={getAbsoluteURL("/", rootStore.appLang)}>
					<img src={`${process.env.PUBLIC_URL}/img/er-logo-vertical.svg`} className="fixed" alt="Enoteca Ricciardi" />
				</Link>
				
			</div>
			<div className="main-column flex-grow-1">
				<HeaderDesktop />
				<div className="grid">
					<div className="col-12">
						<div style={{ padding: '20px 20px 50px', background: '#ffffff' }}>
						<Messages ref={msgs} />
						</div>
					</div>
				</div>
				<Footer/>
				<ScrollTop className="scroll-top-mobile"/>
			</div>		
			<ServiceColumn />
		</div>
	</div>);
}

export default ErrorBoundary;
