import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useMatch, Link } from "react-router-dom";
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import MyNavigation from '../partials/navigation';
import TopSearch from '../partials/search';
import localized from '../common/localization';

import { getAbsoluteURL } from '../common/common';
import { useAuthStore, useSnipcartStore } from '../hooks/use-stores';

const HeaderDesktop = observer( (props) => {
	//const productsMatch = useMatch(getAbsoluteURL('/products/*'));
	const snipcartStore = useSnipcartStore()
  	const authStore = useAuthStore();

	const lang = authStore.rootStore.appLang;

	return (
		<React.Fragment>
			<div className="pre-header">
				<div className="header-address ">Piazza Vesuvio 11, Milano</div> 
				<MyNavigation className="menu-mobile" addServicePages={true} addOtherLangs={true} />
				<div className="service-menu-mobile">
					<div className="logo-mobile"><span><Link className="text-link" to={getAbsoluteURL('/', authStore.rootStore.appLang)}>Enoteca Ricciardi</Link></span></div>
					<div className="service-buttons-mobile">
						<a className="service-buttons--button snipcart-checkout" aria-label={localized.Cart}>
							<i className="pi pi-shopping-cart" style={{'fontSize': '1.5em'}}></i>
							{snipcartStore.itemsCount > 0 &&
							<Badge severity="success"></Badge>	
							}								
					    </a>
					    {authStore.isAuthenticated === false &&
					    <Link to={getAbsoluteURL('/auth/login', authStore.rootStore.appLang)} className="service-buttons--button" aria-label={localized.Profile}>
						<i className="pi pi-user" style={{'fontSize': '1.5em'}}></i>
						
					    </Link>
					    }
					    {authStore.isAuthenticated &&
					    <Link to={getAbsoluteURL('/profile', authStore.rootStore.appLang)} className="service-buttons--button"  aria-label={localized.Profile}>
						<i className="pi pi-user" style={{'fontSize': '1.5em'}}></i>						
					    </Link>
					    }						
					</div>
				</div>
			</div>
			<div className="header-container">
				{/*
				<div className="message">					
					{lang == 'en' &&
					<>The wine shop will be closed from 8<sup>th</sup> to 24<sup>th</sup> August. Shipments will resume regularly from August 26<sup>th</sup>.</>
					}
					{lang != 'en' &&
					<>L'enoteca resterà chiusa dall'8 al 24 agosto. Le spedizioni riprenderanno regolarmente dal giorno 26 agosto.</>
					}
				</div>
				*/}
				<TopSearch />
				<MyNavigation />
			</div>               
		</React.Fragment>
	)
} );

export default HeaderDesktop;
