import React, { useMemo } from 'react';
//import { MegaMenu } from 'primereact/megamenu';
import { Menubar } from 'primereact/menubar';
import { NavLink, useNavigate } from "react-router-dom";
import { getAbsoluteURL } from '../common/common';
import { getLocalizedFilterClass } from '../common/search-criteria';
import { useRootStore } from '../hooks/use-stores';
import localized from '../common/localization';
import { observer } from 'mobx-react-lite'; 
import useOtherLangUrls from '../hooks/use-otherlang-urls';

const Menu = observer( (props) => { 
    const rootStore = useRootStore();
    const navigate = useNavigate();
    const [getCurrentUrlLocalized, currUrl] = useOtherLangUrls();
    const getCommandFn = (path) => {
	    return (e) => { navigate(getAbsoluteURL(path, rootStore.appLang)); }
    }

    const LinkTemplateFn = (item, options = {}) => {
	return (
	<NavLink to={item.isFinalUrl ? item.url : getAbsoluteURL(item.url, rootStore.appLang)}
		className={options.className} target={item.target} onClick={options.onClick}
	>
		<span className={options.labelClassName}>{item.label}</span>
	</NavLink>
	);
    }

    const getCurrLangLocalizedFilterClass = (key) => ( getLocalizedFilterClass(key, rootStore.appLang) );

    const items = useMemo( () => {
	    let nav = [
		{
		    label: localized.f_ViniRossi, url: `/products/class/${getCurrLangLocalizedFilterClass('vini-rossi-tutti')}`, template: LinkTemplateFn
		    /*,items: [ 
			    { label: 'Tutti i Vini Rossi', url: `/products/class/${getCurrLangLocalizedFilterClass('rossi')}`, template: LinkTemplateFn }
			    ,{ separator: true }
			    ,{ label: 'Rosso', url: `/products/class/${getCurrLangLocalizedFilterClass('vino-rosso')}`, template: LinkTemplateFn }
			    ,{ label: 'Rosso frizzante', url: `/products/class/${getCurrLangLocalizedFilterClass('vino-rosso-frizzante')}`, template: LinkTemplateFn }
			    ,{ label: 'Rosato frizzante', url: `/products/class/${getCurrLangLocalizedFilterClass('vino-rosato-frizzante')}`, template: LinkTemplateFn }
		    ]*/
		},
		{
		    label: localized.f_ViniBianchi, url: `/products/class/${getCurrLangLocalizedFilterClass('vini-bianchi-tutti')}`, template: LinkTemplateFn
		    /*,items: [
			    { label: 'Tutti i Vini Bianchi', url: `/products/class/${getCurrLangLocalizedFilterClass('bianchi')}`, template: LinkTemplateFn }
		    ]*/
		},
		{
		    label: 'Champagne', url: `/products/class/${getCurrLangLocalizedFilterClass('champagne')}`, template: LinkTemplateFn
		    /* ,items: [
			    { label: 'Tutti gli Champagne', url: `/products/class/${getCurrLangLocalizedFilterClass('champagne')}`, template: LinkTemplateFn }
			    ,{ separator: true }
			    ,{ label: 'Bianco', url: `/products/class/${getCurrLangLocalizedFilterClass('champagne-bianco')}`, template: LinkTemplateFn }
			    ,{ label: 'Rosé', url: `/products/class/${getCurrLangLocalizedFilterClass('champagne-rose')}`, template: LinkTemplateFn }
		    ] */
		},
		{
		    label: localized.f_Spumanti, url: `/products/class/${getCurrLangLocalizedFilterClass('spumanti')}`, template: LinkTemplateFn
		    /* ,items: [
			    { label: 'Tutti Gli Spumanti', url: `/products/class/${getCurrLangLocalizedFilterClass('spumanti')}`, template: LinkTemplateFn }
			    ,{ separator: true }
			    ,{ label: 'Bianco', url: `/products/class/${getCurrLangLocalizedFilterClass('spumante-bianco')}`, template: LinkTemplateFn }
			    ,{ label: 'Rosso', url: `/products/class/${getCurrLangLocalizedFilterClass('spumante-rosso')}`, template: LinkTemplateFn }
			    ,{ label: 'Rosé', url: `/products/class/${getCurrLangLocalizedFilterClass('spumante-rose')}`, template: LinkTemplateFn }
			    ,{ label: 'Dolce', url: `/products/class/${getCurrLangLocalizedFilterClass('spumante-dolce')}`, template: LinkTemplateFn }
		    ] */
		},
		{
		    label: localized.f_Altro
		    ,items: [
			    { label: localized.All, url: `/products/class/${getCurrLangLocalizedFilterClass('altro')}`, template: LinkTemplateFn }
			    ,{ separator: true }
			    ,{ label: localized.f_ViniRosati, url: `/products/class/${getCurrLangLocalizedFilterClass('vino-rosato')}`, template: LinkTemplateFn }
			    ,{ label: localized.f_ViniDolci, url: `/products/class/${getCurrLangLocalizedFilterClass('vino-dolce')}`, template: LinkTemplateFn }
			    ,{ label: localized.f_ViniLiquorosiFortificati, url: `/products/class/${getCurrLangLocalizedFilterClass('vino-liquoroso-fortificato')}`, template: LinkTemplateFn }
			    ,{ label: localized.f_Sidro, url: `/products/class/${getCurrLangLocalizedFilterClass('sidro')}`, template: LinkTemplateFn }
			    ,{ label: 'Saké', url: `/products/class/${getCurrLangLocalizedFilterClass('sake')}`, template: LinkTemplateFn }
		    ]
		},
		{
		    label: localized.f_Superalcolici
		    ,items: [
			    { label: localized.All, url: `/products/class/${getCurrLangLocalizedFilterClass('superalcolici')}`, template: LinkTemplateFn }
			    ,{ separator: true }
			    ,{ label: 'Whisky', url: `/products/class/${getCurrLangLocalizedFilterClass('superalcolici-whisky')}`, template: LinkTemplateFn }
			    ,{ label: 'Whiskey', url: `/products/class/${getCurrLangLocalizedFilterClass('superalcolici-whiskey')}`, template: LinkTemplateFn }
			    ,{ label: 'Vodka', url: `/products/class/${getCurrLangLocalizedFilterClass('superalcolici-vodka')}`, template: LinkTemplateFn }
			    ,{ label: 'Tequila', url: `/products/class/${getCurrLangLocalizedFilterClass('superalcolici-tequila')}`, template: LinkTemplateFn }
			    ,{ label: 'Rum', url: `/products/class/${getCurrLangLocalizedFilterClass('superalcolici-rum')}`, template: LinkTemplateFn }
			    ,{ label: 'Mezcal', url: `/products/class/${getCurrLangLocalizedFilterClass('superalcolici-mezcal')}`, template: LinkTemplateFn }
			    ,{ label: 'Liquore', url: `/products/class/${getCurrLangLocalizedFilterClass('superalcolici-liquore')}`, template: LinkTemplateFn }
			    ,{ label: 'Grappa', url: `/products/class/${getCurrLangLocalizedFilterClass('superalcolici-grappa')}`, template: LinkTemplateFn }
			    ,{ label: 'Gin', url: `/products/class/${getCurrLangLocalizedFilterClass('superalcolici-gin')}`, template: LinkTemplateFn }
			    ,{ label: 'Cognac', url: `/products/class/${getCurrLangLocalizedFilterClass('superalcolici-cognac')}`, template: LinkTemplateFn }
			    ,{ label: 'Calvados', url: `/products/class/${getCurrLangLocalizedFilterClass('superalcolici-calvados')}`, template: LinkTemplateFn }
			    ,{ label: 'Cachaca', url: `/products/class/${getCurrLangLocalizedFilterClass('superalcolici-cachaca')}`, template: LinkTemplateFn }
			    ,{ label: 'Brandy', url: `/products/class/${getCurrLangLocalizedFilterClass('superalcolici-brandy')}`, template: LinkTemplateFn }
			    ,{ label: 'Bas-Armagnac', url: `/products/class/${getCurrLangLocalizedFilterClass('superalcolici-bas-armagnac')}`, template: LinkTemplateFn }
			    ,{ label: 'Armagnac', url: `/products/class/${getCurrLangLocalizedFilterClass('superalcolici-armagnac')}`, template: LinkTemplateFn }
		    ]
		}
		,{
		    label: localized.DiscountedProducts, url: '/products/?discount=1', template: LinkTemplateFn, className: 'menu-promo'
		}
		//,
		//{
		//    /* label: localized.f_Rarita */
		    
		//}
	    ];
	    if (props.addServicePages) {
		    nav.push.apply(nav, [
		    	{separator:true}
			    ,{ label: localized.AboutUs, url: '/about-us', template: LinkTemplateFn }
			    ,{ label: localized.QualityAssurance, url: '/quality-assurance', template: LinkTemplateFn }
			    ,{ label: localized.Payments, url: '/payments', template: LinkTemplateFn }
			    ,{ label: localized.Orders, url: '/orders', template: LinkTemplateFn }
			    ,{ label: localized.SaleConditions, url: '/conditions', template: LinkTemplateFn }
			    ,{ label: localized.Returns, url: '/returns', template: LinkTemplateFn }
			    ,{ label: localized.Faq, url: '/faq', template: LinkTemplateFn }
			    ,{ label: localized.Contacts, url: '/contact-us', template: LinkTemplateFn }
		    ]);
	    }
	    if (props.addOtherLangs) {
		    let othLang = rootStore.appLang == 'it' ? 'en' : 'it';
		    nav.push({
			    separator:true
		    }, {
				label: <div className="flex align-items-center"><img src={`${process.env.PUBLIC_URL}/img/globe-dark.svg`} className="mr-1" alt="globe"  /> <span>{rootStore.appLang == 'it' ? 'English' : 'Italiano'}</span></div>
				,url: getCurrentUrlLocalized(othLang), isFinalUrl: true
				,template: LinkTemplateFn
		    });
	    }
	    return nav;
    }, [rootStore.appLang, currUrl]);


    return (
        <div className={`lang-${rootStore.appLang} ${props.className || ""}`}> 
            <Menubar model={items} />                
        </div>
    );
} );
export default Menu;
