import React, { useState, useMemo, Component } from 'react';
import NewPasswordForm from '../partials/form-new-password';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import localized from '../common/localization';
import { observer } from 'mobx-react-lite'; 
import { useRootStore } from '../hooks/use-stores';
import { getAbsoluteURL } from '../common/common';
import { Link } from 'react-router-dom';

const PasswordResetPage = observer( (props) => {
	
	const rootStore = useRootStore()
    const lang = useMemo(() => rootStore.appLang, [rootStore.appLang])

	return (
	<div className="login-wrapper">
		<ScrollToTopOnMount />
		<div className="form-wrapper ">
			<NewPasswordForm/>
			<Link to={getAbsoluteURL("/", rootStore.appLang)} className="back-to-home">			
				<i className="pi pi-arrow-left" style={{'fontSize': '.9em'}}></i> {localized.GoToHomepage} 
			</Link>
		</div>
	</div>
	);
});

export default PasswordResetPage;
