import React, { useEffect } from 'react';
  
function ScrollToTopOnMount(props) {
        useEffect(() => {
                setTimeout( () => {
			window.scrollTo({ top: 0, behavior: "smooth" });
                }, props.delay || 100);
        }, []);

        return null;
}

export default ScrollToTopOnMount;
