import { makeObservable, observable, computed, toJS } from 'mobx'
import BaseStore from './base-store';
import Defs from '../common/defs';

const SERVICE_NAME = 'orders';

class OrderStore extends BaseStore {
	constructor(rootStore, opts) {
		super(rootStore, opts);
	}

	loadImplementation(opt) {
		return this.stdServiceFindAndSet({
			service: SERVICE_NAME
			,...opt
		});
	}

	getImplementation(id, opt) {
		return this.stdServiceGet(id, {
			service: SERVICE_NAME
			,...opt
		});
	}

	createImplementation(data, params) {
		return this.stdServiceCreate(data, params, { service: SERVICE_NAME });
	}

	patchImplementation(id, data, params) {
		return this.stdServicePatch(id, data, params, { service: SERVICE_NAME });
	}

	removeImplementation(id) {
		return this.stdServiceRemove(id, { service: SERVICE_NAME });
	}

	getInStoreOrderFromCart({ cart, operatorId }) {
		//we have to use itemsAndVirtualRows to account for discounts and so on
		let orderEvent = {
			cartSlug: (cart.slug || cart.id)
			,itemsCount: cart.itemsCount
			,totalGross: cart.totalGross
			,totalNet: cart.totalNet
			,items: toJS(cart.itemsAndVirtualRows)
			,notes: cart.notes
			,discount: cart.discount && cart.discount.amount || 0
			,_tmpEcommerceOrder: cart._tmpEcommerceOrder
		};
		let fullOrder = {
			creationDate: new Date()
			,type: Defs.IN_STORE_ORDER
			,total: cart.totalGross
			,itemsCount: cart.itemsCount
			,inStoreEvent: orderEvent
			//using cart.items to calculate refItems because we have to exclude virtual rows (discounts, etc)
			,refItems: toJS(cart.items).map( item => ({ 
				productId: item.product._id
				,name: this._getItemProductName(item.product)
				,price: item.product.grossPrice
				,quantity: item.qty
			}) )
			,operatorId: operatorId
		};
		return fullOrder;
	}

	_getItemProductName(product) {
		return `${product.name} / ${product.producerName} / ${product.year||'-'} / ${product.bottleSize||'-'}`;
	};
}

export default OrderStore;
