const data_it = `
<h1>Chi siamo</h1>
<p>La nostra enoteca a Milano è conosciuta come luogo dove l’eccellenza dei vini è di casa. Oggi come ieri infatti offre una prestigiosa varietà di vini, liquori e distillati provenienti dall'Italia e da tutto il mondo.</p>
<h2>I prodotti dell’ Enoteca approdano anche online.</h2>
<p>L'enoteca da sempre è conosciuta e riconosciuta per la voglia di emozionare e soddisfare il cliente, per l’attenzione al servizio, per la scelta dei vini e la continua ricerca del prodotto perfetto. Tutti questi elementi hanno portato oggi l’Enoteca ad intraprendere anche la vendita online, garantendo la stessa qualità e dedizione nella scelta dei prodotti e nei servizi offerti.</p>
<p>Tra gli scaffali del negozio online sarà possibile attingere, come in Enoteca, ai prestigiosi vini e bottiglie italiane e francesi, ad autentiche rarità enologiche, a liquori e distillati provenienti dall'Italia e da tutto il mondo.</p>
`;

const data_en = `
<h1>About us</h1>
<p>Our wine shop is well known in Milan for its excellent wines. Building on a long tradition, it offers a prestigious variety of wines, liqueurs and spirits that come from Italy and from the world over. </p>
<h2>Our products are now available online</h2>
<p>Our wine shop owes its reputation to its desire to excite and satisfy customers, attentive service, selection of wines and relentless search for the perfect product. Proud of its achievements, we are ready to debut online, ensuring the same quality and dedication in the selection of products and services sold.</p>
<p>Like in a wine bar, the shelves of the e-shop are stocked with prestigious Italian and French wines and bottles, fine and rare wines, liqueurs and spirits coming from Italy and the world over. </p>
`;

export {data_it, data_en};

