import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import authClient from '@feathersjs/authentication-client';

import Config from '../Config';

const app = feathers();

// Connect to a different URL
const restClient = rest(Config.API_ROOT);

// Configure an AJAX library (see below) with that client 
app.configure(restClient.fetch(window.fetch));

app.configure(authClient({
        storageKey: 'jwtauth'
}))


// Connect to the `httpX://xxxxxxxxx/users` ... services
const services = {};
services.users = app.service('users');
services.products = app.service('products');
services.orders = app.service('orders');
services['option-lists'] = app.service('option-lists');
services.authManagement = app.service('authmgmt');

export default {
        app
        ,services
}
