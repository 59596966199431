import React, { useState, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'primereact/button';
import MegaMenuDemo from '../partials/navigation';
import TopSearch from '../partials/search';
import localized from '../common/localization';
import { observer } from 'mobx-react-lite'; 
import { useRootStore } from '../hooks/use-stores';

const Footer = observer( (props) => {   
    
    const rootStore = useRootStore()
    const lang = useMemo(() => rootStore.appLang, [rootStore.appLang])

    return (
        
<div className="footer-container" >               
  <div className=" px-4 py-6 md:px-3 lg:px-5">
      <div className="grid pb-6 border-bottom-1 border-600">
          <div className="col-12 md:col-4 lg:col-3">
              <div className=" font-bold line-height-3 mb-3 uppercase">{localized.Company}</div>
              <NavLink to="about-us" className="footer-link">{localized.AboutUs}</NavLink>
              <NavLink to="quality-assurance" className="footer-link">{localized.QualityAssurance}</NavLink>
              <NavLink to="contact-us" className="footer-link">{localized.Contacts}</NavLink>                          
          </div>
          <div className="col-12 md:col-4 lg:col-3">
              <div className=" font-bold line-height-3 mb-3 uppercase">{localized.Assistance}</div>
              <NavLink to="payments" className="footer-link">{localized.Payments}</NavLink>  
              <NavLink to="orders" className="footer-link">{localized.Orders}</NavLink>  
              <NavLink to="conditions" className="footer-link">{localized.SaleConditions}</NavLink>  
              <NavLink to="returns" className="footer-link">{localized.Returns}</NavLink>  
              <NavLink to="faq" className="footer-link">{localized.Faq}</NavLink>  

            
          </div>
          <div className="col-12 md:col-4 lg:col-3">
              <div className=" font-bold line-height-3 mb-3 uppercase">Negozio</div>
              <p className="line-height-3">Piazza Vesuvio 11<br/>20144, Milano</p>
              <p className="line-height-3">T: +39 02 8940 2627</p>
              <p className="line-height-3">
                <span className="font-bold"><i className="pi pi-clock" style={{'fontSize': '.8em'}}></i> {localized.OpeningHours}</span>
                <br/>{localized.OpeningDays} <br /> 9.30 - 13.30 <br /> 15.00 - 20.00 
              </p> 
          </div>
          <div className="col-12 md:col-4 lg:col-3">
              
          </div>
      </div>
      <div className="flex flex-wrap align-items-center justify-content-between mt-6">
         
          <div className="flex align-items-center justify-content-between md:mb-0 mb-3">
          <NavLink to="privacy-policy" className="cursor-pointer text-sm">Privacy Policy</NavLink>
          <NavLink to="cookie-policy" className="cursor-pointer text-sm ml-3">Cookie Policy</NavLink>
          
          </div>
          <div className="flex align-items-center justify-content-between">
          <a href="https://www.instagram.com/enotecaricciardi/" target="_blank" className="cursor-pointer text-500 md:ml-3 ml-2"  aria-label={localized.VisitInstagram}><i className="pi pi-instagram text-xl"></i></a>
          <a href="https://www.facebook.com/enotecaricciardi/" target="_blank" className="cursor-pointer text-500 md:ml-3 ml-2"  aria-label={localized.VisitFacebook}><i className="pi pi-facebook text-xl"></i></a>
          </div>
      </div>
      <div className="mt-3 text-xs opacity-70 line-height-3">
        Ricciardi s.r.l. <br/> Via Bartolomeo D'Alviano 4, 20146 Milano  <br/>
        Capitale Sociale 12.245,92 euro (IV)<br/>
        p.IVA/CF 07072800969 - Cam.Com MI REA 1933591<br/>
      </div>
  </div>
</div>           
         
    )
});

export default Footer;
