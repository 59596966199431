const data_it = `
<h1>Contatti</h1>
<p>
    <strong>Enoteca Principale</strong><br>piazza Vesuvio 11<br>20144 Milano
</p>
<p>
    Tel: +39 02 8940 2627<br>Fax: +39 02 5810 3559
</p>
<p>
    <a href="mailto:info@enoteca-ricciardi.it">info@enoteca-ricciardi.it</a><br>
    <a href="mailto:enotecaricciardi@gmail.com">enotecaricciardi@gmail.com</a><br>
</p>
<h2>Orari</h2>
<p>Dal Lunedì al Sabato <br /> 9.30 - 13.30 / 15.00 - 20.00 <br />Domenica, Chiuso</p>
`;

const data_en = `
<h1>Contact us</h1>
<p>
    <strong>Main wine shop</strong><br>piazza Vesuvio 11<br>20144 Milano
</p>
<p>
    Tel: +39 02 8940 2627<br>Fax: +39 02 5810 3559
</p>
<p>
    <a href="mailto:info@enoteca-ricciardi.it">info@enoteca-ricciardi.it</a><br>
    <a href="mailto:enotecaricciardi@gmail.com">enotecaricciardi@gmail.com</a><br>
</p>
<h2>Opening hours</h2>
<p>From Monday to Saturday <br /> 9.30 - 13.30 / 15.00 - 20.00 <br /> Sunday, Closed</p>
`;

export {data_it, data_en};

