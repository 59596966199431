import React, { useState, useMemo, Component } from 'react';
import { Link } from "react-router-dom";
import {Button} from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import ReactCardFlip from 'react-card-flip';
import { useSnipcartStore } from '../hooks/use-stores';
import { getAbsoluteURL, getSnipcartDiscountProperties } from '../common/common';
import localized from '../common/localization';
import Config from '../Config';
import Defs from '../common/defs';

/* props = {
 * 	product = {
 * 		id: ""
 * 		,producer: "Louis Claude Desvignes"
 * 		,name: "Morgon Le Voute Saint Vincent"
 * 		,year: "2018"
 *	 	,format: "1500 ml"
 * 		,grossPrice: 25
 * 		,netPrice: 20
 * 		,discountRate: 0.1
 * 		,stock: 1
 * 		,isNotShippable: false
 * 		,_colorClass: 'circle-neutral'
 * 	}
 * }
 */
const ADDED_TO_CART_MESSAGE_DURATION = 1500;
const NOT_ADDED = 'no', ADDING = 'adding', ADDED = 'added', ERROR = 'error';

const ProductFlipcard = (props) => {
	const snipcartStore = useSnipcartStore();
	const [qtyValue, setQtyValue] = useState(1);
	const [isFlipped, setIsFlipped] = useState(false);
	const [justAddedToCart, setJustAddedToCart] = useState(false);
	const [addedToCartStatus, setAddedToCartStatus] = useState(NOT_ADDED);

	const product = props.product;
	const productImg = props.product.images instanceof Array && props.product.images.length > 0 && props.product.images[0] instanceof Object ?
		`${Config.FRONTEND_BASE}/img-transforms/500h-webp/${props.product.images[0].path}.webp` : `${process.env.PUBLIC_URL}/img/bottle.svg`;
	const stockStatus = useMemo( () => {
		if (product.stock > Defs.PRODUCT_LOW_STOCK_LIMIT) {
			return {
				key: 'instock'
				,text: localized.InStock
			}
		}
		else if (product.stock > 0) {
			return {
				key: 'lowstock'
				,text: localized.LowStock
			}
		}
		return {
			key: 'nostock'
			,text: localized.NoStock
		}
	}, [product]);

	const hasDiscount = useMemo( () => {
		return product && product.discountRate > 0;
	}, [product]);

	const getProductName = () => {
		const extraInfo = (product.year || product.format) ? ` (${[product.year, product.format].filter(v => v).join(' - ')})` : '';
		return `${product.name} - ${product.producer}${extraInfo}`;
	}

	const vanityName = useMemo( () => {
		return getProductName();
	}, [product]);

	const switchFlipCardSide = (e) => {
		e.preventDefault();
		setIsFlipped( !isFlipped );
		if (!isFlipped) {
			setQtyValue(1);
		}
		_setAddedToCart(false);
	};

	const _setAddedToCart = (status) => {
		if (status) {
			setJustAddedToCart(true);
			setAddedToCartStatus(ADDED);
			setTimeout( () => {
				setJustAddedToCart(false);
			}, ADDED_TO_CART_MESSAGE_DURATION);
		}
		else {
			//300ms is enough to let the card flip without changing the card status/appearance
			setTimeout( () => {
				setJustAddedToCart(false);
				setAddedToCartStatus(NOT_ADDED);
			}, 300);
		}
	}

	const addToCart = () => {
		setAddedToCartStatus(ADDING);
		const snipcartObj = {
			id: product.id,
			name: getProductName().slice(0,127),
			price: product.netPrice,
			url: `/ecommerce-webhooks/products?id=${product.id}`,
			quantity: qtyValue,
			metadata: { sku: product.sku, vatPerc: product.vatPerc },
			...getSnipcartDiscountProperties(product),
			...(product.isNotShippable ? { shippable: false } : null)
		}
		console.log(snipcartObj);
		window.Snipcart.api.cart.items.add(snipcartObj).then( (info) => {
			console.log(info);
			_setAddedToCart(true);
		}).catch( err => {
			console.error(err);
		});

	}

	const showCart = () => {
		window.Snipcart.api.theme.cart.open();
	}

  return (
    <div className="col-6 sm:col-6 md:col-6 lg:col-4 xl:col-3 product-card-regular">
      <ReactCardFlip containerClassName={isFlipped ? 'flipped':''} isFlipped={isFlipped} flipDirection="horizontal">
        
        <div className="product-card">
	  {!isFlipped && <React.Fragment>
          <Link to={props.detailPath} className="product-card__info">
	    
	    <div className="product-grid-item-top">
			
				{hasDiscount &&
				<div className="discount-perc"><span>-{Number(product.discountRate*100).toFixed(0)}%</span></div>
				}
				<span className={`product-badge status-${stockStatus.key}`}>{stockStatus.text}</span>
	    	
		</div>
            <div className="product-card__image">                
              <div className="product-card__circle">
                <div className={`product-card__circle-container ${product._colorClass || 'circle-neutral'}`}>
              </div>                                     
              </div>
              <div className="product-card__bottle">
                <img src={productImg} className="" alt={vanityName} height="250"/>
              </div>
            </div>                
            <div className="product-card__info__name">
              <div className="product-card__product__producer">{product.producer}</div>
              <div className="product-card__product__name">{product.name}</div>
              {(product.year || product.format) &&
	      <div className="product-card__product__year">{[product.year, product.format].filter(v => v).join(' - ')}</div>
	      }
            </div>
          </Link>
          <div className="product-card__price">
            <div className="pl-3">
	      {hasDiscount ? 
	      	<div>
		  <span className="discount-price">{Number(product.grossPrice*(1-product.discountRate)).toFixed(2)} €</span>
		  <span className="original-price">{Number(product.grossPrice).toFixed(2)} €</span>
	      	</div>
	      	: <span className="text-xl font-bold">{product.grossPrice ? product.grossPrice.toFixed(2) : 'n/a'} €</span>
	      }
            </div>
            <div className="flex align-items-center h-full">
              <Button onClick={switchFlipCardSide} className="product-card__button-add-to-cart " aria-label={localized.Buy}>
                <i className="pi pi-shopping-cart" style={{'fontSize': '1.5em'}}></i>
              </Button>                    
            </div>
          </div>
	  </React.Fragment>}
        </div>

        <div className="product-card -retro">
	  {isFlipped && <React.Fragment>
          <div className="product-card__info">
            <div className="product-card__image">  
              <div className="product-card__in-cart">
                {snipcartStore.itemsQtyHash.hasOwnProperty(product.id) && <>
		<i className="pi pi-shopping-cart font-bold" style={{'fontSize': '1em'}}></i> {snipcartStore.itemsQtyHash[product.id]} {localized.AlreadyAddedToCart}
		</>}
              </div>                  
              <a onClick={switchFlipCardSide} className="product-card__close">
                <i className="pi pi-times-circle" style={{'fontSize': '1.7em'}}></i>
              </a>
              <div className="product-card__quantity">
	              {[NOT_ADDED, ADDING].indexOf(addedToCartStatus) !== -1 && <React.Fragment>
                  <div className="product-card__quantity__selector">
                    <InputNumber inputId="horizontal" min={product.stock == 0 ? 0 : 1} max={product.stock} value={qtyValue} onValueChange={(e) => setQtyValue(e.value)} showButtons buttonLayout="horizontal" step={1}
                          decrementButtonClassName="product-card__quantity__button" incrementButtonClassName="product-card__quantity__button" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"/>
                  </div>                                     
      	        </React.Fragment>}
	              {[ADDED].indexOf(addedToCartStatus) !== -1 && <React.Fragment>
        	        <div className="product-card__quantity__in-cart">                    
        		        <i className="pi pi-check" style={{'fontSize': '1.7em'}}></i>
        	        </div>
      	        </React.Fragment>}
      	      </div>
              <div className="product-card__bottle">
                <img src={`${process.env.PUBLIC_URL}/img/bottle.svg`} className="" alt="wine bottle" />
              </div>
            </div>
            <Link to={props.detailPath} className="product-card__info__name">
              <div className="product-card__product__producer">{product.producer}</div>
              <div className="product-card__product__name">{product.name}</div>
              {(product.year || product.format) &&
	      <div className="product-card__product__year">{[product.year, product.format].filter(v => v).join(' - ')}</div>
	      }
            </Link>
          </div>
          <div className="product-card__footer">
            {[NOT_ADDED, ADDING].indexOf(addedToCartStatus) !== -1 && <React.Fragment>
            <div className="product-card__price">
              <div className="">
	      {hasDiscount ? 
		  
	      	<div>
		 		<span className="discount-price">{Number(product.grossPrice*(1-product.discountRate)).toFixed(2)} €</span>
		  		<span className="original-price">{Number(product.grossPrice).toFixed(2)} €</span>
	      	</div>
                : <span className="text-xl">{product.grossPrice ? product.grossPrice.toFixed(2) : 'n/a'}€</span>
	      }
              </div>
              <div className="flex flex-wrap align-content-center">
                <Button onClick={addToCart} disabled={addedToCartStatus != NOT_ADDED} disabled={product.stock == 0} label={localized.Buy} icon="pi pi-shopping-cart" className="product-card__button-buy p-button-rounded" />
              </div> 
            </div>
            </React.Fragment>}
            {[ADDED].indexOf(addedToCartStatus) !== -1 && <React.Fragment>
            <div className={`product-card__confirm ${justAddedToCart ? 'fadein animation-duration-1000' : 'hidden fadeout animation-duration-100'}`}>
              <div className="">
                <span className="font-bold text-green-700">{localized.AddedToCart}</span>
              </div>
            </div>
            <div className={`product-card__continue ${justAddedToCart ? 'hidden' : 'fadein animation-duration-1000'}`}>
              <div className=" flex flex-wrap align-content-center justify-content-center">
                <Button onClick={showCart} label={localized.Cart} iconPos="left" icon="pi pi-shopping-cart" className="product-card__button-buy p-button-rounded  mr-2" />
                <Button onClick={switchFlipCardSide} label={localized.Continue} className="product-card__button-buy p-button-rounded" />
              </div> 
            </div>
            </React.Fragment>}
          </div>
	  </React.Fragment>}
        </div>

      </ReactCardFlip>
    </div>
  )
}
export default ProductFlipcard;
