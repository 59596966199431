import { makeAutoObservable, observable, computed, autorun, reaction, action } from "mobx"

//import { snipcartLangOverrides } from '../common/localization';

const testCartItemsMapperFn = item => ({ id: item.id, uniqueId: item.uniqueId, quantity: item.quantity, price: item.price });
const cartItemsMapperFn = item => ({
	id: item.id, quantity: item.quantity, price: item.price, name: item.name
	,uniqueId: item.uniqueId, customFields: item.customFields, description: item.description
	,hasTaxesIncluded: item.hasTaxesIncluded, metadata: item.metadata
});

class SnipcartStore {
	itemsCount = 0;
	items = [];
	rootStore;

	constructor(rootStore) {
		makeAutoObservable(this, {
			rootStore: false
		});
		this.rootStore = rootStore;
		const authStore = this.rootStore.authStore;

		rootStore._snipcartReady.then( () => {
			window.Snipcart.api.session.setCurrency('eur');
			this._update();
			//window.Snipcart.api.session.setLanguage(rootStore.appLang, snipcartLangOverrides[rootStore.appLang] || null);
			window.Snipcart.store.subscribe( () => {
				this._update();
			});
		});

		reaction( () => ({
			isAuthenticated: authStore.isAuthenticated
			,isBusinessUser: rootStore.isBusinessUser
			,fullUserObj: authStore.fullUserObj
		}), (values, previousValues) => {
			this.updateCartProperties(values, previousValues);
		});

		/* To re-enable the behavior allowing a free lesson in the cart only when there are paid products, uncomment this
		autorun( () => {
			if (this.items.length == 0) {
				return;
			}
			const hasOnlyFreeProducts = this.items.every( item => item.price == 0 );
			if (!hasOnlyFreeProducts) {
				return;
			}


			console.log('Woops, the user removed all paid products but left the free samples... removing');
			const itemUniqueIds = this.items.map( item => item.uniqueId ).filter( uid => !!uid );
			if (itemUniqueIds.length > 0) {
				setTimeout( () => {
					itemUniqueIds.forEach( uid => {
						window.Snipcart.api.cart.items.remove(uid).catch( err => {
							console.error('cannot remove item from cart', err);
						});
					});
				}, 250);
			}
		});*/
	}

	updateCartProperties(values, previousValues) {
		const authStore = this.rootStore.authStore;
		let update = {
			metadata: this.currentCartMetadata
		}
		if (values.isAuthenticated != previousValues.isAuthenticated && authStore.email) {
			update.email = authStore.email;	
		}
		this.rootStore._snipcartReady.then( () => {
			console.log('setting new metadata', update);
			window.Snipcart.api.cart.update(update);
		}).catch( err => {
			console.error(err);
		}).then( () => {
			const fullUserObj = authStore.fullUserObj || {};
			const userBillingAddress = fullUserObj.billingAddress || {};
			const userInfo = {
				email: fullUserObj.email
				,metadata: this.currentCartMetadata
				,billingAddress: {
					name: `${fullUserObj.firstName}${fullUserObj.lastName ? ' ' + fullUserObj.lastName : ''}`
					,address1: userBillingAddress.streetAddress || ''
					,address2: ''
					,city: userBillingAddress.city || ''
					,country: userBillingAddress.country && String(userBillingAddress.country).toUpperCase() || ''
					,postalCode: userBillingAddress.postalCode || ''
					,phone: userBillingAddress.phone || ''
				}
				//,shipToBillingAddress: true
			};
			//userInfo.shippingAddress = userInfo.billingAddress;
			if (!userInfo.billingAddress.name || !userInfo.billingAddress.address1 || !userInfo.billingAddress.city
				|| !userInfo.billingAddress.country || !userInfo.billingAddress.postalCode
			) {
				delete userInfo.billingAddress;
				console.log('Not enough info to preset billing address');
			}
			let customFields = [];
			if (userBillingAddress.fiscalCode) {
				customFields.push({
					name: 'fiscalCode'
					,value: userBillingAddress.fiscalCode
				})
			}
			if (userBillingAddress.vatNumber) {
				customFields.push({
					name: 'vatNumber'
					,value: userBillingAddress.vatNumber
				})
			}
			if (userBillingAddress.sdiCode) {
				customFields.push({
					name: 'sdiCode'
					,value: userBillingAddress.sdiCode
				})
			}
			if (customFields.length > 0) {
				userInfo.customFields = customFields;
			}

			console.log('Presetting cart with user\'s info...', userInfo);
			window.Snipcart.api.cart.update(userInfo).catch( (err) => {
				console.error(err);
			});
		});
		
	}


	_update() {
		const state = window.Snipcart.store.getState();
		//console.log(state);
		if (state.cart.items.count != this.itemsCount) {
			this.itemsCount = state.cart.items.count;
		}
		let lastItems = JSON.stringify(
			this.items.map(testCartItemsMapperFn)
		);
		let currItems = JSON.stringify(
			state.cart.items.items.map(testCartItemsMapperFn)
		);
		if ( lastItems != currItems ) {
			this.items = state.cart.items.items.map(cartItemsMapperFn);
		}
	}

	get itemsQtyHash() {
		const o = {};
		this.items.forEach( item => {
			o[item.id] = item.quantity;
		});
		return o;
	}

	get currentCartMetadata() {
		const authStore = this.rootStore.authStore;
		return {
			showCustomSignin: (authStore.isAuthenticated === null ? false : !authStore.isAuthenticated)
			,userId: authStore.isAuthenticated && authStore.id || null
			,isBusinessUser: !!this.rootStore.isBusinessUser
		}
	}
}

export default SnipcartStore;
