import { makeObservable, observable, computed } from 'mobx'
import BaseStore from './base-store';
import { getLocalizedLabel } from '../common/common';

const SERVICE_NAME = 'option-lists';

const OPTION_LISTS_CACHE_DURATION = 50*60000;
const REQUIRED_OPTION_LISTS = [
	'producerName', 'origCategory', 'origSubcategory', 'year', 'bottleSize', 'country'
		,'region', 'subregion', 'agingTxt', 'vinification', 'cruClassification', 'dosage'
		,'cuveeType', 'producerCategory', 'productionPhilosophy'
];

class OptionListsStore extends BaseStore {
	_lastFetch = null;

	constructor(rootStore, opts) {
		super(rootStore, opts);
		makeObservable(this, {
			// new
			optionLists: computed
		})
	}

	loadImplementation(opt) {
		return this.stdServiceFindAndSet({
			service: SERVICE_NAME
			,...opt
		}).then( data => {
			this._lastFetch = new Date();
			return data;
		});
	}

	getImplementation(id, opt) {
		return this.stdServiceGet(id, {
			service: SERVICE_NAME
			,...opt
		});
	}

	createImplementation(data, params) {
		return this.stdServiceCreate(data, params, { service: SERVICE_NAME });
	}

	patchImplementation(id, data, params) {
		return this.stdServicePatch(id, data, params, { service: SERVICE_NAME });
	}

	removeImplementation(id) {
		return this.stdServiceRemove(id, { service: SERVICE_NAME });
	}

	fetchRequiredOptionLists() {
		let now = new Date();
		if (!this._lastFetch || (now - this._lastFetch) >= OPTION_LISTS_CACHE_DURATION) {
			return this.load({
				fetchAll: true
				,query: {
					key: { '$in': REQUIRED_OPTION_LISTS }
				}
			});
		}
		else {
			return Promise.resolve(this.items);
		}
	}

	get optionLists() {
		let obj = {};
		this.items.forEach( listOption => {
			//not using 'value' because we want the full object instead of the id
			obj[listOption.key] = new Map();
			obj[listOption.key].set('', { label: ' --- ', val: '' });
			let sorted = listOption.options.map( option => ({
				label: getLocalizedLabel(option, this.rootStore.appLang), val: option._id
			}) ).sort( (a, b) => ( (a.label || '').localeCompare(b.label) ) );
			sorted.forEach( option => {
				obj[listOption.key].set(option.val, option);
			});
			/*
			obj[listOption.key] = [ { label: ' --- ', value: '' } ].concat(
				listOption.options.map( option => ({ label: option.label, value: option._id }) )
				.sort( (a, b) => ( (a.label || '').localeCompare(b.label) ) )
			);
			*/
		});
		return obj;
	}
}

export default OptionListsStore;
