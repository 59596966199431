import { redirect } from "react-router-dom";
import App from './App';
import Login from './pages/Login';
import Register from './pages/Register';
import Verification from './pages/Verification';
import PasswordForgotten from './pages/PasswordForgotten';
import PasswordReset from './pages/PasswordReset';
import PaymentResult from './pages/PaymentResult';
import MainLayout from './layouts/Main';
import Products from './pages/Products';
import ProductDetail from './pages/ProductDetail';
import ProfileIndex from './pages/Profile';
import { ProfileForms } from './pages/Profile';
import MyOrders from './pages/MyOrders';
import NotFound from './pages/NotFound';
import AboutUs from './static-pages/AboutUs';
import Contacts from './static-pages/Contacts';
import Faq from './static-pages/Faq';
import HomePage from './pages/HomePage';
import Orders from './static-pages/Orders';
import Payments from './static-pages/Payments';
import Privacy from './static-pages/Privacy';
import Cookies from './static-pages/Cookies';
import QualityAssurance from './static-pages/QualityAssurance';
import Returns from './static-pages/Returns';
import SaleConditions from './static-pages/SaleConditions';
import ErrorBoundary from './pages/ErrorBoundary';
import localizedStrings from 'localized-strings';
import { getAbsoluteURL } from './common/common';

function getRoutes(rootStore) {
	const loadUser = () => {
		return rootStore.authStore.initializedPromise.then( () => {
			if (!rootStore.authStore.isAuthenticated) {
				console.log('not authenticated, redirecting to /');
				return redirect(getAbsoluteURL('/', rootStore.appLang));
			}
			return rootStore.userStore.get(rootStore.authStore.id);
		});
	}

	const loadUserOrders = () => {
		return rootStore.authStore.initializedPromise.then( () => {
			if (!rootStore.authStore.isAuthenticated) {
				console.log('not authenticated, redirecting to /');
				return redirect(getAbsoluteURL('/', rootStore.appLang));
			}
			return rootStore.orderStore.load({
				fetchAll: true
				,query: { userId: rootStore.authStore.id }
			});
		});
	}

	/*
	const routes = [
		{ path: '/products/*', routerComponent: Products
			,children: [
				{ path: '/products/view/:id/*', routerComponent: ProductDetail }
			]
		}
		,{ path: '/page1', routerComponent: Page1 }
		,{ path: '/profile', routerComponent: Profile
			,children: [
				{ path: '/profile/my-orders', routerComponent: MyOrders }
			]
		}
	];
	*/

	const routes = [
	{ path: '/', element: <App />, errorElement: <ErrorBoundary />
	  ,children: [
		{ path: getAbsoluteURL('/auth/login'), element: <Login /> }
		,{ path: getAbsoluteURL('/auth/signup'), element: <Register /> }
		,{ path: getAbsoluteURL('/auth/verify/:tokenId'), element: <Verification /> }
		,{ path: getAbsoluteURL('/auth/password-forgotten'), element: <PasswordForgotten /> }
		,{ path: getAbsoluteURL('/auth/password-reset/:tokenId'), element: <PasswordReset /> }
		,{ path: getAbsoluteURL('/payment-result'), element: <PaymentResult /> }
		,{ path: getAbsoluteURL('/*'), element: <MainLayout />
		   ,children: [
			{ index: true, element: <HomePage /> }
			,{ path: 'products/*', element: <Products /> }
			,{ path: 'products/view/:id/*', element: <ProductDetail /> }
			,{ path: 'profile', element: <ProfileIndex /> 
				,children: [
					{ index: true, element: <ProfileForms />, loader: loadUser }
					,{ path: 'my-orders', element: <MyOrders />, loader: loadUserOrders }
				]
			}
			,{ path: 'conditions', element: <SaleConditions /> }
			,{ path: 'payments', element: <Payments /> }
			,{ path: 'orders', element: <Orders /> }
			,{ path: 'returns', element: <Returns /> }
			,{ path: 'faq', element: <Faq /> }
			,{ path: 'contact-us', element: <Contacts /> }
			,{ path: 'about-us', element: <AboutUs /> }
			,{ path: 'privacy-policy', element: <Privacy /> }
			,{ path: 'cookie-policy', element: <Cookies /> }
			,{ path: 'quality-assurance', element: <QualityAssurance /> }
			,{ path: '*', element: <NotFound /> }
		   ]
		}

	  ]
	}
	];

	return routes;
}

export default getRoutes;
