import React, { useState, useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Sidebar } from 'primereact/sidebar';
import SiderFilters from '../components/sider-filters';
import localized from '../common/localization';
import { getAbsoluteURL } from '../common/common';
import { FILTERS_IN_SEARCH_PARAMS, FILTER_TYPE_TO_PARAM_MAP } from '../common/searchFilters';
import COUNTRIES_OPTIONS from '../common/countries-options';
import useUrlParamsMap from '../hooks/use-urlparamsmap';
import { useRootStore, useOptionListsStore } from '../hooks/use-stores';
import Config from '../Config';

// props = {
// 	filteredProducts
// 	searchFilters
// }
//
// _text is already localized
// __text should be localized
// text is the q parameter

const ResultsHeader = observer( (props) => {
	const rootStore = useRootStore();
	const optionListsStore = useOptionListsStore();
	const navigate = useNavigate();
	const searchFilters = props.searchFilters;
	const [urlParamsMap, params] = useUrlParamsMap();
	const [searchParams, setSearchParams] = useSearchParams();
	const [visibleFullScreen, setVisibleFullScreen] = useState(false);
	const [selectedCountries, setSelectedCountries] = useState(null);

	const availableCountries = useMemo( () => {
		let s = new Set();
		props.filteredProducts.forEach( p => {
			if (p.country) {
				s.add(p.country);
			}
		});
		if (urlParamsMap.has('country') && !s.has(urlParamsMap.get('country'))) {
			s.add(urlParamsMap.get('country'));
		}
		return s;
	}, [props.filteredProducts]);

	const availableFilterOptions = useMemo( () => {
		//console.log('recalculating available filter options');
		if (!optionListsStore._loaded) {
			return null; //wait for the options
		}
		let reg = new Map(), subreg = new Map(), agingTxt = new Map()
			,cruClassification = new Map(), dosage = new Map()
			,cuvee = new Map(), producerCategory = new Map()
			,productionPhilosophy = new Map()
			,vinification = new Map()
			,hasAging = false
			//!!!ADD THE FILTER Map HERE!!!
		;
		props.filteredProducts.forEach( p => {
			if (p.aging > 0) {
				hasAging = true;
			}
			if (p.region && !reg.has(p.region)) {
				reg.set(p.region, p._regionRef);
			}
			if (p.subregion && !subreg.has(p.subregion)) {
				subreg.set(p.subregion, p._subregionRef);
			}
			if (p.agingTxt && !agingTxt.has(p.agingTxt)) {
				agingTxt.set(p.agingTxt, p._agingTxtRef);
			}
			if (p.vinification && !vinification.has(p.vinification)) {
				vinification.set(p.vinification, p._vinificationRef);
			}
			if (p.cruClassification && !cruClassification.has(p.cruClassification)) {
				cruClassification.set(p.cruClassification, p._cruClassificationRef);
			}
			if (p.dosage && !dosage.has(p.dosage)) {
				dosage.set(p.dosage, p._dosageRef);
			}
			if (p.cuveeType && !cuvee.has(p.cuveeType)) {
				cuvee.set(p.cuveeType, p._cuveeTypeRef);
			}
			if (p.producerCategory && !producerCategory.has(p.producerCategory)) {
				producerCategory.set(p.producerCategory, p._producerCategoryRef);
			}
			if (p.productionPhilosophy && !productionPhilosophy.has(p.productionPhilosophy)) {
				productionPhilosophy.set(p.productionPhilosophy, p._productionPhilosophyRef);
			}
			//!!!HANDLE THE ADDITION OF FILTER OPTION VALUES HERE!!!
		});
		let allOptionLists = optionListsStore.optionLists;
		let fRegion = searchFilters.find( f => f.type == 'region' );
		let fSubregion = searchFilters.find( f => f.type == 'subregion' );
		let fAgingTxt = searchFilters.find( f => f.type == 'agingTxt' );
		let fVinification = searchFilters.find( f => f.type == 'vinification' );
		let fCruClassification = searchFilters.find( f => f.type == 'cruClassification' );
		let fDosage = searchFilters.find( f => f.type == 'dosage' );
		let fCuvee = searchFilters.find( f => f.type == 'cuveeType' );
		let fProducerCategory = searchFilters.find( f => f.type == 'producerCategory' );
		let fProductionPhilosophy = searchFilters.find( f => f.type == 'productionPhilosophy' );

		let fAgingNum = searchFilters.find( f => f.type == 'aging' );
		hasAging = hasAging || fAgingNum;

		function checkAndAddMissingOption(f, mapObj, optListMap) {
			//if we have a filter but it's not in the available options, let's add it
			if (f && f.value && !mapObj.has(f.value)) {
				mapObj.set(f.value
					,(optListMap && optListMap.has(f.value) ?
						optListMap.get(f.value).label
						: f.value
					)
				);
			}
		}

		checkAndAddMissingOption(fRegion, reg, allOptionLists.region);
		checkAndAddMissingOption(fSubregion, subreg, allOptionLists.subregion);
		checkAndAddMissingOption(fAgingTxt, agingTxt, allOptionLists.agingTxt);
		checkAndAddMissingOption(fVinification, vinification, allOptionLists.vinification);
		checkAndAddMissingOption(fCruClassification, cruClassification, allOptionLists.cruClassification);
		checkAndAddMissingOption(fDosage, dosage, allOptionLists.dosage);
		checkAndAddMissingOption(fCuvee, cuvee, allOptionLists.cuveeType);
		checkAndAddMissingOption(fProducerCategory, producerCategory, allOptionLists.producerCategory);
		checkAndAddMissingOption(fProductionPhilosophy, productionPhilosophy, allOptionLists.productionPhilosophy);
		//!!!ADD THE checkAndAddMissionOption FOR THE FILTER HERE!!!

		const sorter = (a, b) => (a.label.localeCompare(b.label));
		return {
			hasAging: hasAging

			,region: reg
			,subregion: subreg
			,agingTxt: agingTxt
			,vinification: vinification
			,cruClassification: cruClassification
			,dosage: dosage
			,cuveeType: cuvee
			,producerCategory: producerCategory
			,productionPhilosophy: productionPhilosophy
			//!!!ADD THE Map OF THE FILTER HERE!!!

			,regionOpts: Array.from(reg, ([id, label]) => ({ val: id, label })).sort(sorter)
			,subregionOpts: Array.from(subreg, ([id, label]) => ({ val: id, label })).sort(sorter)
			,agingTxtOpts: Array.from(agingTxt, ([id, label]) => ({ val: id, label })).sort(sorter)
			,vinificationOpts: Array.from(vinification, ([id, label]) => ({ val: id, label })).sort(sorter)
			,cruClassificationOpts: Array.from(cruClassification, ([id, label]) => ({ val: id, label })).sort(sorter)
			,dosageOpts: Array.from(dosage, ([id, label]) => ({ val: id, label })).sort(sorter)
			,cuveeOpts: Array.from(cuvee, ([id, label]) => ({ val: id, label })).sort(sorter)
			,producerCategoryOpts: Array.from(producerCategory, ([id, label]) => ({ val: id, label })).sort(sorter)
			,productionPhilosophyOpts: Array.from(productionPhilosophy, ([id, label]) => ({ val: id, label })).sort(sorter)
			//!!!ADD THE Opts OF THE FILTER HERE!!!
		}
	}, [props.filteredProducts, searchFilters, rootStore.appLang, optionListsStore.optionLists, optionListsStore._loaded]);

	const countriesOptions = useMemo( () => {
		return COUNTRIES_OPTIONS.filter(c => availableCountries.has(c.code)).map(c => ({ name: localized[c.name] || c.name, code: c.code }));
	}, [availableCountries, rootStore.appLang]);

	const textFilter = useMemo( () => searchFilters.find(f => f.text), [searchFilters]);
	const classFilter = useMemo( () => searchFilters.find(f => f.type == 'class'), [searchFilters]);
	const discountFilter = useMemo( () => searchFilters.find(f => f.type == 'discount'), [searchFilters]);

	const pageTitle = useMemo( () => {
		if (textFilter){
			return String(localized.ResultsForKeyword).replace('{0}', textFilter.text) + ' | Enoteca Ricciardi'
		}
		else if (classFilter) {
			return (localized[classFilter.__text] || classFilter.__text) + ' | Enoteca Ricciardi';
		}
		else {
			return localized.ProductsTitle + ' | Enoteca Ricciardi';
		}
	}, [textFilter, classFilter]);

	function _mapCountryFilter(f) {
		let codes = f.__text.split(/,\s*/);
		let mappedText = codes.map( c => {
			let found = countriesOptions.find(o => o.code == c);
			return found ? (localized[found.name] || found.name) : c;
		}).join(', ');
		return {
			...f
			,_text: mappedText
		}
	}

	function _mapOptionFilter(f, mapObj) {
		let vals = f.__text.split(/,\s*/);
		let mappedText = vals.map( i => {
			let found = mapObj.get(i);
			return found || i;
		}).join(', ');
		return {
			...f
			,_text: mappedText
		}
	}

	const mappedSearchFilters = useMemo( () => {
		return searchFilters.map(f => {
			if (!availableFilterOptions) { //not ready yet
				return f;
			}

			if (f.type == 'country') {
				return _mapCountryFilter(f);
			}
			else if (f.type == 'region') {
				return _mapOptionFilter(f, availableFilterOptions.region);
			}
			else if (f.type == 'subregion') {
				return _mapOptionFilter(f, availableFilterOptions.subregion);
			}
			else if (f.type == 'agingTxt') {
				return _mapOptionFilter(f, availableFilterOptions.agingTxt);
			}
			else if (f.type == 'vinification') {
				return _mapOptionFilter(f, availableFilterOptions.vinification);
			}
			else if (f.type == 'cruClassification') {
				return _mapOptionFilter(f, availableFilterOptions.cruClassification);
			}
			else if (f.type == 'dosage') {
				return _mapOptionFilter(f, availableFilterOptions.dosage);
			}
			else if (f.type == 'cuveeType') {
				return _mapOptionFilter(f, availableFilterOptions.cuveeType);
			}
			else if (f.type == 'producerCategory') {
				return _mapOptionFilter(f, availableFilterOptions.producerCategory);
			}
			else if (f.type == 'productionPhilosophy') {
				return _mapOptionFilter(f, availableFilterOptions.productionPhilosophy);
			}
			else if (f.type == 'aging') {
				return {
					...f
					,_text: `${localized.Aging} ${f.__text}`
				}
			}
			//!!!ADD THE FILTER _mapOptionFilter CALL HERE!!!
			return f;
		}).map(f => {
			return {
				...f
				,_calcText: (f._text || (f.__text ? (localized[f.__text] || f.__text) : f.text))
			};
		}).filter(f => {
			if (textFilter) {
				return true;
			}
			return f.type != 'class'
		});
	}, [countriesOptions, availableFilterOptions, searchFilters, textFilter]);

	const onSidebarClose = () => { 
		setVisibleFullScreen(false);
	}

	useEffect( () => {
		let countryFilters = searchFilters.find(f => f.type == 'country');
		if (!countryFilters) {
			setSelectedCountries([]);
			return;
		}
		let arr = countryFilters.or ? countryFilters.or.map(f => f.country) : [countryFilters.country];
		let values = arr.flatMap(v => {
			let option = countriesOptions.find(o => o.code == v);
			if (!option) {
				return [];
			}
			return [option];
		});
		setSelectedCountries(values);
	}, [urlParamsMap, searchParams, searchFilters]);

	useEffect( () => {
		setTimeout( () => {
			optionListsStore.fetchRequiredOptionLists();
		}, 1600);
	}, []);

	const setCountriesValue = (e) => {
		setSelectedCountries(e.value);
		if (e.value.length > 0) {
			urlParamsMap.set('country', e.value.map(i => i.code).join('-'));
		}
		else {
			urlParamsMap.delete('country');
		}
		navigateToUrlByParams(urlParamsMap, searchParams);
	}

	const navigateToUrlByParams = (urlParamsMap, searchParams) => {
		//TODO: remove incompatible filters, for instance champagne ones when the type is not champagne
		let filterParams = [...urlParamsMap].flatMap(i => i).join('/');
		//we need to reset the skip param when filters are changed
		searchParams.delete('skip');
		let dstUrl = new URL(`/products/${filterParams}`, window.location.origin);
		dstUrl.search = `?${searchParams.toString()}`;
		//no need to inject the language since it's already in searchParams
		//navigate( getAbsoluteURL(dstUrl.toString().replace(new RegExp(`^${window.location.origin}`), '')) );
		navigate( getAbsoluteURL(dstUrl.pathname + dstUrl.search) );
	}



	const countryTemplate = (option) => {
		return (
			<div className="country-item">
			<div>{option.name}</div>
			</div>
		);
	}
	const selectedCountriesTemplate = (option) => {
		if (option) {
			return (
				<div className="country-item country-item-value">
				<div>{option.name}</div>
				</div>
			);
		}

		return localized.SelectCountries;
	}

	const panelFooterTemplate = () => {
		const selectedItems = selectedCountries;
		const length = selectedItems ? selectedItems.length : 0;
		return (
			<div className="py-2 px-3">
			<b>{length}</b> item{length > 1 ? 's' : ''} selected.
			</div>
		);
	}

	const deleteFilter = (ndx) => {
		let f = mappedSearchFilters[ndx];
		let prop = FILTER_TYPE_TO_PARAM_MAP[f.type] || f.type;
		if (urlParamsMap.has(prop)) {
			urlParamsMap.delete(prop);
		}
		else if (searchParams.has(prop)) {
			searchParams.delete(prop);
		}
		navigateToUrlByParams(urlParamsMap, searchParams);
	}

	const clearFilters = () => {
		urlParamsMap.forEach( (val, k) => {
			console.log(val, k);
			/*if (k == 'class') {
				return;
			}*/
			urlParamsMap.delete(k);
		});
		searchParams.forEach( (val, k) => {
			if (FILTERS_IN_SEARCH_PARAMS.indexOf(k) !== -1) {
				searchParams.delete(k);
			}
		});
		navigateToUrlByParams(urlParamsMap, searchParams);
	}

	const getTitleByFilters = () => {
		if (textFilter) {
			return (
				<h1>{localized.formatString(localized.ResultsForKeyword, <span>{textFilter.text}</span>)}
				</h1>
			);
		}
		if (classFilter) {
			return (
				<h1><span>{localized[classFilter.__text] || classFilter.__text}</span>
				</h1>
			);
		}
		else if (discountFilter) {
			return (
				<h1><span>{localized[discountFilter.__text] || discountFilter.__text}</span>
				</h1>
			);
		}
		return (
			<h1><span>{localized.ProductsTitle}</span>
			</h1>
		);

	}

	const metaDesc = useMemo( () => {
		//console.log(classFilter);
		let d;
		if (classFilter) {
			d = localized[`ProductsDesc_${classFilter.__text}`];
		}
		else if (discountFilter) {
			d = localized.ProductsPromoDesc;
		}
		return d || localized.ProductsDesc;
	}, [classFilter, discountFilter]);

	return (
	    <React.Fragment>
	        <Helmet>
			<title>{pageTitle}</title>
			<script type="application/ld+json">{
				JSON.stringify({
					"@context":"https://schema.org",
					"@type":"ItemList",
					"itemListElement": props.filteredProducts.map( (p, ndx) => ({
						"@type":"ListItem",
						"position": ndx+1,
						"url": `${Config.FRONTEND_BASE}${p.__detailPath}`
					}))
				})
			}</script>
			<meta name="description" content={metaDesc} />
		</Helmet>
	    
		<div className="results-header">
		    <div className="col-12">
		    {getTitleByFilters()}
		    </div>
		    <div className="col-12 select-filters">
			<MultiSelect value={selectedCountries} options={countriesOptions} selectionLimit={5} onChange={setCountriesValue} optionLabel="name" fixedPlaceholder={true} filter className="multiselect-custom mobile-hide"
				itemTemplate={countryTemplate} selectedItemTemplate={selectedCountriesTemplate} showSelectAll={false}  />
			<Button label={localized.all_filters} className=" p-button-rounded" icon="pi pi-sliders-h" iconPos="right"  onClick={() => setVisibleFullScreen(true)}/>
		    </div>
		    {availableFilterOptions && mappedSearchFilters.length > 0 && <>
			    <Divider />
			    <div className="col-12 selected-filters">
				{mappedSearchFilters.map( (f, ndx) => (
					<Button key={ndx}
						label={f._calcText.length > 36 ? `${f._calcText.slice(0,36)}...` : f._calcText}
						title={f._calcText}
						className="p-button-rounded p-button-sm" icon="pi pi-times" iconPos="right"
						onClick={ () => deleteFilter(ndx) }
					/>
				) )}
				<Button label={localized.ResetFilters}  className="p-button-rounded p-button-sm p-button-outlined " onClick={clearFilters}  />
			    </div>
		    </>
		    }
		</div>
		
		<SiderFilters sidebarOpen={visibleFullScreen} onSidebarClose={onSidebarClose}
			filteredProducts={props.filteredProducts}
			searchFilters={props.searchFilters}
			urlParamsMap={urlParamsMap}
			searchParams={searchParams}
			navigateToUrlByParams={navigateToUrlByParams}
			clearFilters={clearFilters}
			countriesState={[selectedCountries, countriesOptions, setCountriesValue]}
			availableFilterOptions={availableFilterOptions}
		/>
	    
	    </React.Fragment>
	)
} );

export default ResultsHeader;
