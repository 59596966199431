import { makeObservable, observable, computed } from 'mobx'
import BaseStore from './base-store';

const SERVICE_NAME = 'users';

class UserStore extends BaseStore {
	constructor(rootStore, opts) {
		super(rootStore, opts);
	}

	loadImplementation(opt) {
		return this.stdServiceFindAndSet({
			service: SERVICE_NAME
			,...opt
		});
	}

	getImplementation(id, opt) {
		return this.stdServiceGet(id, {
			service: SERVICE_NAME
			,...opt
		});
	}

	createImplementation(data, params) {
		return this.stdServiceCreate(data, params, { service: SERVICE_NAME });
	}

	patchImplementation(id, data, params) {
		return this.stdServicePatch(id, data, params, { service: SERVICE_NAME }).then( res => {
			try {
				if (this.rootStore.authStore && id == this.rootStore.authStore.id) {
					console.log('Updating current user\'s data');
					this.rootStore.authStore.setUser(res);
				}
			} catch(err) {
				console.error(err);
			}
			return res;
		});
	}

	removeImplementation(id) {
		return this.stdServiceRemove(id, { service: SERVICE_NAME });
	}
}

export default UserStore;
