import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useLoaderData, useMatch, useNavigate, Outlet } from "react-router-dom";
import { redirect } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import HeaderDesktop from '../components/header';
import { TabMenu } from 'primereact/tabmenu';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import { useRootStore, useUserStore, useAuthStore } from '../hooks/use-stores';
import { getAbsoluteURL } from '../common/common';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import { validatePassword } from '../common/common';
import localized from '../common/localization';

const SEX_OPTIONS = [
	{ name: 'Male', code: 'M' },
	{ name: 'Female', code: 'F' },
	{ name: 'UndefinedSex', code: 'U' }
];

/*******************************
 * THIS IS THE PASSWORD FORM
 * ****************************/
const PasswordForm = observer( (props) => {
	const defaultValues = {
		password: ''
		,'password-confirm': ''
	}
	const { control, formState: { errors }, handleSubmit, reset, watch } = useForm({ defaultValues });
	const watchPassword = watch('password');

	const onSubmit = (data) => {
		console.log(data);
		props.onSubmit(data).then( (res) => {
			//res true means no failure
			if (res) {
				reset();
			}
		});
	};

	const getFormErrorMessage = (name) => {
		return errors[name] && <small className="p-error">{errors[name].message}</small>
	};

	const getPasswordConfirmationValidator = (value) => {
		if (!value || watchPassword === value) {
			return true;
		}
		return localized.PasswordsMustBeEqual;
        };

	return (
	    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
              <div className="formgrid grid ">
                <div className="field col-12 md:col-6 lg:col-4">
                  <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>{localized.fl_NewPassword}*</label>
		  <Controller name="password" control={control} rules={{ required: localized.PasswordIsRequired, validate: validatePassword }}
		   render={({ field, fieldState }) => (
		     <Password id={field.name} {...field} className={classNames('text-base', 'text-color', 'surface-overlay', 'p-2', 'border-1', 'border-solid', 'surface-border', 'border-round', 'appearance-none', 'outline-none', 'focus:border-primary', 'w-full', { 'p-invalid': fieldState.invalid })} />
		   )} />
		  {getFormErrorMessage('password')}
                </div>
                <div className="field col-12 md:col-6 lg:col-4">
                  <label htmlFor="password-confirm" className={classNames({ 'p-error': errors.password })}>{localized.fl_RepeatNewPassword}*</label>
		  <Controller name="password-confirm" control={control} rules={{ required: localized.ConfirmPasswordIsRequired, validate: getPasswordConfirmationValidator }} render={({ field, fieldState }) => (
		    <Password id={field.name} {...field} feedback={false} className={classNames('text-base', 'text-color', 'surface-overlay', 'p-2', 'border-1', 'border-solid', 'surface-border', 'border-round', 'appearance-none', 'outline-none', 'focus:border-primary', 'w-full', { 'p-invalid': fieldState.invalid })} />
		    )} />
		  {getFormErrorMessage('password-confirm')}
                </div>
              </div>
              <div className="formgrid grid ">
            	<div className="field col-12 md:col-6 lg:col-4">
                  <Button label={localized.FormSave} aria-label="Submit" disabled={props.submitting} />
                </div>
              </div>
	    </form>
	);
} );


/*******************************
 * THIS IS THE PROFILE FORM
 * ****************************/
const ProfileForm = observer( (props) => {
	const rootStore = useRootStore();
	const editedUser = props.user;
	const defaultValues = {
		email: editedUser.email,
		firstName: editedUser.firstName || '',
		lastName: editedUser.lastName || '',
		birthdate: editedUser.birthdate ? new Date(editedUser.birthdate) : null,
		sex: editedUser.sex || '',
		isBusiness: editedUser.billingAddress?.isBusiness || false,
		companyName: editedUser.billingAddress?.companyName || '',
		fiscalCode: editedUser.billingAddress?.fiscalCode || '',
		vatNumber: editedUser.billingAddress?.vatNumber || '',
		sdiCode: editedUser.billingAddress?.sdiCode || ''
	}
	const { control, formState: { errors }, handleSubmit, reset, watch } = useForm({ defaultValues, shouldUnregister: true });
	const isBusinessFormValue = watch('isBusiness', defaultValues.isBusiness);

	const sexOptions = useMemo( () => {
		return SEX_OPTIONS.map(o => ({ ...o, name: localized[o.name] || o.name }) )
	}, [rootStore.appLang])

	const onSubmit = (data) => {
		console.log(data);
		props.onSubmit(data);
		//reset();
	};

	const getFormErrorMessage = (name) => {
		return errors[name] && <small className="p-error">{errors[name].message}</small>
	};

	return (
	    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">

              <div className="formgrid grid mt-6 pl-2">
	        <div className="field-radiobutton mr-4">
		  <Controller name="isBusiness" control={control} render={({ field, fieldState }) => (
		    <RadioButton id={`${field.name}-0`} {...field} value={false} className={classNames({ 'p-invalid': fieldState.invalid })}
		     onChange={(e) => field.onChange(e.value)} checked={!field.value} />
		  )} />
		  <label htmlFor="isBusiness-0" className={classNames({ 'p-error': errors.isBusiness })}>{localized.RegularClientOption}</label>
		  {getFormErrorMessage('isBusiness')}
                </div>
	        <div className="field-radiobutton">
		  <Controller name="isBusiness" control={control} render={({ field, fieldState }) => (
		    <RadioButton id={`${field.name}-1`} {...field} value={true} className={classNames({ 'p-invalid': fieldState.invalid })}
		     onChange={(e) => field.onChange(e.value)} checked={field.value} />
		  )} />
		  <label htmlFor="isBusiness-1" className={classNames({ 'p-error': errors.isBusiness })}>{localized.BusinessClientOption}</label>
		  {getFormErrorMessage('isBusiness')}
                </div>
	      </div>

              {isBusinessFormValue && <>
              <div className="formgrid grid mt-2">
                <div className="field col-12 md:col-12 lg:col-8">
                  <label htmlFor="companyName" className={classNames({ 'p-error': errors.companyName })}>{localized.fl_Company}</label>
		  <Controller name="companyName" control={control} rules={{ required: localized.CompanyNameIsRequired }} render={({ field, fieldState }) => (
		    <InputText id={field.name} {...field} autoFocus className={classNames('text-base', 'text-color', 'surface-overlay', 'p-2', 'border-1', 'border-solid', 'surface-border', 'border-round', 'appearance-none', 'outline-none', 'focus:border-primary', 'w-full', { 'p-invalid': fieldState.invalid })} />
		  )} />
		  {getFormErrorMessage('companyName')}
                </div>                            
              </div>

              <div className="formgrid grid">
                <div className="field col-12 md:col-6 lg:col-4">
                  <label htmlFor="vatNumber" className={classNames({ 'p-error': errors.vatNumber })}>{localized.fl_VatNo}*</label>
		  <Controller name="vatNumber" control={control} rules={{ required: localized.VatNumberIsRequired }} render={({ field, fieldState }) => (
		    <InputText id={field.name} {...field} autoFocus className={classNames('text-base', 'text-color', 'surface-overlay', 'p-2', 'border-1', 'border-solid', 'surface-border', 'border-round', 'appearance-none', 'outline-none', 'focus:border-primary', 'w-full', { 'p-invalid': fieldState.invalid })} />
		  )} />
		  {getFormErrorMessage('vatNumber')}
                </div>
                <div className="field col-12 md:col-6 lg:col-4">
                  <label htmlFor="sdiCode" className={classNames({ 'p-error': errors.sdiCode })}>{localized.fl_SdiCode}</label>
		  <Controller name="sdiCode" control={control} render={({ field, fieldState }) => (
		    <InputText id={field.name} {...field} autoFocus className={classNames('text-base', 'text-color', 'surface-overlay', 'p-2', 'border-1', 'border-solid', 'surface-border', 'border-round', 'appearance-none', 'outline-none', 'focus:border-primary', 'w-full', { 'p-invalid': fieldState.invalid })} />
		  )} />
		  {getFormErrorMessage('sdiCode')}
                </div>               
              </div>
	      </>}

              <div className="formgrid grid ">
                <div className="field col-12 md:col-6 lg:col-4">
                  <label htmlFor="firstName" className={classNames({ 'p-error': errors.firstName })}>{localized.fl_FirstName}</label>
		  <Controller name="firstName" control={control} rules={{ required: localized.FirstNameIsRequired }} render={({ field, fieldState }) => (
		    <InputText id={field.name} {...field} autoFocus className={classNames('text-base', 'text-color', 'surface-overlay', 'p-2', 'border-1', 'border-solid', 'surface-border', 'border-round', 'appearance-none', 'outline-none', 'focus:border-primary', 'w-full', { 'p-invalid': fieldState.invalid })} />
		  )} />
		  {getFormErrorMessage('firstName')}
                </div>
                <div className="field col-12 md:col-6 lg:col-4">
                  <label htmlFor="lastName" className={classNames({ 'p-error': errors.lastName })}>{localized.fl_LastName}</label>
		  <Controller name="lastName" control={control} rules={{ required: localized.LastNameIsRequired }} render={({ field, fieldState }) => (
		    <InputText id={field.name} {...field} autoFocus className={classNames('text-base', 'text-color', 'surface-overlay', 'p-2', 'border-1', 'border-solid', 'surface-border', 'border-round', 'appearance-none', 'outline-none', 'focus:border-primary', 'w-full', { 'p-invalid': fieldState.invalid })} />
		  )} />
		  {getFormErrorMessage('lastName')}
                </div>               
              </div>

              <div className="formgrid grid ">
                <div className="field col-12 md:col-4">
                  <label htmlFor="birthdate" className={classNames({ 'p-error': errors.birthdate })}>{localized.fl_BirthDate}</label>
		  <br/>
		  <Controller name="birthdate" control={control} render={({ field, fieldState }) => (
		    <Calendar id={field.name} {...field} dateFormat="dd/mm/yy" autoFocus className={classNames('text-base', 'text-color', 'surface-overlay', 'p-2', 'border-1', 'border-solid', 'surface-border', 'border-round', 'appearance-none', 'outline-none', 'focus:border-primary', 'w-full', { 'p-invalid': fieldState.invalid })} />
		  )} />
		  {getFormErrorMessage('birthdate')}
                </div>
                <div className="field col-12 md:col-6 lg:col-4">
                  <label htmlFor="sex" className={classNames({ 'p-error': errors.sex })}>{localized.fl_Sex}</label>
		  <br/>
		  <Controller name="sex" control={control} render={({ field, fieldState }) => (
		    <Dropdown options={sexOptions} optionLabel="name" optionValue="code" placeholder="Seleziona" id={field.name} {...field} autoFocus className={classNames('text-base', 'text-color', 'surface-overlay', 'p-2', 'border-1', 'border-solid', 'surface-border', 'border-round', 'appearance-none', 'outline-none', 'focus:border-primary', 'w-full', { 'p-invalid': fieldState.invalid })} />
		  )} />
		  {getFormErrorMessage('sex')}
                </div>
              </div>               

              <div className="formgrid grid">
                <div className="field col-12 md:col-6 lg:col-4">
                  <label htmlFor="fiscalCode" className={classNames({ 'p-error': errors.fiscalCode })}>{localized.fl_FiscalCode}</label>
		  <Controller name="fiscalCode" control={control} render={({ field, fieldState }) => (
		    <InputText id={field.name} {...field} autoFocus className={classNames('text-base', 'text-color', 'surface-overlay', 'p-2', 'border-1', 'border-solid', 'surface-border', 'border-round', 'appearance-none', 'outline-none', 'focus:border-primary', 'w-full', { 'p-invalid': fieldState.invalid })} />
		  )} />
		  {getFormErrorMessage('fiscalCode')}
                </div>
              </div>

              <div className="formgrid grid ">
                <div className="field col-12 md:col-6 lg:col-4">
                  <label htmlFor="email">{localized.fl_email}</label>
		  <Controller name="email" control={control} render={({ field, fieldState }) => (
		    <InputText id={field.name} {...field} disabled autoFocus className={classNames('text-base', 'text-color', 'surface-overlay', 'p-2', 'border-1', 'border-solid', 'surface-border', 'border-round', 'appearance-none', 'outline-none', 'focus:border-primary', 'w-full', { 'p-invalid': fieldState.invalid })} />
		  )} />
                </div>                            
              </div>
              <div className="formgrid grid ">
            	<div className="field col-12 md:col-6 lg:col-4">
                  <Button label={localized.FormSave} aria-label="Submit" disabled={props.submitting} />
                </div>
              </div>

	    </form>
	);

} );

/*******************************
 * THIS IS THE INDEX PAGE
 * ****************************/
const ProfileIndex = observer( (props) => {
	const rootStore = useRootStore();
	const authStore = useAuthStore();
	const ordersMatch = useMatch(getAbsoluteURL('/profile/my-orders'));
	const navigate = useNavigate();
	const [activeIndex, setActiveIndex] = useState(ordersMatch ? 1 : 0);
	const toast = useRef(null);

	useEffect( () => {
		if (!authStore.isAuthenticated) {
			setTimeout( () => {
				navigate( getAbsoluteURL('/', rootStore.appLang) );
			}, 3500);
		}
	}, [authStore.isAuthenticated]);

	const tabItems = useMemo( () => {
		return [
			{label: localized.ProfilePersonalData, icon: 'pi pi-fw pi-user'},
			{label: localized.ProfileOrders, icon: 'pi pi-fw pi-list'}
		];
	}, [rootStore.appLang]);

	const changeTabIndex = (e) => {
		setActiveIndex(e.index);
		navigate( getAbsoluteURL(e.index == 1 ? '/profile/my-orders' : '/profile', rootStore.appLang) );
	}

	const signout = () => {
		authStore.logout().then( () => {
			toast.current.show({
				severity: 'info'
				,summary: 'Logout'
				,detail: localized.LogoutMessage
				,life: 6500
			});
			return true;
		}).catch( (err) => {
			toast.current.show({
				severity: 'warn'
				,summary: 'Errore'
				,detail: localized.formatString(localized.CannotLogoutError, err.message)
				,life: 5000
			});
			return false;
		});
	}

	return (<div className="main-column flex-grow-1">

        <div className="page-content-container">
	  <ScrollToTopOnMount />
	  <Toast ref={toast} position="top-center"/>
          <div className="page-content pt-4 md:px-8 pb-5 sm:px-4">
            <h1>{localized.t_YourProfile}</h1>
            <Button label={localized.SignOut} onClick={signout} icon="pi pi-power-off" className="mb-2 p-button-sm" />
            <TabMenu className="user-area-menu" model={tabItems} activeIndex={activeIndex} onTabChange={changeTabIndex} />
	    <Outlet />
          </div>
        </div>
        
	</div>
	)
} );


/*******************************
 * PROFILE FORMS PAGE, PROFILE + PASSWORD CHANGE
 * ****************************/
const ProfileForms = observer( (props) => {
	const editedUser = useLoaderData();
	const authStore = useAuthStore();
	const userStore = useUserStore();
	const [submitting, setSubmitting] = useState(false);
	const toast = useRef(null);

	const onProfileSubmit = (data) => {
		if (!editedUser._id) {
			return
		}
		setSubmitting(true);
		let outData = {
			firstName: data.firstName
			,lastName: data.lastName
			,sex: data.sex || undefined
			,birthdate: data.birthdate || undefined
			,'billingAddress.isBusiness': data.isBusiness
			,'billingAddress.companyName': data.companyName || undefined
			,'billingAddress.fiscalCode': data.fiscalCode || undefined
			,'billingAddress.vatNumber': data.vatNumber || undefined
			,'billingAddress.sdiCode': data.sdiCode || undefined
		}
		return userStore.patch(editedUser._id, outData).then( () => {
			toast.current.show({
				severity: 'info'
				,summary: localized.SuccessMessage
				,detail: localized.UserUpdatedMessage
				,life: 6500
			});
			return true;
		}).catch( (err) => {
			toast.current.show({
				severity: 'warn'
				,summary: localized.ErrorMessage
				,detail: localized.formatString(localized.UpdatingUserError, err.message)
				,life: 5000
			});
			return false;
		}).then( (stat) => {
			setSubmitting(false);
			return stat;
		});
	}

	const onChangePasswordSubmit = (values) => {
		if (!editedUser._id) {
			return
		}
		setSubmitting(true);
		let outData = {
			password: values.password
		}
		return userStore.patch(editedUser._id, outData).then( () => {
			toast.current.show({
				severity: 'info'
				,summary: localized.SuccessMessage
				,detail: localized.PasswordUpdatedMessage
				,life: 6500
			});
			return true;
		}).catch( (err) => {
			toast.current.show({
				severity: 'warn'
				,summary: localized.ErrorMessage
				,detail: localized.formatString(localized.UpdatePasswordError, err.message)
				,life: 5000
			});
			return false;
		}).then( (stat) => {
			setSubmitting(false);
			return stat;
		});
	}
	
	return (<div>
	  <Toast ref={toast} />
	  <ProfileForm user={editedUser} onSubmit={onProfileSubmit} submitting={submitting} />
	  <h3>{localized.t_ChangePassword}</h3>
	  <PasswordForm onSubmit={onChangePasswordSubmit} submitting={submitting} />
	</div>
	)
} );

export default ProfileIndex;
export { ProfileForms };


/* 

			<div class="formgrid grid mt-4">
              <div class="field col-12 md:col-6 lg:col-4">
                  <label for="firstname6">Nome</label>
                  <input id="firstname6" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
              </div>
              <div class="field col-12 md:col-6 lg:col-4">
                  <label for="firstname6">Cognome</label>
                  <input id="firstname6" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
              </div>               
            </div>

            <div class="formgrid grid mt-4">
              <div class="field col-12 md:col-12 lg:col-8">
                  <label for="firstname6">Address*</label>
                  <input id="firstname6" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
              </div>                            
            </div>

            <div class="formgrid grid mt-4">
              <div class="field col-12 md:col-12 lg:col-8">
                  <label for="firstname6">City*</label>
                  <input id="firstname6" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
              </div>                            
            </div>

            <div class="formgrid grid mt-4">
              <div class="field col-12 md:col-6 lg:col-4">
                  <label for="firstname6">Country*</label>
                  <input id="firstname6" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
              </div>
              <div class="field col-12 md:col-6 lg:col-4">
                  <label for="firstname6">Postal code*</label>
                  <input id="firstname6" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
              </div>               
            </div>

            <div class="formgrid grid mt-4">
              <div class="field col-12 md:col-6 lg:col-4">
                  <label for="firstname6">Phone*</label>
                  <input id="firstname6" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
              </div>
              <div class="field col-12 md:col-6 lg:col-4">
                  <label for="firstname6">Phone Alternative</label>
                  <input id="firstname6" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
              </div>               
            </div>
            
            <div class="formgrid grid ">
              <div class="field col-12 md:col-6 lg:col-4">
                  <label for="firstname6">email</label>
                  <input id="firstname6" type="text" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
              </div>                            
            </div>

*/
