import React, { useState, useMemo } from 'react';
import { useLoaderData } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { Accordion, AccordionTab } from 'primereact/accordion';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import moment from 'moment';
import localized from '../common/localization';
import { useRootStore } from '../hooks/use-stores';

const MyOrders = observer( (props) => {
	const orders = useLoaderData();
	const rootStore = useRootStore();

	const lang = useMemo( () => rootStore.appLang, [rootStore.appLang]);

	return (<Accordion multiple={false} activeIndex={[0]} className="orders-list mt-4">
		{orders.map( order => (
		<AccordionTab key={order._id} header={`n° ${order.normalizedSeqNo} - ${moment(order.creationDate).format('DD/MM/YYYY HH:mm')}`}>
		  <ul>
		    {(order.snipcartEvent?.content?.items || []).map( (row, ndx) => (
		    <li key={ndx} className="flex justify-content-between flex-column md:flex-row ">
		      <span>{row.quantity} x {row.name}</span>
		      <span>{(+row.totalPrice).toFixed(2)} €</span>
		    </li>
		    ) )}
		    {order.snipcartEvent?.content?.shippingFees > 0 &&
		    <li className="flex justify-content-between flex-column md:flex-row ">
		      <span>{localized.ShippingLabel} {order.snipcartEvent?.content?.shippingMethod}</span>
		      <span>{(+order.snipcartEvent?.content?.shippingFees).toFixed(2)} €</span>
		    </li>
		    }
		    {order.snipcartEvent?.content?.taxesTotal > 0 &&
		    <li className="flex justify-content-between flex-column md:flex-row ">
		      <span>{localized.VATLabel}</span>
		      <span>{(+order.snipcartEvent?.content?.taxesTotal).toFixed(2)} €</span>
		    </li>
		    }
		  </ul>
		  <div className="order-list-total flex justify-content-between">
		    <span>{localized.TotalLabel}</span>{' '}
		    <span>{(+order.total).toFixed(2)} €</span>
		  </div>
		</AccordionTab>
		) )}

	</Accordion>
	)
} );

export default MyOrders;
