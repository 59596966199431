import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ListBox } from 'primereact/listbox';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import SEARCH_CRITERIA from '../common/search-criteria';
import { FILTER_TYPE_TO_PARAM_MAP } from '../common/searchFilters';
import { useRootStore, useOptionListsStore } from '../hooks/use-stores';
import localized from '../common/localization';

const SiderFilters = observer( (props) => {
	const { urlParamsMap, searchParams } = props;
	const [selectedFilterClass, setSelectedFilterClass] = useState(null);
	const [selectedCountries, countriesOptions, setCountriesValue] = props.countriesState;
	const [priceMin, setPriceMin] = useState(0);
	const [priceMax, setPriceMax] = useState(null);
	const [agingMin, setAgingMin] = useState(0);
	const [agingMax, setAgingMax] = useState(null);
	const [textSearch, setTextSearch] = useState('');
	const [yearSearch, setYearSearch] = useState('');
	const [selectedRegions, setSelectedRegions] = useState(null);
	const [selectedSubregions, setSelectedSubregions] = useState(null);
	const [selectedAgingTxts, setSelectedAgingTxts] = useState(null);
	const [selectedVinifications, setSelectedVinifications] = useState(null);
	const [selectedCruClassifications, setSelectedCruClassifications] = useState(null);
	const [selectedDosages, setSelectedDosages] = useState(null);
	const [selectedCuvees, setSelectedCuvees] = useState(null);
	const [selectedProducerCategories, setSelectedProducerCategories] = useState(null);
	const [selectedProductionPhilosophies, setSelectedProductionPhilosophies] = useState(null);
	//!!!ADD HERE THE 'selected' STATE FOR FILTERS!!!
	const [checked, setChecked] = useState(false);
	const rootStore = useRootStore();
	const optionListsStore = useOptionListsStore();
	const navigateToFilteredUrlTimeout = useRef();

	const availableFilterOptions = props.availableFilterOptions;
	//&& selectedFilterClass && selectedFilterClass.code == 'champagne' 
	/*const isChampagneOrSpumanti = useMemo( () => {
		return (selectedFilterClass && ['champagne', 'spumanti'].indexOf(selectedFilterClass.code) !== -1);
	}, [selectedFilterClass]);*/
	/*const isFranceOrChampagne = useMemo( () => {
		return ( (selectedCountries && selectedCountries.find(c => c.code == 'FR'))
			|| (selectedFilterClass && selectedFilterClass.code == 'champagne')
		);
	}, [selectedFilterClass, selectedCountries]);*/

	const selectedCountriesTxt = useMemo( () => {
		if (!selectedCountries) {
			return null;
		}
		return selectedCountries.map(i => i.name).join(', ');
	}, [selectedCountries]);

	const priceFilterTxt = useMemo( () => {
		return [
			priceMin > 0 ? `>= ${priceMin}€` : ''
			,priceMax > 0 ? `<= ${priceMax}€` : ''
		].filter(i => i).join(', ');
	}, [priceMin, priceMax]);

	const agingFilterTxt = useMemo( () => {
		return [
			agingMin > 0 ? `>= ${agingMin}€` : ''
			,agingMax > 0 ? `<= ${agingMax}€` : ''
		].filter(i => i).join(', ');
	}, [agingMin, agingMax]);

	const selectedRegionsTxt = useMemo( () => {
		if (!selectedRegions) {
			return '';
		}
		return selectedRegions.map(o => o.label).join(', ');
	}, [selectedRegions]);

	const selectedSubregionsTxt = useMemo( () => {
		if (!selectedSubregions) {
			return '';
		}
		return selectedSubregions.map(o => o.label).join(', ');
	}, [selectedSubregions]);

	const selectedAgingTxtsTxt = useMemo( () => {
		if (!selectedAgingTxts) {
			return '';
		}
		return selectedAgingTxts.map(o => o.label).join(', ');
	}, [selectedAgingTxts]);

	const selectedVinificationsTxt = useMemo( () => {
		if (!selectedVinifications) {
			return '';
		}
		return selectedVinifications.map(o => o.label).join(', ');
	}, [selectedVinifications]);

	const selectedCruClassificationsTxt = useMemo( () => {
		if (!selectedCruClassifications) {
			return '';
		}
		return selectedCruClassifications.map(o => o.label).join(', ');
	}, [selectedCruClassifications]);

	const selectedDosagesTxt = useMemo( () => {
		if (!selectedDosages) {
			return '';
		}
		return selectedDosages.map(o => o.label).join(', ');
	}, [selectedDosages]);

	const selectedCuveesTxt = useMemo( () => {
		if (!selectedCuvees) {
			return '';
		}
		return selectedCuvees.map(o => o.label).join(', ');
	}, [selectedCuvees]);

	const selectedProducerCategoriesTxt = useMemo( () => {
		if (!selectedProducerCategories) {
			return '';
		}
		return selectedProducerCategories.map(o => o.label).join(', ');
	}, [selectedProducerCategories]);

	const selectedProductionPhilosophiesTxt = useMemo( () => {
		if (!selectedProductionPhilosophies) {
			return '';
		}
		return selectedProductionPhilosophies.map(o => o.label).join(', ');
	}, [selectedProductionPhilosophies]);

	//!!!ADD THE FILTER 'txt' SUMMARY PART HERE!!!

	const filterClassOptions = useMemo( () => {
		//console.log('recalculating filter class options');
		return Object.keys(SEARCH_CRITERIA).map(ckey => {
			let o = SEARCH_CRITERIA[ckey];
			return { code: ckey, name: localized[o.label] || o.label, localizedKeys: o.localizedKeys }
		}).filter(i => {
			return true;
			/*if (!urlParamsMap.has('class')) {
				return true;
				}
				return i.code == urlParamsMap.get('class');*/
		});
	}, [urlParamsMap, rootStore.appLang]);

	const resetTimeoutAndNavigateToFilteredUrl = useCallback( (timeout) => {
		clearTimeout(navigateToFilteredUrlTimeout.current);
		if (timeout === 0) {
			props.navigateToUrlByParams(urlParamsMap, searchParams);
			return;
		}
		navigateToFilteredUrlTimeout.current = setTimeout( () => {
			props.navigateToUrlByParams(urlParamsMap, searchParams);
		}, timeout || 2000);
	}, [urlParamsMap, searchParams]);

	useEffect( () => {
		let v = urlParamsMap.has('class') ? urlParamsMap.get('class') : null;
		let opt = filterClassOptions.find(o => {
			if (o.code == v) {
				return true;
			}
			if (o.localizedKeys && Object.keys(o.localizedKeys).find(lang => o.localizedKeys[lang] == v)) {
				return true;
			}
			return false;
		});
		setSelectedFilterClass(opt || null);
	}, [urlParamsMap, filterClassOptions]);

	useEffect( () => {
		let qFilter = props.searchFilters.find(f => f.type == 'q');
		setTextSearch(qFilter ? qFilter.text : '');

		let yearFilter = props.searchFilters.find(f => f.type == 'year');
		setYearSearch(yearFilter ? yearFilter.value : '');

		let priceFilter = props.searchFilters.find(f => f.type == 'price');
		setPriceMin(priceFilter && priceFilter.priceFrom > 0 ? priceFilter.priceFrom : '');
		setPriceMax(priceFilter && priceFilter.priceTo > 0 ? priceFilter.priceTo : '');

		let agingFilter = props.searchFilters.find(f => f.type == 'aging');
		setAgingMin(agingFilter && agingFilter.agingFrom > 0 ? agingFilter.agingFrom : '');
		setAgingMax(agingFilter && agingFilter.agingTo > 0 ? agingFilter.agingTo : '');
	}, [props.searchFilters]);

	useEffect( () => {
		if (!optionListsStore._loaded) {
			return; //waiting for the options
		}
		let fReg = props.searchFilters.find( f => f.type == 'region' );
		let fSubreg = props.searchFilters.find( f => f.type == 'subregion' );
		let fAgingTxt = props.searchFilters.find( f => f.type == 'agingTxt' );
		let fVinification = props.searchFilters.find( f => f.type == 'vinification' );
		let fCru = props.searchFilters.find( f => f.type == 'cruClassification' );
		let fDosage= props.searchFilters.find( f => f.type == 'dosage' );
		let fCuvee= props.searchFilters.find( f => f.type == 'cuveeType' );
		let fProducerCategory= props.searchFilters.find( f => f.type == 'producerCategory' );
		let fProductionPhilosophy = props.searchFilters.find( f => f.type == 'productionPhilosophy' );

		setSelected(fReg, setSelectedRegions, 'region', 'regionOpts');
		setSelected(fSubreg, setSelectedSubregions, 'subregion', 'subregionOpts');
		setSelected(fAgingTxt, setSelectedAgingTxts, 'agingTxt', 'agingTxtOpts');
		setSelected(fVinification, setSelectedVinifications, 'vinification', 'vinificationOpts');
		setSelected(fCru, setSelectedCruClassifications, 'cruClassification', 'cruClassificationOpts');
		setSelected(fDosage, setSelectedDosages, 'dosage', 'dosageOpts');
		setSelected(fCuvee, setSelectedCuvees, 'cuveeType', 'cuveeOpts');
		setSelected(fProducerCategory, setSelectedProducerCategories, 'producerCategory', 'producerCategoryOpts');
		setSelected(fProductionPhilosophy, setSelectedProductionPhilosophies, 'productionPhilosophy', 'productionPhilosophyOpts');
		//!!!ADD THE FILTER setSelected PART HERE!!!

		function setSelected(filter, setFn, filterName, filterOpts) {
			if (!filter) {
				setFn([]);
			} else {
				let selectedOpts = getSelectedOpsForFilter(filter, filterName, filterOpts);
				//setFn([selectedOpts]);
				//multiple values support
				setFn(selectedOpts);
			}
		}
		function getSelectedOpsForFilter(f, filterName, filterOpts) {
			let allOptionLists = optionListsStore.optionLists;
			/*let selectedOpts = availableFilterOptions[filterName].has(f.value) ?
				availableFilterOptions[filterOpts].find(o => o.val == f.value) 
				: (allOptionLists[filterName].has(f.value) ? 
					allOptionLists[filterName].get(f.value) : [ { val: f.value, label: f.value } ]
				);
			*/
			//multiple values support
			let filterVals = f.or ? f.or.map(i => i.value) : [f.value];
			let selectedOpts = filterVals.map(fval => {
				return (availableFilterOptions[filterName] && availableFilterOptions[filterName].has(fval)) ?
					availableFilterOptions[filterOpts].find(o => o.val == fval) 
					: ( (allOptionLists[filterName] && allOptionLists[filterName].has(fval)) ? 
					allOptionLists[filterName].get(fval) : [ { val: fval, label: fval } ]
					)
				;
			});
			return selectedOpts;
		}
	}, [optionListsStore._loaded, optionListsStore.optionLists, props.searchFilters, availableFilterOptions]);

	//console.log(props.searchFilters);

	/*
	const availableCatsSubcats = useMemo( () => {
		let cats = new Set()
			,subcats = new Set()
			,catsSubcats = new Set();
		props.filteredProducts.forEach( p => {
			if (p.origCategory) {
				cats.add(p.origCategory);
			}
			if (p.origSubcategory) {
				subcats.add(p.origSubcategory);
			}
			catsSubcats.add(`${p.origCategory} - ${p.origSubcategory}`);
		});
		console.log([cats, subcats, catsSubcats]);
		return [cats, subcats, catsSubcats];
	}, [props.filteredProducts]);
	*/

	const setFilterClassValue = (e) => {
		//console.log(e.value);
		setSelectedFilterClass(e.value);
		if (e.value) {
			let o = e.value;
			let code = (rootStore.appLang == 'it') ? 
				o.code
				: (o.localizedKeys[rootStore.appLang] || o.code)
			;
			urlParamsMap.set('class', code);
		}
		else {
			urlParamsMap.delete('class');
		}
		resetTimeoutAndNavigateToFilteredUrl(0);
	}

	const setTextSearchValue = (e) => {
		setTextSearch(e.target.value);
		if (e.target.value) {
			searchParams.set('q', e.target.value);
		}
		else {
			searchParams.delete('q');
		}
		resetTimeoutAndNavigateToFilteredUrl(2800);
	}

	const onTextSearchKeyPress = (e) => {
                if (e.key === 'Enter') {
			if (textSearch) {
				searchParams.set('q', textSearch);
			}
			else {
				searchParams.delete('q');
			}
			resetTimeoutAndNavigateToFilteredUrl(0);
                }
        }

	const setYearSearchValue = (e) => {
		setYearSearch(e.target.value);
		if (e.target.value) {
			searchParams.set('year', e.target.value);
		}
		else {
			searchParams.delete('year');
		}
		resetTimeoutAndNavigateToFilteredUrl(2800);
	}

	const setPriceSearchParamsByArrayAndNavigateToFilter = (values) => {
		let priceFilter = values.map(i => i > 0 ? i : '');
		let hasPriceFilter = priceFilter.filter(i => i > 0);
		if (hasPriceFilter) {
			searchParams.set('price', priceFilter.join(','));
		}
		else {
			searchParams.delete('price');
		}
		resetTimeoutAndNavigateToFilteredUrl();
	}

	const setAgingSearchParamsByArrayAndNavigateToFilter = (values) => {
		let agingFilter = values.map(i => i > 0 ? i : '');
		let hasAgingFilter = agingFilter.filter(i => i > 0);
		if (hasAgingFilter) {
			searchParams.set(FILTER_TYPE_TO_PARAM_MAP['aging'], agingFilter.join(','));
		}
		else {
			searchParams.delete(FILTER_TYPE_TO_PARAM_MAP['aging']);
		}
		resetTimeoutAndNavigateToFilteredUrl();
	}

	const setPriceMinValue = (e) => {
		//console.log(e.value);
		setPriceMin(e.value);
		let values = [+e.value, +priceMax];
		setPriceSearchParamsByArrayAndNavigateToFilter(values);
	}
	const setPriceMaxValue = (e) => {
		//console.log(e.value);
		setPriceMax(e.value);
		let values = [+priceMin, +e.value];
		setPriceSearchParamsByArrayAndNavigateToFilter(values);
	}

	const setAgingMinValue = (e) => {
		//console.log(e.value);
		setAgingMin(e.value);
		let values = [+e.value, +agingMax];
		setAgingSearchParamsByArrayAndNavigateToFilter(values);
	}
	const setAgingMaxValue = (e) => {
		//console.log(e.value);
		setAgingMax(e.value);
		let values = [+agingMin, +e.value];
		setAgingSearchParamsByArrayAndNavigateToFilter(values);
	}

	function getSetValueFn(setFn, filterName) {
		return (e) => {
			console.log(e.value);
			setFn(e.value);
			let fprop = FILTER_TYPE_TO_PARAM_MAP[filterName] || filterName;
			if (e.value.length > 0) {
				searchParams.set(fprop, e.value.map(i => i.val).join(','));
			}
			else {
				searchParams.delete(fprop);
			}
			resetTimeoutAndNavigateToFilteredUrl(0);
		}
	}
	//resetTimeoutAndNavigateToFilteredUrl changes when searchParams and urlParamsMap change
	const setRegionsValue = useCallback( getSetValueFn(setSelectedRegions, 'region'), [resetTimeoutAndNavigateToFilteredUrl]);
	const setSubregionsValue = useCallback( getSetValueFn(setSelectedSubregions, 'subregion'), [resetTimeoutAndNavigateToFilteredUrl]);
	const setAgingTxtsValue = useCallback( getSetValueFn(setSelectedAgingTxts, 'agingTxt'), [resetTimeoutAndNavigateToFilteredUrl]);
	const setVinificationsValue = useCallback( getSetValueFn(setSelectedVinifications, 'vinification'), [resetTimeoutAndNavigateToFilteredUrl]);
	const setCruClassificationsValue = useCallback( getSetValueFn(setSelectedCruClassifications, 'cruClassification'), [resetTimeoutAndNavigateToFilteredUrl]);
	const setDosagesValue = useCallback( getSetValueFn(setSelectedDosages, 'dosage'), [resetTimeoutAndNavigateToFilteredUrl]);
	const setCuveesValue = useCallback( getSetValueFn(setSelectedCuvees, 'cuveeType'), [resetTimeoutAndNavigateToFilteredUrl]);
	const setProducerCategoriesValue = useCallback( getSetValueFn(setSelectedProducerCategories, 'producerCategory'), [resetTimeoutAndNavigateToFilteredUrl]);
	const setProductionPhilosophiesValue = useCallback( getSetValueFn(setSelectedProductionPhilosophies, 'productionPhilosophy'), [resetTimeoutAndNavigateToFilteredUrl]);
	//!!!ADD THE FILTER setXXXXValue FUNCTION HERE!!!

	const countryTemplate = (option) => {
		return (
		    <React.Fragment>
		    <div className="fake-checkbox flex">            
			<div className="p-checkbox p-component p-checkbox-checked mr-2">
			    <div className="p-checkbox-box p-highlight">
				<span className="p-checkbox-icon p-c pi pi-check"></span>
			    </div>
			</div>            
			<div className="country-item">
			    <div>{option.name}</div>
			</div>
		    </div>
		    </React.Fragment>
		);
	}

	const genericTemplate = (option) => {
		return (
		    <React.Fragment>
		    <div className="fake-checkbox flex">            
			<div className="p-checkbox p-component p-checkbox-checked mr-2">
			    <div className="p-checkbox-box p-highlight">
				<span className="p-checkbox-icon p-c pi pi-check"></span>
			    </div>
			</div>            
			<div className="option-item">
			    <div>{option.label}</div>
			</div>
		    </div>
		    </React.Fragment>
		);
	}


    return (
	<Sidebar maskClassName="filters-sidebar" blockScroll visible={props.sidebarOpen} position="right" onHide={props.onSidebarClose}>
	    <div className="sidebar-content-container"> 
		<div className="sidebar-content-content"> 
		    {availableFilterOptions &&
		    <Accordion activeIndex={null}>
				<AccordionTab header={<div>
					{localized.WineType} {selectedFilterClass && <small>({selectedFilterClass.name})</small>}
				</div>
				}>
				    <ListBox className="false-radio" value={selectedFilterClass} options={filterClassOptions} onChange={setFilterClassValue} filter optionLabel="name" itemTemplate={countryTemplate} style={{}} listStyle={{ maxHeight: '250px' }}
				    />

				</AccordionTab>
				<AccordionTab header={<div>
						{localized.TextSearch} {textSearch && <small>({textSearch})</small>}
					</div>}>
				    <span className="p-input-icon-left">
					    <i className="pi pi-search" />
					    <InputText value={textSearch} onKeyPress={onTextSearchKeyPress} onChange={setTextSearchValue} placeholder="Search" />
				    </span>
				</AccordionTab>
				<AccordionTab header={<div>
					{localized.Countries} {selectedCountriesTxt && <small>({selectedCountriesTxt})</small>}
				</div>
				}>
				    <ListBox className="false-radio" value={selectedCountries} options={countriesOptions} onChange={setCountriesValue} multiple filter optionLabel="name" itemTemplate={countryTemplate} style={{ }} listStyle={{ maxHeight: '250px' }}
				    />
				</AccordionTab>				
				<AccordionTab header={<div>
					{localized.Price} {priceFilterTxt && <small>({priceFilterTxt})</small>}
				</div>}>
				    <div className="p-inputgroup">
					<span className="p-inputgroup-addon">min</span>
					<InputNumber value={priceMin} min={0} onChange={setPriceMinValue} placeholder="0 €" mode="currency" minFractionDigits="0" currency="EUR" locale="it-IT"/>
					<span className="p-inputgroup-addon">max</span>
					<InputNumber value={priceMax} onChange={setPriceMaxValue} placeholder="€" mode="currency" minFractionDigits="0" currency="EUR" locale="it-IT" />
				     </div>
				</AccordionTab>	
				<AccordionTab header={<div>
						{localized.Year} {yearSearch && <small>({yearSearch})</small>}
					</div>}>
				    <span className="p-input-icon-left">
					    <i className="pi pi-search" />
					    <InputText value={yearSearch} onChange={setYearSearchValue} placeholder="2016" />
				    </span>
				</AccordionTab>
				{availableFilterOptions.regionOpts.length > 0 &&
				<AccordionTab header={<div>
					{localized.Region} {selectedRegionsTxt && <small>({selectedRegionsTxt})</small>}
				</div>
				}>
				    <ListBox className="false-radio" value={selectedRegions} options={availableFilterOptions.regionOpts} onChange={setRegionsValue} multiple filter optionLabel="label" itemTemplate={genericTemplate} style={{ }} listStyle={{ maxHeight: '250px' }}
				    />
				</AccordionTab>
				}
				{availableFilterOptions.subregionOpts.length > 0 &&
				<AccordionTab header={<div>
					{localized.Subregion} {selectedSubregionsTxt && <small>({selectedSubregionsTxt})</small>}
				</div>
				}>
				    <ListBox className="false-radio" value={selectedSubregions} options={availableFilterOptions.subregionOpts} onChange={setSubregionsValue} multiple filter optionLabel="label" itemTemplate={genericTemplate} style={{ }} listStyle={{ maxHeight: '250px' }}
				    />
				</AccordionTab>
				}
				{availableFilterOptions.agingTxtOpts.length > 0 &&
				<AccordionTab  header={<div>
					{localized.AgingTxt} {selectedAgingTxtsTxt && <small>({selectedAgingTxtsTxt})</small>}
				</div>
				}>
				    <ListBox className="false-radio" value={selectedAgingTxts} options={availableFilterOptions.agingTxtOpts} onChange={setAgingTxtsValue} multiple filter optionLabel="label" itemTemplate={genericTemplate} style={{ }} listStyle={{ maxHeight: '250px' }}
				    />
				</AccordionTab>
				}
				{availableFilterOptions.vinificationOpts.length > 0 && 
				<AccordionTab  header={<div>
					{localized.Vinification} {selectedVinificationsTxt && <small>({selectedVinificationsTxt})</small>}
				</div>
				}>
				    <ListBox className="false-radio" value={selectedVinifications} options={availableFilterOptions.vinificationOpts} onChange={setVinificationsValue} multiple filter optionLabel="label" itemTemplate={genericTemplate} style={{ }} listStyle={{ maxHeight: '250px' }}
				    />
				</AccordionTab>
				}
				{availableFilterOptions.cruClassificationOpts.length > 0 && 
				<AccordionTab  header={<div>
					{localized.CruClassification} {selectedCruClassificationsTxt && <small>({selectedCruClassificationsTxt})</small>}
				</div>
				}>
				    <ListBox className="false-radio" value={selectedCruClassifications} options={availableFilterOptions.cruClassificationOpts} onChange={setCruClassificationsValue} multiple filter optionLabel="label" itemTemplate={genericTemplate} style={{ }} listStyle={{ maxHeight: '250px' }}
				    />
				</AccordionTab>
				}
				{availableFilterOptions.dosageOpts.length > 0 &&
				<AccordionTab  header={<div>
					{localized.Dosage} {selectedDosagesTxt && <small>({selectedDosagesTxt})</small>}
				</div>
				}>
				    <ListBox className="false-radio" value={selectedDosages} options={availableFilterOptions.dosageOpts} onChange={setDosagesValue} multiple filter optionLabel="label" itemTemplate={genericTemplate} style={{ }} listStyle={{ maxHeight: '250px' }}
				    />
				</AccordionTab>
				}
				{availableFilterOptions.cuveeOpts.length > 0 &&
				<AccordionTab  header={<div>
					{localized.CuveeType} {selectedCuveesTxt && <small>({selectedCuveesTxt})</small>}
				</div>
				}>
				    <ListBox className="false-radio" value={selectedCuvees} options={availableFilterOptions.cuveeOpts} onChange={setCuveesValue} multiple filter optionLabel="label" itemTemplate={genericTemplate} style={{ }} listStyle={{ maxHeight: '250px' }}
				    />
				</AccordionTab>
				}
				{availableFilterOptions.producerCategoryOpts.length > 0 &&
				<AccordionTab  header={<div>
					{localized.ProducerCategory} {selectedProducerCategoriesTxt && <small>({selectedProducerCategoriesTxt})</small>}
				</div>
				}>
				    <ListBox className="false-radio" value={selectedProducerCategories} options={availableFilterOptions.producerCategoryOpts} onChange={setProducerCategoriesValue} multiple filter optionLabel="label" itemTemplate={genericTemplate} style={{ }} listStyle={{ maxHeight: '250px' }}
				    />
				</AccordionTab>
				}
				{availableFilterOptions.productionPhilosophyOpts.length > 0 &&
				<AccordionTab  header={<div>
					{localized.ProductionPhilosophy} {selectedProductionPhilosophiesTxt && <small>({selectedProductionPhilosophiesTxt})</small>}
				</div>
				}>
				    <ListBox className="false-radio" value={selectedProductionPhilosophies} options={availableFilterOptions.productionPhilosophyOpts} onChange={setProductionPhilosophiesValue} multiple filter optionLabel="label" itemTemplate={genericTemplate} style={{ }} listStyle={{ maxHeight: '250px' }}
				    />
				</AccordionTab>
				}
				{false && availableFilterOptions.hasAging && <AccordionTab header={<div>
					{localized.Aging} {agingFilterTxt && <small>({agingFilterTxt})</small>}
				</div>}>
				    <div className="p-inputgroup">
					<span className="p-inputgroup-addon">min</span>
					<InputNumber value={agingMin} min={0} onChange={setAgingMinValue} placeholder="0" mode="decimal" minFractionDigits="0" locale="it-IT"/>
					<span className="p-inputgroup-addon">max</span>
					<InputNumber value={agingMax} onChange={setAgingMaxValue} placeholder="" mode="decimal" minFractionDigits="0" locale="it-IT" />
				     </div>
				</AccordionTab>
				}
		    </Accordion>
		    }
		</div>
		<div className="sidebar-content-footer"> 
			<Button onClick={props.clearFilters} label={localized.ResetFilters} className="p-button-rounded  p-button-outlined "   />
			<Button onClick={props.onSidebarClose} label={`${localized.Show} ${props.filteredProducts.length}`} className="p-button-rounded "  icon="pi pi-eye"  />
		</div>                   
	      
	    </div>
	    
	</Sidebar>

               
         
    )
} );

export default SiderFilters;
