import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ProvideRootStore } from './hooks/use-stores';
import { HelmetProvider } from 'react-helmet-async';

import getRoutes from './routes';
import rootStoreInstance from './stores/root-store';

const routes = getRoutes(rootStoreInstance);
const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ProvideRootStore>
        <HelmetProvider>
	  <RouterProvider router={router} />
	</HelmetProvider>
      </ProvideRootStore>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
