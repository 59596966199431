import Breadcrumbs from '../components/breadcrumbs';
import { useRootStore } from '../hooks/use-stores';
import { observer } from 'mobx-react-lite';
import { Helmet } from "react-helmet-async";
import localized from '../common/localization';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';

import { data_it, data_en } from './Payments-html';

const Payments = observer( function(props) {
	const rootStore = useRootStore();
	const data = rootStore.appLang == 'it' ? data_it:data_en;
	return (
	<>
		<Helmet>
			<title>{localized.Payments} | Enoteca Ricciardi Milano</title>
			<script type="application/ld+json">{
			JSON.stringify({
				"@context": "https://schema.org",
				"@type": "WebPage",
				"name": `${localized.Payments} - Enoteca Ricciardi Milano`,
				"description": `${localized.PaymentsDesc}`
			})
			}</script>
			<meta property="og:title" content={`${localized.Payments} - Enoteca Ricciardi Milano`} />
			<meta property="og:description" content={localized.PaymentsDesc} />
			<meta property="og:image" content="https://www.enoteca-ricciardi.it/shop/img/er-logo-bott.svg" />
		</Helmet>

		<ScrollToTopOnMount />
		<Breadcrumbs></Breadcrumbs>
		<div className="page-content-container">
			<div className="page-content">
				<div dangerouslySetInnerHTML={{__html: data }}>
				</div>
			</div>	
		</div>

	</>
	);
})

export default Payments;
