import React from 'react';
import Config from '../Config';
//import moment from 'moment';
import _ from 'lodash';
import localized from './localization';

import { fullCriteriaObj } from './search-criteria';
import { FILTER_TYPE_TO_PARAM_MAP } from './searchFilters';

const mountPointNormalized = Config.MOUNT_POINT.replace(/\/$/, '');
const mountPointNormalizedRegexp = new RegExp(`^${mountPointNormalized}`);

const FISCALCODE_RE = /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i;
const EMAIL_RE = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const PRODUCTS_CACHE_DURATION = 20*60000;

function cleanPath(path) {
        return path.replace(/\/+/g, '/');
}

function getAbsoluteURL(path, lang) {
        path = path.indexOf('/') === 0 ? path : `/${path}`;
	let u = new URL(`${mountPointNormalized}${path}`, window.location.origin);
	if (lang && lang != 'it') {
		u.searchParams.set('hl', lang);
	}
	let dstUrl = u.pathname + u.search;
        return dstUrl;
}
function stripBaseURL(path) {
        return path.replace(mountPointNormalizedRegexp, '');
}

const validatePassword = (value) => {
        const DIGIT_RE = /\d/g, UPPERCASE_RE = /[A-Z]/g, LOWERCASE_RE = /[a-z]/g, SPECIAL_CHARS_RE = /[^A-Za-z0-9]/;
        if (value &&
                ( value.length < 8 || !value.match(DIGIT_RE) || !value.match(SPECIAL_CHARS_RE)
                  || !value.match(UPPERCASE_RE) || !value.match(LOWERCASE_RE) )
        ) {
                return localized.PasswordValidationError 
        }
        return true;
};

function getFilterWithMultipleSupport(filterName, val) {
	let splitted = val.split(/\s*,\s*/);
	if (splitted.length == 1) {
		return {
			type: filterName
			,value: splitted[0]
			,__text: splitted[0]
		}
	}
	return {
		or: splitted.map(f => ({
			type: filterName
			,value: f
			,__text: f
		}))
		,type: filterName
		,__text: splitted.join(',')
	}
}

const parseSearchParams = (searchParams) => {
	let filters = [];
	if (searchParams.get('q')) {
		let state = {};
		state['text'] = searchParams.get('q');
		state['type'] = 'q';
		filters.push(state);
	}
	if (searchParams.get('year')) {
		let v = searchParams.get('year');
		filters.push({
			type: 'year'
			,__text: v
			,value: v
		});
	}
	if (searchParams.get('price')) {
		let state = {};
		let priceSplitted = (searchParams.get('price') || '').split(',');
		let hasPriceFilter = false;
		if (priceSplitted[0] && +priceSplitted[0] > 0) {
			state['priceFrom'] = +priceSplitted[0];
			hasPriceFilter = true;
		}
		if (priceSplitted[1] && +priceSplitted[1] > 0) {
			state['priceTo'] = +priceSplitted[1];
			hasPriceFilter = true;
		}
		if (hasPriceFilter) {
			state['type'] = 'price';
			state['__text'] = [
				state.priceFrom >= 0 ? `>= ${state.priceFrom}€` : ''
				,state.priceTo >= 0 ? `<= ${state.priceTo}€` : ''
			].filter(i => i).join(', ');
			filters.push(state);
		}
	}
	if (searchParams.get('discount')) {
		if (['0', 'false'].indexOf(searchParams.get('discount').toLowerCase()) === -1) {
			filters.push({
				discount: true
				,type: 'discount'
				,__text: 'DiscountedProducts'
			});
		}
	}
	/*
	 *	If we ever need to support multiple values for our options, we should use the 'or' filter...
		filters.push({
			or: splitted.map(i => ({ type: 'xxx', value: i }) )
			,type: 'xxx'
			,__text: `${splitted.join(',')}`
		});
	 */

	if (searchParams.get(FILTER_TYPE_TO_PARAM_MAP['region'])) {
		let v = searchParams.get(FILTER_TYPE_TO_PARAM_MAP['region']);
		filters.push( getFilterWithMultipleSupport('region', v) );
		/*filters.push({
			type: 'region'
			,__text: v 
			,value: v
		});*/
	}
	if (searchParams.get(FILTER_TYPE_TO_PARAM_MAP['subregion'])) {
		let v = searchParams.get(FILTER_TYPE_TO_PARAM_MAP['subregion']);
		filters.push( getFilterWithMultipleSupport('subregion', v) );
		/*filters.push({
			type: 'subregion'
			,__text: v
			,value: v
		});*/
	}
	if (searchParams.get(FILTER_TYPE_TO_PARAM_MAP['agingTxt'])) {
		let v = searchParams.get(FILTER_TYPE_TO_PARAM_MAP['agingTxt']);
		filters.push( getFilterWithMultipleSupport('agingTxt', v) );
		/*filters.push({
			type: 'agingTxt'
			,__text: v
			,value: v
		});*/
	}
	if (searchParams.get(FILTER_TYPE_TO_PARAM_MAP['vinification'])) {
		let v = searchParams.get(FILTER_TYPE_TO_PARAM_MAP['vinification']);
		filters.push( getFilterWithMultipleSupport('vinification', v) );
	}
	if (searchParams.get(FILTER_TYPE_TO_PARAM_MAP['cruClassification'])) {
		let v = searchParams.get(FILTER_TYPE_TO_PARAM_MAP['cruClassification']);
		filters.push( getFilterWithMultipleSupport('cruClassification', v) );
		/*filters.push({
			type: 'cruClassification'
			,__text: v
			,value: v
		});*/
	}
	if (searchParams.get('dosage')) {
		let v = searchParams.get('dosage');
		filters.push( getFilterWithMultipleSupport('dosage', v) );
		/*filters.push({
			type: 'dosage'
			,__text: v
			,value: v
		});*/
	}
	if (searchParams.get(FILTER_TYPE_TO_PARAM_MAP['cuveeType'])) {
		let v = searchParams.get(FILTER_TYPE_TO_PARAM_MAP['cuveeType']);
		filters.push( getFilterWithMultipleSupport('cuveeType', v) );
		/*filters.push({
			type: 'cuveeType'
			,__text: v
			,value: v
		});*/
	}
	if (searchParams.get(FILTER_TYPE_TO_PARAM_MAP['producerCategory'])) {
		let v = searchParams.get(FILTER_TYPE_TO_PARAM_MAP['producerCategory']);
		filters.push( getFilterWithMultipleSupport('producerCategory', v) );
		/*filters.push({
			type: 'producerCategory'
			,__text: v
			,value: v
		});*/
	}
	if (searchParams.get(FILTER_TYPE_TO_PARAM_MAP['productionPhilosophy'])) {
		let v = searchParams.get(FILTER_TYPE_TO_PARAM_MAP['productionPhilosophy']);
		filters.push( getFilterWithMultipleSupport('productionPhilosophy', v) );
		/*filters.push({
			type: 'productionPhilosophy'
			,__text: v
			,value: v
		});*/
	}
	if (searchParams.get(FILTER_TYPE_TO_PARAM_MAP['aging'])) {
		let state = {};
		let ageSplitted = (searchParams.get(FILTER_TYPE_TO_PARAM_MAP['aging']) || '').split(',');
		let hasAgeFilter = false;
		if (ageSplitted[0] && +ageSplitted[0] > 0) {
			state['agingFrom'] = +ageSplitted[0];
			hasAgeFilter = true;
		}
		if (ageSplitted[1] && +ageSplitted[1] > 0) {
			state['agingTo'] = +ageSplitted[1];
			hasAgeFilter = true;
		}
		if (hasAgeFilter) {
			state['type'] = 'aging';
			state['__text'] = [
				state.agingFrom >= 0 ? `>= ${state.agingFrom}` : ''
				,state.agingTo >= 0 ? `<= ${state.agingTo}` : ''
			].filter(i => i).join(', ');
			filters.push(state);
		}
	}
	//!!!FILTERS IN SEARCH PARAMETERS MUST BE ADDED HERE!!!
	return filters;
}

const setPredefinedCriteria = (val, filters, urlParam) => {
	const criteria = fullCriteriaObj;
	if (criteria[val]) {
		//pushing all the elements of the array as a nested array. we change cat and subcat into origCategory and origSubcategory
		filters.push({
			or: criteria[val].filters.map(c => ({ origCategory: c.cat, origSubcategory: c.subcat }) )
			,__text: criteria[val].label
			//,readonly: true
			,type: 'class'
		});
	}
}
const setMultiCountry = (val, filters, urlParam) => {
	let splitted = val.split('-').filter(c => c);
	if (splitted.length == 1) {
		filters.push({
			'country': val
			,type: urlParam
			,__text: val
		});
		return;
	}
	//pushing all the elements of the array as a nested array. 
	filters.push({
		or: splitted.map(c => ({ country: c }) )
		,type: urlParam
		,__text: `${splitted.join(',')}`
	});
}
const urlParamToSearchState = {
	'class': setPredefinedCriteria
	,'country': setMultiCountry
	,'cat': 'origCategory'
	,'subcat': 'origSubcategory'
};
const urlParamsValueMapper = {
	'country': 'upper'
	,'class': 'lower'
};

const parseUrlParamsIntoFilters = (urlParamsMap) => {
	let filters = [];
	urlParamsMap.forEach( (urlVal, urlKey) => {
		Object.keys(urlParamToSearchState).some( urlParam => {
			if (urlKey == urlParam) {
				let val = urlVal;
				if (urlParamsValueMapper[urlParam]) {
					if (urlParamsValueMapper[urlParam] == 'upper') {
						val = val.toUpperCase();
					} else if (urlParamsValueMapper[urlParam] == 'lower') {
						val = val.toLowerCase();
					}
				}
				if (typeof urlParamToSearchState[urlParam] == 'function') {
					urlParamToSearchState[urlParam](val, filters, urlParam);
				}
				else {
					filters.push({
						[urlParamToSearchState[urlParam]]: val
						,type: urlParam
						,__text: `${urlParam}:${val}`
					});
				}
				return true;
			}
			return false;
		});
	} );
	//console.log('parsed filters', filters);
	return filters;
}

const tokenizedTextSearch = (value, record, colKeys) => {
	//we're handling accented characters
	let valueTokens = value.toLowerCase()
		.split(/\s+/).map( v => v.normalize("NFD").replace(/[\u0300-\u036f]/g, "") );
	return valueTokens.every( needle => {
		return colKeys.some( dataIndex => {
			if (record[dataIndex]){
				//&& record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) ) {
				let haystack = record[dataIndex].toString().toLowerCase()
					.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
				return haystack.includes(needle);
			}
			return false;
		});
	});
}

const getProductInternalColorClass = (data) => {
	const DEFAULT_CLASS = 'circle-neutral';
	const COLOR_BY_CAT_SUBCAT = {
		'vini|rosso': 'circle-red'
		,'vini|rosso dolce': 'circle-red'
		,'vini|rosso frizzante': 'circle-red-friz'
		,'spumanti|rosso': 'circle-red-friz'
		,'vini|rosato frizzante': 'circle-rose-friz'
		,'champagne|rosé': 'circle-rose-friz'
		,'spumanti|rosé': 'circle-rose-friz'
		,'champagne|bianco': 'circle-white-friz'
		,'vini|bianco frizzante': 'circle-white-friz'
		,'spumanti|bianco': 'circle-white-friz'
		,'spumanti|spumanti dolci': 'circle-white-friz'
		,'vini|bianco': 'circle-white'
		,'vini|bianco dolce': 'circle-white'
		,'vini|rosato': 'circle-rose'
		,'vini|liquoroso-fortificato': 'circle-porcino'
		,'distillati|': 'circle-neutral'
	}
	let keyFull = `${String(data.origCategory).toLowerCase()}|${String(data.origSubcategory).toLowerCase()}`;
	let keySimple = `${String(data.origCategory).toLowerCase()}|`;
	if (COLOR_BY_CAT_SUBCAT.hasOwnProperty(keyFull)) {
		return COLOR_BY_CAT_SUBCAT[keyFull];
	}
	else if (COLOR_BY_CAT_SUBCAT.hasOwnProperty(keySimple)) {
		return COLOR_BY_CAT_SUBCAT[keySimple];
	}
	return DEFAULT_CLASS;
}

const mapProductToCardObj = data => ({
	id: data._id
	,sku: data.sku
	,producer: data.producerName
	,name: data.name
	,year: data.year
	,format: data.bottleSize
	,grossPrice: data.grossPrice
	,netPrice: data.netPrice
	,discountRate: data.discountRate
	,images: data.images
	,stock: data.stocks instanceof Object && data.stocks.Ecommerce ? Math.max(data.stocks.Ecommerce, 0) : 0
	,vatPerc: data.vatPerc
	,...(data.isNotShippable ? { isNotShippable: true } : null)
	,_colorClass: getProductInternalColorClass(data)
});

const getProductVanityName = prodObj => {
	let extra = [prodObj.year, prodObj.bottleSize].filter(i => i).join(' - ');
	return `${prodObj.producerName} - ${prodObj.name}${extra ? ` (${extra})` : ''}`;
	//vim doesn't detect the closing `
}

const getSnipcartDiscountProperties = product => {
	return product.discountRate > 0 ?
		{
			alternatePrices: {
				discount: +((product.netPrice * (1 - product.discountRate)).toFixed(2))
			}
			,categories: ['discounts']
		}
		: null
	;
}

const getLocalizedDescriptionProperties = (lang) => {
	if (lang != 'en') {
		return [`descTxt_${lang}`, 'descTxt_en'];
	}
	return ['descTxt_en'];
}
const getLocalizedDescription = (product, lang) => {
	let props = getLocalizedDescriptionProperties(lang);
	let descKey = props.find( p => (product[p] || '').replace(/\n/g, '') );
	if (descKey) {
		return product[descKey].replace(/\n/g, '<br/>');
	}
	return null;
}

const getLocalizedLabel = (item, lang) => {
	if (lang == 'it') {
		return item.label || item.label_en;
	}
	else {
		return item[`label_${lang}`] || item.label_en || item.label;
	}
}

const fetchProducts = (productStore, lang) => {
	//console.log("fetchProducts lang", lang, productStore._lastLang);
	let now = new Date();
	if (!productStore._lastFetch || (now - productStore._lastFetch) >= PRODUCTS_CACHE_DURATION || (productStore._lastLang && lang != productStore._lastLang)) {
		productStore.load({
			fetchAll: true
			,query: {
				_hl: lang
				,isPublished: true //TODO: UNCOMMENT THIS ONCE PRODUCTS ARE PUBLISHED
				,origCategory: { '$nin': ['Eliminati', 'Nascosti'] }
				,_deleted: { '$ne': true }
				,'$select': ['_id', 'name', 'netPrice', 'grossPrice', 'producerName', 'year', 'bottleSize', 'origCategory', 'origSubcategory'
					,'country', 'region', 'subregion', 'grapesBlendTxt', 'vinification'
					,'agingTxt', 'aging', 'cruClassification', 'dosage', 'cuveeType', 'producerCategory', 'productionPhilosophy'
					,'abv', 'stocks.Ecommerce', 'sku', 'daneaId', 'isPublished', 'vatPerc', 'images', 'discountRate', 'createdAt'
					,'isNotShippable'
				]
				,'$limit': 500
				,'$sort': {
					producerName: 1, year: -1
				}
			}
		});
	}
	else {
		console.log('Using products cache...');
	}
}


export {
	getAbsoluteURL
	,stripBaseURL
	,cleanPath
	,validatePassword
	,parseSearchParams
	,parseUrlParamsIntoFilters
	,tokenizedTextSearch
	,getProductInternalColorClass
	,mapProductToCardObj
	,getProductVanityName
	,getSnipcartDiscountProperties
	,getLocalizedDescriptionProperties
	,getLocalizedDescription
	,getLocalizedLabel
	,fetchProducts

	,EMAIL_RE
};
