import React, { useState, useEffect, Component, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { getAbsoluteURL, EMAIL_RE } from '../common/common';
import { useAuthStore } from '../hooks/use-stores';
import Config from '../Config';
import localized from '../common/localization';

const LoginForm = observer( (props) => {
	const authStore = useAuthStore();
	const location = useLocation();
	const redirectTo = location.state && location.state.from ? location.state.from : Config.MOUNT_POINT;
	const navigate = useNavigate();
	const defaultValues = {
		email: ''
		,password: ''
	}
	const { control, formState: { errors }, handleSubmit, reset } = useForm(defaultValues);
	const toast = useRef(null);

	useEffect( () => {
		if (authStore.isAuthenticated) {
			if (props.onAuthenticated) {
				props.onAuthenticated();
				return;
			}
			console.log('redirect to value', redirectTo);
			navigate(redirectTo);
			if (props.onNavigate) {
				props.onNavigate();
			}
		}
	}, [authStore.isAuthenticated]);

	const onSubmit = values => {
		authStore.login(values.email, values.password).catch( (err) => {
			toast.current.show({
				severity: 'warn'
				,summary: localized.login_failed
				,detail: localized.check_email_password
				,life: 5000
			});
		});
	}

	const getFormErrorMessage = (name) => {
		return errors[name] && <small className="p-error">{errors[name].message}</small>
	};

	if (authStore.isAuthenticated === null){
		return (<div>
			Controllo autenticazione...
		</div>);
	}
	else if (authStore.isAuthenticated) {
		//if we're here, we're logged in, the useEffect will perform redirection
		return <div>reindirizzamento...</div>
	}

	const gotoPasswordForgotten = () => {
		window.Snipcart.api.theme.cart.close();
		setTimeout( () => {
			navigate(getAbsoluteURL('/auth/password-forgotten', authStore.rootStore.appLang));
		}, 600);
	}

	return (
	<form onSubmit={handleSubmit(onSubmit)} className="">
	  <Toast ref={toast} position="top-center"/>
	  
	   
	      <div className="text-center mb-5">            
	        <div className="text-900 text-3xl font-medium mb-3">{localized.welcome_back}</div>
	        <span className="text-600 font-medium line-height-3">{localized.DontHaveAccount}</span>
	        <Link to={getAbsoluteURL('/auth/signup', authStore.rootStore.appLang)} className="font-medium ml-2  cursor-pointer">{localized.CreateAccountNow}</Link>
	      </div>
	      <div>
	        <label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
	        {getFormErrorMessage('email')}
		<Controller name="email" control={control} rules={{ required: localized.EmailRequired, pattern: { value: EMAIL_RE, message: localized.EmailInvalid }}}
		 render={({ field, fieldState }) => (
                  <InputText id={field.name} type="email" {...field} autoFocus className={classNames('w-full', 'mb-3', { 'p-invalid': fieldState.invalid })} />
		)} />
		

	        <label htmlFor="password" className="block text-900 font-medium mb-2">Password</label>
	        {getFormErrorMessage('password')}
		<Controller name="password" control={control} rules={{ required: localized.PasswordRequired }} render={({ field, fieldState }) => (
                  <Password id={field.name} {...field} toggleMask feedback={false} className={classNames('w-full', 'mb-3', { 'p-invalid': fieldState.invalid })} />
                )} />
		
		

			<div>
				<Accordion className="login-accordion">
		            <AccordionTab header={localized.RegisterAgainQuestion}>
			            <div dangerouslySetInnerHTML={{__html: localized.RegisterAgainText }} />
			                
		            </AccordionTab>            
		        </Accordion>
			</div>
	        <div className="flex align-items-center justify-content-between mb-4">

		  <a href="#" className="font-medium  text-sm text-right cursor-pointer" onClick={gotoPasswordForgotten}>{localized.PasswordForgotten}</a>
	        </div>

	        <Button type="submit" label={localized.SignIn}  className="w-full p-button-rounded" disabled={authStore._isLoggingIn} >
	          <i className="pi pi-arrow-right"></i>
			</Button>
	      </div>
	  

	</form>
	)
} );

export default LoginForm;
