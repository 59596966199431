import { useLocation } from 'react-router-dom';
import { LANG_QUERY_PARAM } from '../common/localization';

const useOtherLangUrls = () => {
	const location = useLocation();
	const currUrl = location.pathname + location.search + location.hash;
	//console.log(location);
	const fn = (lang) => {
		let u = new URL(currUrl, window.location.origin);
		u.searchParams.set(LANG_QUERY_PARAM, lang);
		return u.pathname + u.search + u.hash;
	}
	return [fn, currUrl]
}

export default useOtherLangUrls;
