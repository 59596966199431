import LocalizedStrings from 'react-localization';
import GeoStrings from './localization-geo';
import SeoStrings from './localization-seo';

const DEFAULT_LANGUAGE = 'it';
const LANG_QUERY_PARAM = 'hl';

const strings = new LocalizedStrings({
	en:{
		login_failed: 'Failed Log In'
		,NewArrivals: 'New arrivals'
		,check_email_password: 'Please chek Email and Password'
		,welcome_back: 'Welcome Back!'
		,all_filters: 'all filters'		
		,c_Vini: 'Wine'
		,c_Birra: 'Beer'
		,f_ViniRossi: 'Red Wines'
		,f_ViniBianchi: 'White Wines'
		,f_ViniRosati: 'Rosé Wines'
		,f_ViniDolci: 'Sweet Wines'
		,f_ViniLiquorosiFortificati: 'Liquor/Fortified Wines'
		,f_Sidro: 'Cider'
		,f_Spumanti: 'Sparkling Wines'
		,f_Altro: 'Other'
		,f_Superalcolici: 'Spirits'
		,f_rarita: 'Rarities'
		,DiscountedProducts: 'Promo'
		,Cart: 'Cart'
		,Login: 'Log In'
		,Profile: 'Profile'
		,SelectCountries: 'Country'
		,SortByPrice: 'Sort by price'
		,PriceHighToLow: 'Price high to low'
		,PriceLowToHigh: 'Price low to high'
		,ResultsForKeyword: 'Results for {0}'
		,ProductsTitle: 'Products'
		,ResetFilters: 'Reset Filters'
		,Buy: 'Buy'
		,AddedToCart: 'Added to cart'
		,Cart: 'Cart'
		,Continue: 'Continue'
		,AlreadyAddedToCart: 'already in cart'
		,Company: 'Company'
		,AboutUs: 'About Us'
		,QualityAssurance: 'Quality assurance'
		,Contacts: 'Contact Us'
		,Assistance: 'Assistance'
		,Payments: 'Payments'
		,Orders: 'Orders and Shipments'
		,SaleConditions: 'Sale Conditions'
		,Returns: 'Returns'
		,Faq: 'FAQs'
		,DontHaveAccount:'Don\'t have an account?'
		,CreateAccountNow: 'Register now!'
		,PasswordForgotten: 'Password Forgotten'
		,SignIn: 'Sign In'
		,SignOut: 'Sign Out'
		,GoToHomepage: 'Go to homepage'
		,ForgotYourPassword: 'Forgot your Password?'
		,WriteYourEmail: 'Write your email'
		,Submit: 'Submit'
		,PasswordRequired: 'Password is required'
		,EmailRequired: 'Email is required.'
		,EmailInvalid: 'Email is invalid'
		,PasswordResetRequested: 'Password reset requested'
		,PasswordResetRequestedDetail: 'If your address is in our database, you will shortly receive an email containing the link to reset your password'
		,Error: 'Error'
		,PasswordResetError: 'Unable to send the email with the link to reset your password. Try again, thanks'
		,Welcome: 'Welcome!'
		,RegisterAccount: 'Register your account'
		,YourEmail: 'Your Email'
		,ChoosePassword: 'Choose your Password'
		,Register: 'Sign up'
		,Redirecting: 'redirecting...'
		,RegistrationSuccessful: 'Sign up successful'
		,RegistrationSuccessfulDetail: 'Registration has been successful. Check your inbox to confirm your email'
		,RegistrationImpossible: 'Error signing up'
		,MailAlreadyUsed: 'This email address is already in use, please try another one'
		,RegistrationError: 'Error signing up: {0}'
		,PasswordValidationError: 'The password must be at least 8 characters and must contain a number, an uppercase and a lowercase letters and a special character'
		,WineCategory: 'Category'
		,WineType: 'Type'
		,Countries: 'Countries'
		,Country: 'Country'
		,TextSearch: 'Search text'
		,Price: 'Price'
		,Show: 'Show'
		,Grapes: 'Grapes'
		,Region: 'Region'
		,Subregion: 'Subregion'
		,Zone: 'Zone'
		,Origin: 'Origin'
		,Vinification: 'Vinification'
		,CruClassification: 'Cru classification'
		,ProducerCategory: 'Producer category'
		,CuveeType: 'Cuvée type'
		,ProductionPhilosophy: 'Production philosophy'
		,Dosage: 'Dosage'
		,Aging: 'Aging #'
		,AgingTxt: 'Aging'
		,Year: 'Year'
		,Package: 'Package'
		,All: 'All'
		,OpeningDays: 'from Monday to Saturday'
		,OpeningHours: 'Opening hours'
		,NoResults: 'No product found'
		,InStock: 'In Stock'
		,LowStock: 'Low stock'
		,NoStock: 'Out of stock'		
		,ProfilePersonalData: 'Personal Data'
		,ProfileOrders: 'Orders'
		,Male: 'Male'
		,Female: 'Female'
		,UndefinedSex: 'Undefined'
		,RegularClientOption: 'Regular client'
		,BusinessClientOption: 'Business client'
		,fl_Company: 'Company'
		,fl_VatNo: 'VAT Number'
		,fl_SdiCode: 'SDI Code'
		,fl_FirstName: 'First name'
		,fl_LastName: 'Last name'
		,fl_BirthDate: 'Birthdate'
		,fl_Sex: 'Sex'
		,fl_FiscalCode: 'Fiscal Code'
		,fl_Email: 'Email'
		,fl_NewPassword: 'New password'
		,fl_RepeatNewPassword: 'Repeat new password'
		,FormSave: 'Save'
		,t_YourProfile: 'Your Profile'
		,PasswordIsRequired: 'Password is required'
		,ConfirmPasswordIsRequired: 'Confirm Password is required'
		,PasswordsMustBeEqual: 'Passwords must be equal'
		,CompanyNameIsRequired: 'Company name is required'
		,VatNumberIsRequired: 'VAT number is required'
		,FirstNameIsRequired: 'First name is required'
		,LastNameIsRequired: 'Last name is required'
		,LogoutMessage: 'You successfully logged out'
		,CannotLogoutError: 'Cannot log out: {0}'
		,SuccessMessage: 'Success'
		,ErrorMessage: 'Error'
		,UserUpdatedMessage: 'User successfully updated'
		,UpdatingUserError: 'Error updating user: {0}'
		,PasswordUpdatedMessage: 'Your password has been updated'
		,UpdatePasswordError: 'Error updating password: {0}'
		,ShippingLabel: 'Shipping'
		,VATLabel: 'VAT'
		,TotalLabel: 'TOTAL'
		,Find: 'Find'
		,VisitInstagram: 'Visit our Instagram profile'
		,VisitFacebook: 'Visit our Facebook page'
		,NoPageFound: 'Page not found'
		,RegisterAgainQuestion: 'Problems logging in?'
		,PaymentError: 'Payment error.'
		,PaymentNulled:'Payment cancelled.'
		,PaymentOk: 'Payment has been successful!'
		,RegisterAgainText: `
				<p>
                	If you registered on our site <strong>before December 7, 2022</strong>, we kindly ask you to <strong>register again</strong>.
                </p>
                <p>
                	Otherwise, check that you have entered your login credentials correctly or use the link below to set a new password.
                </p>
			`
		,n_of_tot: '{0} of {1}'
		,...GeoStrings.en 
		,...SeoStrings.en 
	},
	it: {
		login_failed: 'Login fallito'
		,NewArrivals: 'Nuovi arrivi'
		,check_email_password: 'Per favore, controlla email e password'
		,welcome_back: 'Bentornato'
		,all_filters: 'tutti i filtri'
		,c_Vini: 'Vini'
		,c_Birra: 'Birra'
		,f_ViniRossi: 'Vini Rossi'
		,f_ViniBianchi: 'Vini Bianchi'
		,f_ViniRosati: 'Vini rosé'
		,f_ViniDolci: 'Vini Dolci'
		,f_Spumanti: 'Spumanti'
		,f_ViniLiquorosiFortificati: 'Vini Liquorosi/Fortificati'
		,f_Sidro: 'Sidro'
		,f_Altro: 'Altro'
		,f_Superalcolici: 'Superalcolici'
		,f_rarita: 'Rarità'
		,DiscountedProducts: 'Promozioni'
		,Cart: 'Carrello'
		,Login: 'Accedi'
		,Profile: 'Profilo'
		,SelectCountries: 'Nazione'
		,SortByPrice: 'Ordina per prezzo'
		,PriceHighToLow: 'Prezzo dal più alto al più basso'
		,PriceLowToHigh: 'Prezzo dal più basso al più alto'
		,ResultsForKeyword: 'Risultati per {0}'
		,ProductsTitle: 'Prodotti'
		,ResetFilters: 'Rimuovi i filtri'
		,Buy: 'Acquista'
		,AddedToCart: 'Aggiunto al carrello'
		,Cart: 'Carrello'
		,Continue: 'Continua'
		,AlreadyAddedToCart: 'già nel carrello'
		,Company: 'Azienda'
		,AboutUs: 'Chi siamo'
		,QualityAssurance: 'Garanzia di qualità'
		,Contacts: 'Contatti'
		,Assistance: 'Assistenza'
		,Payments: 'Pagamenti'
		,Orders: 'Ordini e spedizioni'
		,SaleConditions: 'Condizioni di vendita'
		,Returns: 'Resi'
		,Faq: 'Domande frequenti'
		,DontHaveAccount:'Non sei registrato?'
		,CreateAccountNow: 'Registrati ora!'
		,PasswordForgotten: 'Password dimenticata'
		,SignIn: 'Accedi'
		,SignOut: 'Esci dal tuo account'
		,GoToHomepage: 'Vai alla pagina principale'
		,ForgotYourPassword: 'Dometicato la password?'
		,WriteYourEmail: 'Scrivi la tua email'
		,Submit: 'Invia'
		,PasswordRequired: 'Password richiesta'
		,EmailRequired: 'Email richiesta'
		,EmailInvalid: 'L\'email non è valida'
		,PasswordResetRequested: 'Reset password richiesta'
		,PasswordResetRequestedDetail: 'Se il tuo indirizzo è nel nostro database, a breve riceverai una mail contenente il link per reimpostare la password'
		,Error: 'Errore'
		,PasswordResetError: 'Impossibile inviare la mail con il link per reimpostare la password. Prova ancora, grazie'
		,Welcome: 'Benvenuto!'
		,RegisterAccount: 'Registrati'
		,YourEmail: 'La tua Email'
		,ChoosePassword: 'Scegli la tua Password'
		,Register: 'Registrati'
		,Redirecting: 'Reindirizzamento...'
		,RegistrationSuccessful: 'Registrazione avvenuta con successo'
		,RegistrationSuccessfulDetail: 'La registrazione è andata a buon fine. Controlla la casella di posta per confermare la tua email'
		,RegistrationImpossible: 'Impossibile registrarsi'
		,MailAlreadyUsed: 'Questo indirizzo mail è già in uso, prova con un\'altro'
		,RegistrationError: 'Errore durante la registrazione: {0}'
		,PasswordValidationError: 'La password deve essere di almeno 8 caratteri e deve contenere un numero, una lettera maiuscola e una minuscola e un carattere speciale'
		,WineCategory: 'Categoria'
		,WineType: 'Tipologia'
		,Countries: 'Nazioni'
		,Country: 'Nazione'
		,TextSearch: 'Ricerca testuale'
		,Price: 'Prezzo'
		,Show: 'Mostra'
		,Grapes: 'Uvaggio'
		,Region: 'Regione'
		,Subregion: 'Sottoregione'
		,Zone: 'Zona'
		,Origin: 'Denominazione'
		,Vinification: 'Vinificazione'
		,CruClassification: 'Classificazione Cru'
		,ProducerCategory: 'Categoria produttore'
		,CuveeType: 'Tipologia Cuvée'
		,ProductionPhilosophy: 'Filosofia produttiva'
		,Dosage: 'Dosaggio'
		,Aging: 'Invecchiamento #'
		,AgingTxt: 'Invecchiamento'
		,Year: 'Annata'
		,Package: 'Confezione'
		,All: 'Tutti'
		,OpeningDays: 'dal lunedì al sabato'
		,OpeningHours: 'Orari di apertura:'
		,NoResults: 'Nessun prodotto trovato'
		,InStock: 'Disponibile'
		,LowStock: 'In esaurimento'
		,NoStock: 'Non disponibile'
		,ProfilePersonalData: 'Dati Personali'
		,ProfileOrders: 'Ordini'
		,Male: 'Maschio'
		,Female: 'Femmina'
		,UndefinedSex: 'Non definito'
		,RegularClientOption: 'Privato'
		,BusinessClientOption: 'Azienda'
		,fl_Company: 'Ragione sociale'
		,fl_VatNo: 'Partita IVA'
		,fl_SdiCode: 'Codice SDI'
		,fl_FirstName: 'Nome'
		,fl_LastName: 'Cognome'
		,fl_BirthDate: 'Data di nascita'
		,fl_Sex: 'Sesso'
		,fl_FiscalCode: 'Codice Fiscale'
		,fl_Email: 'Email'
		,fl_NewPassword: 'Nuova password'
		,fl_RepeatNewPassword: 'Ripeti nuova password'
		,FormSave: 'Salva'
		,t_YourProfile: 'Il Tuo Profilo'
		,t_ChangePassword: 'Cambia Password'
		,PasswordIsRequired: 'La password è obbligatoria'
		,ConfirmPasswordIsRequired: 'La conferma password è obbligatoria'
		,PasswordsMustBeEqual: 'Le password devono coincidere'
		,CompanyNameIsRequired: 'La ragione sociale è obbligatoria'
		,VatNumberIsRequired: 'La partita IVA è obbligatoria'
		,FirstNameIsRequired: 'Il nome è obbligatorio'
		,LastNameIsRequired: 'Il cognome è obbligatorio'
		,LogoutMessage: 'Sei uscito con successo'
		,CannotLogoutError: 'Impossibile effettuare il logout: {0}'
		,SuccessMessage: 'Tutto ok'
		,ErrorMessage: 'Errore'
		,UserUpdatedMessage: 'L\'utente è stato aggiornato con successo'
		,UpdatingUserError: 'Impossibile aggiornare l\'utente: {0}'
		,PasswordUpdatedMessage: 'La password è stata aggiornata con successo'
		,UpdatePasswordError: 'Impossibile cambiare la password: {0}'
		,ShippingLabel: 'Spedizione'
		,VATLabel: 'IVA'
		,TotalLabel: 'TOTALE'
		,Find: 'Cerca'
		,VisitInstagram: 'Visita il nostro profilo Instagram'
		,VisitFacebook: 'Visita la nostra pagina Facebook'
		,NoPageFound: 'Pagina non trovata'
		,RegisterAgainQuestion: 'Problemi ad accedere?'
		,PaymentError: 'Errore nel pagamento.'
		,PaymentNulled:'Il pagamento è stato annullato.'
		,PaymentOk: 'Il pagamento è andato a buon fine!'
		,RegisterAgainText: `
				<p>
                	Se ti sei registrato sul nostro sito <strong>prima del 7 dicembre 2022</strong>, ti chiediamo gentilmente di <strong>registrarti nuovamente</strong>.
                </p>
                <p>
                	In caso contrario verifica di aver inserito correttamente le tue credenziali di accesso oppure utilizza il link qui sotto per reimpostare una nuova password.
                </p>
			`
		,n_of_tot: '{0} di {1}'
		,...GeoStrings.it
		,...SeoStrings.it
	}
});
strings.setLanguage(DEFAULT_LANGUAGE); //must match the default value set in the root store

const getAvailableLanguages = () => {
	return strings.getAvailableLanguages();
}

const snipcartLangOverrides = {
	it: {
		address_form: {
			phone: "Telefono"
			,fiscalCode: "Codice Fiscale"
			,vatNumber: "Partita IVA"
			,sdiCode: "Codice SDI"
			,giftTitle: "È un regalo?"
			,giftNote: "Scrivi un biglietto di auguri"
			,isGift: "Aggiungi una confezione regalo (+5€)"
			,giftBoxHint: "Solo per Milano:"
			,giftHelpText: "Solo per gli ordini da consegnare all'interno della città di Milano possiamo eseguire delle eleganti confezioni regalo"
		}
		,billing: {
			user_is_nobusiness: 'Sono un privato'
			,"continue_to_shipping": "Vai alla consegna / ritiro"
			,user_is_business: 'Sono un\'azienda'
			,click_here_if_nobusiness: 'Clicca qui se sei un privato'
			,click_here_if_business: 'Clicca qui se sei un\'azienda'
			,policyAndAgeCheck: 'Confermo di essere maggiorenne e di aver letto la <a target="_blank" href="https://www.iubenda.com/privacy-policy/55472581">privacy policy</a>'
		}
		,cart: {
			invoice_number: 'Numero ordine'
		}
		,"shipping": {
			"title": "Consegna"
			,"shipping_to": "Spedire a:"
			//,"donotshiponsundayholidays": "Non si effettuano spedizioni la domenica ed i giorni festivi.<br/>L'enoteca resterà chiusa dall'8 al 24 agosto. Le spedizioni riprenderanno regolarmente dal giorno 26 agosto"
			,"donotshiponsundayholidays": "Non si effettuano spedizioni la domenica ed i giorni festivi"
		}
		,payment: {
			checkout_with_paypal_method: 'Paga con Carta di Credito* o con'
			,checkout_with_nexi_method: 'Paga con Carta di Credito'
			,paypal_checkout_extrainfo: '* <strong>Per pagare con una carta</strong>, se non hai un conto PayPal, una volta entrato clicca su <strong>"Pagamento come utente non registrato"</strong> o <strong>"Paga con una carta"</strong>'
			,form: {
				deferred_payment_title: 'Pagamento con bonifico'
				,deferred_payment_instructions: `Effettuando questo ordine, l'utente accetta di pagare attraverso un bonifico bancario.
				<br><br>
				ENOTECA RICCIARDI SRL<br>
				BANK ACCOUNT : BANCO BPM - MILANO<br>
				IBAN : IT35 K0503401743 000000001629<br>
				SWIFT BAPPIT 21675
				` 
			}			
			,methods: {
				deferred_payment: 'Paga con bonifico'
			}

		}
		,cart_summary: {
			taxes: 'IVA'
			,total_with_vat: 'Totale IVA compresa'
			,total_without_vat: 'Totale IVA esclusa'
		}
		,errors: {
			email: "Inserisci un'email valida. Controlla che non ci siano spazi all'inizio o alla fine"
		}
	}
	,en: {
		address_form: {
			phone: "Phone"
			,fiscalCode: "Fiscal code"
			,vatNumber: "VAT code"
			,sdiCode: "SDI code"
			,giftTitle: "Is this a Present?"
			,giftNote: "Write a greeting message"
			,isGift: "Add a gift box (+5€)"
			,giftBoxHint: "Only for Milan:"
			,giftHelpText: "Only for orders to be delivered within the city of Milan we can pack elegant gift boxes"
		}
		,billing: {
			user_is_nobusiness: 'I\'m a regular customer'
			,"continue_to_shipping": "Continue to shipping"
			,user_is_business: 'I\'m a business customer'
			,click_here_if_nobusiness: 'Click here if you\'re a regular customer'
			,click_here_if_business: 'Click here if you\'re a business customer'
			,policyAndAgeCheck: 'I confirm I am over the legal drinking age in my country and I\'ve read the <a target="_blank" href="https://www.iubenda.com/privacy-policy/55472581">privacy policy</a>'
		}
		,cart: {
			invoice_number: 'Order number'
		}
		,"shipping": {
			//"donotshiponsundayholidays": "No deliveries will be made on Sundays and on public holidays.<br/>The wine shop will be closed from 8<sup>th</sup> to 24<sup>th</sup> August. Shipments will resume regularly from August 26<sup>th</sup>"
			"donotshiponsundayholidays": "No deliveries will be made on Sundays and on public holidays"
		}
		,payment: {
			checkout_with_paypal_method: 'Checkout with credit card or with'
			,checkout_with_nexi_method: 'Checkout with credit card'
			,paypal_checkout_extrainfo: `* <strong>To pay with a credit card<strong> don't worry if you don't have a PayPal account, just click on <strong>"Pay with Debit or Credit Card"</strong> once the PayPal page shows up`
			,form: {
				deferred_payment_title: 'Bank transfer'
				,deferred_payment_instructions: `Confirming this order you agree to make a bank transfer.
				<br><br>
				ENOTECA RICCIARDI SRL<br>
				BANK ACCOUNT : BANCO BPM - MILANO<br>
				IBAN : IT35 K0503401743 000000001629<br>
				SWIFT BAPPIT 21675
				`
			}
			,methods: {
				deferred_payment: 'Checkout with bank transfer'
			}

		}
		,cart_summary: {
			taxes: 'VAT'
			,total_with_vat: 'Total VAT included'
			,total_without_vat: 'Total VAT excluded'
		}		
		,errors: {
			email: "Enter a valid email address. Please make sure there are no leading or trailing spaces"
		}
	}

}

export default strings;
export { DEFAULT_LANGUAGE, LANG_QUERY_PARAM, getAvailableLanguages, snipcartLangOverrides };
