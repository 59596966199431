import { useMemo, useRef } from 'react';
import { useSearchParams, Link } from "react-router-dom";
import localized from '../common/localization';

const useDataViewSkip = ({ dataViewRows, totalItems, pageContainerRef }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const firstSkip = useRef(false);
	const paginatorTemplate = {
		layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport'
		,FirstPageLink: (options) => {
			return options.element;
		}
		,PrevPageLink: (options) => {
			if (options.className.indexOf('p-disabled') !== -1) {
				return options.element;
			}
			let u = new URL(window.location.href);
			u.searchParams.set('skip', skip - dataViewRows > 0 ? skip - dataViewRows : 0);
			return <Link to={u.pathname + u.search + u.hash} className={options.className} onClick={options.onClick}>
				<span className="p-button-icon p-c p-paginator-icon pi pi-angle-left" />
			</Link>
		}
		,PageLinks: (options) => {
			return options.element;
		}
		,NextPageLink: (options) => {
			// options.onClick: Click event for the default element.
			// options.className: Style class of the default element.
			// options.iconClassName: Style class of the default icon element.
			// options.disabled: Indicates whether the element is disabled.
			// options.element: Default element created by the component.
			// options.props: Component props.
			if (options.className.indexOf('p-disabled') !== -1) {
				return options.element;
			}
			let u = new URL(window.location.href);
			u.searchParams.set('skip', skip + dataViewRows);
			return <Link to={u.pathname + u.search + u.hash} className={options.className} onClick={options.onClick}>
				<span className="p-button-icon p-c p-paginator-icon pi pi-angle-right" />
			</Link>
		}
		,LastPageLink: (options) => {
			if (options.className.indexOf('p-disabled') !== -1) {
				return options.element;
			}
			let lastSkip = totalItems % dataViewRows == 0 ? totalItems - dataViewRows : Math.floor(totalItems / dataViewRows) * dataViewRows;
			lastSkip = lastSkip >= 0 ? lastSkip : 0;
			let u = new URL(window.location.href);
			u.searchParams.set('skip', lastSkip);
			return <Link to={u.pathname + u.search + u.hash} className={options.className} onClick={options.onClick}>
				<span className="p-button-icon p-c p-paginator-icon pi pi-angle-double-right"></span>
			</Link>;
		}
		,RowsPerPageDropdown: (options) => {
			return options.element;
		}
		,CurrentPageReport: (options) => {
			return <span className="p-paginator-current">
				({localized.formatString(localized.n_of_tot, options.currentPage, options.totalPages)})
			</span>;
			//return options.element;
		}
	}

	const skip = useMemo( () => {
		return (searchParams.get('skip') && +searchParams.get('skip') > 0) ? +searchParams.get('skip') : 0;
	}, [searchParams]);

	const setSkipQueryParam = (e) => {
		if (skip > 0 && !firstSkip.current) {
			firstSkip.current = true;
			return;
		}
		//console.log('setting skip to', e);
		searchParams.set('skip', e.first);
		setSearchParams(searchParams);
		if (pageContainerRef.current) {
			setTimeout( () => {
				let bodyRect = document.body.getBoundingClientRect();
				let rect = pageContainerRef.current.getBoundingClientRect();
				window.scrollTo({
					top: rect.top - bodyRect.top -1,
					behavior: "smooth"
				});
			}, 100);
		}
	}

	return [skip, setSkipQueryParam, paginatorTemplate];
}

export default useDataViewSkip;

