import React, { useState, useMemo, useRef, useContext } from 'react';
import { Outlet, Link, matchPath, useLocation } from "react-router-dom";
import { observer } from 'mobx-react-lite';
//import { Routes, Route } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import HeaderDesktop from '../components/header';
import Footer from '../components/footer';
import NotFound from '../pages/NotFound';
import requiresAuthentication from '../common/requiresAuthentication';
import LoginForm from '../partials/form-login';
import ServiceColumn from '../components/service-column';
import { useRootStore } from '../hooks/use-stores';
import { Toast } from 'primereact/toast';
import { getAbsoluteURL } from '../common/common';
import { ScrollTop } from 'primereact/scrolltop';


const Main = observer( (props) => {
	const rootStore = useRootStore();
	const [displaySignIn, setDisplaySignIn] = useState(false);
	const toast = useRef();

	const customSignInFn = useMemo( () => {
		const fn = (e) => {
			e.preventDefault();
			setDisplaySignIn(true);
		}
		window.customSignIn = fn;
		return fn;
	}, []);

	const switchB2B_B2CFn = useMemo( () => {
		const fn = (e) => {
			e.preventDefault();
			e.target.setAttribute('disabled', true);
			setTimeout( () => {
				e.target.removeAttribute('disabled');
			}, 2000);
			const val = !rootStore.isBusinessUser;
			rootStore.setBusinessUser(val);
			toast.current.show({
				severity:'info'
				,summary: 'Info'
				,detail: val ? 'Il form è ora impostato per un cliente aziendale' : 'Il form è ora impostato per un cliente privato/consumer'
				,life: 3000
			});
		};
		window.switchB2B_B2C = fn;
	}, []);

	const onAuthenticated = () => {
		setDisplaySignIn(false);
	}

	return (
	<div className="wrapper">
		<div className="main-layout-wrapper">
			<div className="logo-column">
				<Link to={getAbsoluteURL("/", rootStore.appLang)}>
					<img src={`${process.env.PUBLIC_URL}/img/er-logo-vertical.svg`} width="35" height="214" className="fixed" alt="Enoteca Ricciardi" />
				</Link>
				
			</div>
			<div className="main-column flex-grow-1">
				<HeaderDesktop />
				<Outlet />
				{/*<Routes>
					{routes.flatMap( parentMenuItem => (
						[ parentMenuItem, ...(parentMenuItem.children || []) ].map( menuItem =>
							<Route path={menuItem.path} key={menuItem.path} element={
								<menuItem.routerComponent {...menuItem.routerExtraProps} />
							} />
						)
					) ) }
					<Route path="*" element={<NotFound />} />
				</Routes>
				*/}
				<Footer/>
				<ScrollTop className="scroll-top-mobile"/>
			</div>		
			<ServiceColumn />
			<Dialog header="Log In" visible={displaySignIn} style={{  }} onHide={() => setDisplaySignIn(false)}>
				<LoginForm onAuthenticated={onAuthenticated} />
	                </Dialog>
			<Toast ref={toast} position="top-center" />
			</div>
	</div>);
} );

export default Main;
