import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams, Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { ProgressBar } from 'primereact/progressbar';
//import { Skeleton } from 'primereact/skeleton';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import IsMobile from 'is-mobile';
import { useProductStore, useOptionListsStore } from '../hooks/use-stores';
import useUrlParamsMap from '../hooks/use-urlparamsmap';
import useDataViewSkip from '../hooks/use-dataview-skip';
import { parseSearchParams, parseUrlParamsIntoFilters, tokenizedTextSearch, fetchProducts
	,getAbsoluteURL, mapProductToCardObj, getProductVanityName } from '../common/common';
import ProductCard from '../components/product-card';
import HeaderDesktop from '../components/header';
import ResultsHeader from '../partials/results-header';
import Pagination from '../partials/pagination';
import OtherCards from '../partials/other-cards';
import BannerHome from '../partials/banner-home';
import BannerGiftmaker from '../partials/banner-giftmaker';
import BannerCatalog from '../partials/banner-catalogo';
import XmasOpenings from '../partials/banner-xmas-openings';
import Footer from '../components/footer';
import localized from '../common/localization';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import Config from '../Config';
import DataViewSkeleton from '../partials/dataview-skeleton';

const MAX_LAST_PRODUCTS = 45;

const Products = observer( (props) => {
	const isMobile = IsMobile();
	const productStore = useProductStore();
	const optionListsStore = useOptionListsStore();
	/*supports {
		text: '', producerName: ''
		,origCategory: '', origSubcategory: ''
		,bottleSize: '', year: ''
		,country: '', region: '', subregion: ''
		,priceFrom: null, priceTo: null
	})*/
	const [layout, setLayout] = useState('grid');
	const [sortKey, setSortKey] = useState(null);
	const [sortOrder, setSortOrder] = useState(null);
	const [sortField, setSortField] = useState(null);
	const pageContainerRef = useRef();
	const lang = productStore.rootStore.appLang;
	const dataViewRows = isMobile ? 12 : 24;

	/*useEffect( () => {
		//console.log(pageContainerRef);
		const paginatorClicked = (e) => {
			if (e.target && e.target.className && e.target.className.match(/paginator-element|p-paginator-icon/g)) {
				setTimeout( () => {
					window.scrollTo({
						top: 0,
						behavior: "smooth"
					});
				}, 100);
			}
		}

		let curr = pageContainerRef.current;

		if (pageContainerRef.current) {
			curr.addEventListener('click', paginatorClicked, false);
		}
		return () => {
			curr.removeEventListener('click', paginatorClicked, false);
		}
	}, [pageContainerRef.current]);
	*/

	useEffect( () => {
		fetchProducts(productStore, lang);
	}, [lang]);


	/*const deleteFilterByNdx = (ndx) => {
		let f = productStore.filters[ndx];
		if (f.text) {
			searchParams.delete('q');
			setSearchParams({ ...searchParams });
			return;
		}
		productStore.removeFilterByNdx(ndx);
	}*/

	const filteredProducts = useMemo( () => {

		return productStore.items.slice().filter(i => {
			let stock = i.stocks instanceof Object && Math.max(i.stocks.Ecommerce || 0, 0) ?
				i.stocks.Ecommerce : 0;
			return stock > 0;
		}).sort( (a, b) => {
			let r = String(b.createdAt).localeCompare(a.createdAt);
			if (r != 0) {
				return r;
			}
			r = String(a.producerName).localeCompare(b.producerName);
			if (r != 0) {
				return r;
			}
			return +b.year - a.year;
		}).slice(0, MAX_LAST_PRODUCTS).map(i => {
			const vanityName = getProductVanityName(i);
			return {
				...i
				,__vanityName: vanityName
				,__detailPath: getAbsoluteURL(`/products/view/${i._id}/${vanityName}`, lang)
			}
		});
	}, [productStore.items, productStore.items.length, lang]);

	const [skip, setSkipQueryParam, dataViewTemplate] = useDataViewSkip({ dataViewRows, totalItems: filteredProducts.length, pageContainerRef });

	const sortOptions = [
		{label: localized.PriceHighToLow, value: '!grossPrice'},
		{label: localized.PriceLowToHigh, value: 'grossPrice'},
	];

	const onSortChange = (event) => {
		const value = event.value;

		if (value.indexOf('!') === 0) {
			setSortOrder(-1);
			setSortField(value.substring(1, value.length));
			setSortKey(value);
		}
		else {
			setSortOrder(1);
			setSortField(value);
			setSortKey(value);
		}
	}

	const renderListItem = (data) => {
		return <div>TODO: list item</div>;
	}

	const renderGridItem = (data) => {
		const prodObj = mapProductToCardObj(data);
		return <ProductCard key={data._id} product={prodObj} detailPath={data.__detailPath} />
	}

	const itemTemplate = (product, layout) => {
		if (!product) {
			return;
		}

		if (layout === 'list')
			return renderListItem(product);
		else if (layout === 'grid')
			return renderGridItem(product);
	}

	const renderHeader = () => {
		return (
			<div className="home-group-label">
		  		<h2 className=" m-0">{localized.NewArrivals} <i className="pi pi-reply rotate-90" style={{'fontSize': '.75em'}}></i></h2>
		    </div>
		  
	
		 
			/*<div className="grid grid-nogutter">
			<div className="col-6" style={{textAlign: 'left'}}>
			<Dropdown className="sorting-dropdown" options={sortOptions} value={sortKey} optionLabel="label" placeholder={localized.SortByPrice} onChange={onSortChange}/>
			</div>
			<div className="col-6" style={{textAlign: 'right'}}>
			<DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
			</div>
			</div>*/
		);
	}

	const header = renderHeader();

	return (
		<>
			<Helmet>
				<title>Enoteca Ricciardi - Vini d'eccellenza a Milano</title>
				<script type="application/ld+json">{
				JSON.stringify([ {
					"@context": "http://schema.org",
					"@type": "WebSite",
					"name": "Enoteca Ricciardi",
					"description": `${localized.HomeDesc}`,
					"url": Config.FRONTEND_BASE,
					"sameAs": [Config.FRONTEND_ROOT],
					"image": "https://www.enoteca-ricciardi.it/shop/img/er-logo-bott.svg",
					"publisher": {
						"@type": "Organization",
						"name": "Enoteca Ricciardi",
						"logo": "https://www.enoteca-ricciardi.it/shop/img/er-logo-bott.svg"
					},
					"potentialAction": {
						"@type": "SearchAction",
						"target": `${Config.FRONTEND_ROOT}/products?q={search_term}`,
						"query-input": "required name=search_term"
					}
				}, {
					"@context":"https://schema.org",
					"@type":"ItemList",
					"itemListElement": filteredProducts.map( (p, ndx) => ({
						"@type":"ListItem",
						"position": ndx+1,
						"url": `${Config.FRONTEND_BASE}${p.__detailPath}`
					}))
				} ])
				}</script>
				<meta name="description" content={localized.HomeDesc} />
				<meta property="og:title" content="Enoteca Ricciardi - Vini d'eccellenza a Milano" />
				<meta property="og:image" content="https://www.enoteca-ricciardi.it/shop/img/er-logo-bott.svg" />
			</Helmet>
			<ScrollToTopOnMount />
			{/*<div className="double-banner"></div>*/}
			{/*<BannerHome />*/}
			<BannerCatalog />
			
			<div className="cards-container" ref={pageContainerRef}>
				{productStore.items.length == 0  && <>
					<ProgressBar mode="indeterminate" color="#d6a529" style={{ height: '8px' }}></ProgressBar>
					{/*<Skeleton width="30rem" height="30rem" className="mb-2"></Skeleton>*/}
					<DataViewSkeleton />
				</>}
				{productStore.items.length > 0 &&
				<DataView value={filteredProducts} layout={layout} header={header}
				 first={skip} onPage={setSkipQueryParam} paginatorTemplate={dataViewTemplate}
				 itemTemplate={itemTemplate} paginator rows={dataViewRows}
				 sortOrder={sortOrder} sortField={sortField} />
				}
			</div>

		</>	
		
	);
} );

export default Products;
