import React, { useState, useEffect, useRef, Component } from 'react';
import { useAuthStore } from '../hooks/use-stores';
import { useNavigate, useParams, Link } from "react-router-dom";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Divider } from 'primereact/divider';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';

import { getAbsoluteURL, validatePassword } from '../common/common';

const NewPasswordForm = () => {
	const [isSubmitted, setIsSubmitted] = useState(false);
        const [submitting, setSubmitting] = useState(false);
	const defaultValues = {
		password: ''
		,'password-confirm': ''
	}
	const { control, watch, formState: { errors }, handleSubmit, reset } = useForm(defaultValues);
	const toast = useRef(null);
	const authStore = useAuthStore();
        const navigate = useNavigate();
        const params = useParams();
	const watchPassword = watch('password');

	useEffect( () => {
                if (!params.tokenId) {
                        toast.current.show({
				severity: 'warn'
                                ,summary: 'Errore'
                                ,detail: 'Reset link errato: manca il token nell\'url'
                                ,life: 8000
                        });
                }
                if (isSubmitted || !params.tokenId){
			setTimeout( () => {
				navigate(getAbsoluteURL('/auth/login', authStore.rootStore.appLang));
			}, isSubmitted ? 0 : 7000);
                }
        }, [isSubmitted, params.tokenId]);

	const getFormErrorMessage = (name) => {
		return errors[name] && <small className="p-error">{errors[name].message}</small>
	};

	const onSubmit = values => {
                setSubmitting(true);
                authStore.confirmPasswordReset(params.tokenId, values.password).then( () => {
                        toast.current.show({
				severity: 'success'
                                ,summary: 'Operazione eseguita'
                                ,detail: 'La password è stata reimpostata con successo. Puoi autenticarti adesso'
                                ,life: 8000
                        });
                        setIsSubmitted(true);
                }).catch( (err) => {
                        toast.current.show({
				severity: 'warn'
                                ,summary: 'Errore'
                                ,detail: `Impossibile reimpostare la password. Prova ancora, grazie. Errore: ${err.message}`
                                ,life: 8000
                        });
                }).then( () => {
                        setSubmitting(false);
                });
	}

	const passwordFooter = (
	<React.Fragment>
	    <Divider />
	    <p className="mt-2">Suggestions</p>
	    <ul className="pl-2 ml-2 mt-0" style={{lineHeight: '1.5'}}>
		<li>At least one lowercase</li>
		<li>At least one uppercase</li>
		<li>At least one numeric</li>
		<li>Minimum 8 characters</li>
	    </ul>
	</React.Fragment>
	);

        if (isSubmitted || !params.tokenId){
                return <div>Reindirizzamento...</div>
        }

	const getPasswordConfirmationValidator = (value) => {
		if (!value || watchPassword === value) {
			return true;
		}
		return 'The passwords must be equal';
        };

	return (
	<form onSubmit={handleSubmit(onSubmit)} className="">
		<Toast ref={toast} position="top-center"/>
		
			<div className="text-center mb-5">            
			    <div className="text-900 text-3xl font-medium mb-3">Inserisci la nuova password</div>
			</div>

			<div>
			    <label htmlFor="password" className="block text-900 font-medium mb-2">Password</label>
			    <Controller name="password" control={control} rules={{ required: 'Password is required.', validate: validatePassword }}
			     render={({ field, fieldState }) => (
				<Password id={field.name} {...field} toggleMask footer={passwordFooter} className={classNames('w-full', 'mb-2', { 'p-invalid': fieldState.invalid })} />
			    )} />
			    {getFormErrorMessage('password')}

			    <label htmlFor="password-confirm" className="block text-900 font-medium mb-2">Inserisci nuovamente Password</label>
			    <Controller name="password-confirm" control={control} rules={{ required: 'Password confirm is required.', validate: getPasswordConfirmationValidator }}
			     deps={['password']} render={({ field, fieldState }) => (
				<Password id={field.name} {...field} feedback={false} toggleMask className={classNames('w-full', 'mb-5', { 'p-invalid': fieldState.invalid })} />
			    )} />
			    {getFormErrorMessage('password-confirm')}
			    
			    <Button type="submit" label="Invia"  className="w-full p-button-rounded" disabled={submitting}>
			        <i className="pi pi-arrow-right"></i>
			    </Button>
			</div>
		
	</form>
	);
}

export default NewPasswordForm;
