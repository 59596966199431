import { useEffect, useRef } from 'react';
import Breadcrumbs from '../components/breadcrumbs';
import { useRootStore } from '../hooks/use-stores';
import { observer } from 'mobx-react-lite';
import { Helmet } from "react-helmet-async";
import localized from '../common/localization';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';

import { data_it, data_en } from './Cookies-html';

const Cookies = observer( function(props) {
	const rootStore = useRootStore();
	const pageRef = useRef();
	const data = rootStore.appLang == 'it' ? data_it:data_en;

	useEffect( () => {
		var w = window, d = document;
		var loader = function() {
			pageRef.current.innerHTML = '';
			var s = d.createElement("script");
			s.id = "CookieDeclaration";
			s.src = "https://consent.cookiebot.com/1fc8218a-6b28-478b-9db0-69121c3f8546/cd.js";
			pageRef.current.appendChild(s); 
		};
		//setTimeout(loader, 100);
		loader();
	}, [rootStore.appLang]);

	return (
	<>
		<Helmet>
			<title>Cookie Policy | Enoteca Ricciardi Milano</title>
			<script type="application/ld+json">{
			JSON.stringify({
				"@context": "https://schema.org",
				"@type": "WebPage",
				"name": `Cookie Policy - Enoteca Ricciardi Milano`,
				"description": "Cookie Policy"
			})
			}</script>
			<meta property="og:title" content={`Cookie Policy - Enoteca Ricciardi Milano`} />
			<meta property="og:description" content="Cookie Policy" />
			<meta property="og:image" content="https://www.enoteca-ricciardi.it/shop/img/er-logo-bott.svg" />
		</Helmet>

		<ScrollToTopOnMount />
		<Breadcrumbs></Breadcrumbs>
		<div className="page-content-container">
			<div className="page-content" ref={pageRef}>
				<h1>Cookie Policy</h1>
				<div dangerouslySetInnerHTML={{__html: data }}>
				</div>
			</div>	
		</div>

	</>
	);
})

export default Cookies;
