
const strings = {
	en:{
		FaqDesc: 'All the answers to the most frequently asked questions regarding purchases on the Enoteca Ricciardi online shop'
		,ContactsDesc: 'Contact us for more information or to request quotes for particular purchases'
		,PaymentsDesc: 'Discover the payment methods that you can use for purchases on the Enoteca Ricciardi online store'
		,OrdersDesc: 'Discover the information on orders and shipments of purchases on the Enoteca Ricciardi online store'
		,SaleConditionsDesc: 'Consult this page to find information on the conditions of sale of the Enoteca Ricciardi online shop'
		,ReturnsDesc: 'Consult this page to find information on the return policies of the Enoteca Ricciardi online shop'
		,QualityAssuranceDesc: 'Enoteca Ricciardi guarantees the quality and integrity of their products and protects the customer from purchasing defective goods'
		,PrivacyDesc: 'Consult the privacy policy of Enoteca Ricciardi to find out the treatment of your personal data'
		,HomeDesc: 'Enoteca Ricciardi in Milan is known as a place where the excellence of the wines is at home. In fact it offers a prestigious variety of wines, liqueurs and spirits from Italy and from all over the world.'	
		,ProductsDesc: 'All products from the online shop of Enoteca Ricciardi. A wider selection is available in store'
		,ProductsPromoDesc: 'The products on sale in the Enoteca Ricciardi online shop. A wider selection is available in the store.'
		,ProductsDesc_f_ViniRossi: 'Our selection of the best Italian, French red wines and those from the most interesting wine regions in the rest of the world. A wider selection is available in store, come and visit us!'
		,ProductsDesc_f_ViniBianchi: 'Our selection of the best Italian, French white wines and those from the most interesting wine regions in the rest of the world. A wider selection is available in store, come and visit us!'
		,ProductsDesc_f_Spumanti: 'Our selection of the best Italian sparkling wines from the most interesting wine regions. A wider selection is available in store, come and visit us!'
		,ProductsDesc_Champagne: 'Our selection of Champagne from the most interesting récoltants and the most prestigious négociants. A wider selection is available in store, come and visit us!'
		,ProductsDesc_f_Altro: 'Our selection of rosé, sweet and fortified wines, cider, sake and other products from the Enoteca Ricciardi online shop. A wider selection is available in store, come and visit us!'
		,ProductsDesc_f_Superalcolici: 'Our selection of spirits from the Enoteca Ricciardi online shop. A wider selection is available in store, come and visit us!'
	},
	
	it: {
		FaqDesc: 'Tutte le risposte alle domandi più frequenti riguardo l\'acquisto sullo negozio online di Enoteca Ricciardi'
		,ContactsDesc: 'Contattaci per avere maggiori informazioni o per richiedere preventivi per acquisti particolari'
		,PaymentsDesc: 'Scopri le modalità di pagamento che potrai utilizzare per gli acquisti sul negozio online di Enoteca Ricciardi'
		,OrdersDesc: 'Scopri le informazioni sugli ordini e le spedizioni degli acquisti sul negozio online di Enoteca Ricciardi'
		,SaleConditionsDesc: 'Consulta questa pagina per trovare informazioni sulle condizioni di vendita del negozio online di Enoteca Ricciardi'
		,ReturnsDesc: 'Consulta questa pagina per trovare informazioni sulle politiche di reso del negozio online di Enoteca Ricciardi'
		,QualityAssuranceDesc: 'Enoteca Ricciardi garantisce la qualità e l\'integrità dei loro prodotti e protegge il cliente dall\'acquisto di beni difettosi'
		,PrivacyDesc: 'Consulta la privacy policy di Enoteca Ricciardi per scoprire il trattamento dei tuoi dati personali'
		,HomeDesc: 'L\'Enoteca Ricciardi a Milano è conosciuta come luogo dove l\'eccellenza dei vini è di casa. Oggi come ieri offre una prestigiosa varietà di vini, liquori e distillati provenienti dall\'Italia e da tutto il mondo.'
		,ProductsDesc: 'Tutti i prodotti dello shop online dell\'Enoteca Ricciardi. In negozio è disponibile una selezione più ampia'
		,ProductsPromoDesc: 'I prodotti in promozione nello shop online dell\'Enoteca Ricciardi. In negozio è disponibile una selezione più ampia'
		,ProductsDesc_f_ViniRossi: 'La nostra selezione dei migliori vini rossi italiani, francesi e di quelli provenienti dalle zone vinicole più interessanti nel resto del mondo. In negozio è disponibile un assortimento più ampio, vieni a trovarci!'
		,ProductsDesc_f_ViniBianchi: 'La nostra selezione dei migliori vini bianchi italiani, francesi e di quelli provenienti dalle zone vinicole più interessanti nel resto del mondo. In negozio è disponibile un assortimento più ampio, vieni a trovarci!'
		,ProductsDesc_f_Spumanti: 'La nostra selezione dei migliori spumanti italiani provenienti dalle zone vinicole più interessanti. In negozio è disponibile un assortimento più ampio, vieni a trovarci!'
		,ProductsDesc_Champagne: 'La nostra selezione di Champagne dei più interessanti Récoltant e dei Négociant di maggior pregio. In negozio è disponibile un assortimento più ampio, vieni a trovarci!'
		,ProductsDesc_f_Altro: 'La nostra selezione di vini rosé, dolci, liquorosi, sidro, saké ed altri prodotti dello shop online dell\'Enoteca Ricciardi. In negozio è disponibile un assortimento più ampio, vieni a trovarci!'
		,ProductsDesc_f_Superalcolici: 'La nostra selezione di distillati dello shop online dell\'Enoteca Ricciardi. In negozio è disponibile un assortimento più ampio, vieni a trovarci!'

	}
}

export default strings;
