const data_it = `
<h1>Garanzia di qualità</h1>
<p>I prodotti venduti su enoteca-ricciardi.it sono da considerarsi di prima scelta e non presentano alterazione qualitative tipiche della seconda scelta o similari, sempre che non sia espressamente dichiarato.</p>
<p>Gli articoli trattati su questo sito sono acquistati direttamente dal produttore o da distributori/rivenditori autorizzati che a loro volta acquistano dal produttore.</p>
<p>Gli articoli sono coperti da garanzia per qualsiasi difetto o vizio inerente il prodotto stesso, da segnalare mediante raccomandata, email o fax ai nostri recapiti, entro 10 giorni dalla consegna. </p>
<p>A seguito della ricezione di questa comunicazione ci impegnamo a organizzare il rientro dei beni difettosi. </p>
<p>Le spese di spedizione per il rientro dei beni e per il reinvio della merce in sostituzione sono a nostro carico.<br />Qualora i prodotti da sostituire non fossero più disponibili, ci riserviamo la facoltà di sostituire gli stessi con altri di pari valore o di risarcire per il valore della merce, spese di spedizione escluse.</p>
`;

const data_en = `
<h1>Quality Assurance</h1>
<p>The products sold on enoteca-ricciardi.it are of prime high quality and do not have alterations typical of other second-class products, unless specifically declared.</p>
<p> All items sold through this website are directly purchased from the producers or from authorised distributors/dealers that, in turn, buy them from producers.</p>
<p>The items are covered by warranty for any product flaws or faults, which should be reported by registered letter, email or fax to Ricciardi Srl within 10 days from the delivery date. </p>
<p>After receipt of this notice, Ricciardi Srl will arrange for the return of faulty goods. </p>
<p>Ricciardi Srl will pay for shipment expenses for the return of goods and for the dispatch of replacement products.<br />Should the replacement products no longer be available, Ricciardi Srl reserves the right to replace these products with others of the same value or to refund the Customer, excluding shipping costs. </p>
`;

export {data_it, data_en};

