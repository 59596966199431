import React, { useState, useEffect, useRef, useMemo, Component } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm, Controller } from 'react-hook-form';
import { useAuthStore } from '../hooks/use-stores';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import localized from '../common/localization';
import { useRootStore } from '../hooks/use-stores';


import { getAbsoluteURL, validatePassword, EMAIL_RE } from '../common/common';
import Config from '../Config';

const RegisterForm = observer( (props) => {
	const rootStore = useRootStore()
    const lang = useMemo(() => rootStore.appLang, [rootStore.appLang])
	const authStore = useAuthStore();
	const location = useLocation();
        const navigate = useNavigate();
	const redirectTo = location.state && location.state.from ? location.state.from : Config.MOUNT_POINT;

	const [submitting, setSubmitting] = useState(false);
	const defaultValues = {
		email: ''
		,password: ''
	}
	const { control, watch, formState: { errors }, handleSubmit, reset } = useForm(defaultValues);
	const toast = useRef(null);

	useEffect( () => {
		if (authStore.isAuthenticated) {
			console.log('redirect to value', redirectTo);
			navigate(redirectTo);
		}
	}, []);

	const passwordFooter = (
		<React.Fragment>
		    <Divider />
		    <p className="mt-2">Suggestions</p>
		    <ul className="pl-2 ml-2 mt-0" style={{lineHeight: '1.5'}}>
			<li>At least one lowercase</li>
			<li>At least one uppercase</li>
			<li>At least one numeric</li>
			<li>Minimum 8 characters</li>
		    </ul>
		</React.Fragment>
	);

	const getFormErrorMessage = (name) => {
		return errors[name] && <small className="p-error">{errors[name].message}</small>
	};

	const onSubmit = values => {
		setSubmitting(true);
		authStore.signup({
			email: values.email, password: values.password
			//,firstName: values.firstName, lastName: values.lastName
		}).then( () => {
			toast.current.show({
				severity: 'success'
				,summary: localized.RegistrationSuccessful
				,detail: localized.RegistrationSuccessfulDetail
				,life: 5000
			});
			return true;
		}).catch( (err) => {
			if (err.name === 'Conflict') {
				toast.current.show({
					severity: 'warn'
					,summary: localized.RegistrationImpossible
					,detail: localized.MailAlreadyUsed
					,life: 5000
				});
			}
			else {
				toast.current.show({
					severity: 'warn'
					,summary: localized.RegistrationImpossible
					,detail: localized.formatString(localized.RegistrationError, err.message)
					,life: 5000
				});
			}
			return false;
		}).then( (isOk) => {
			setTimeout( () => {
				setSubmitting(false);
				if (isOk) {
					navigate(getAbsoluteURL('/auth/login', authStore.rootStore.appLang));
				}
			}, 4500);
		});
	}

	if (authStore.isAuthenticated){
		//if we're here, we're logged in
		return <div>{localized.Redirecting}</div>
	}

	return (<form onSubmit={handleSubmit(onSubmit)} className="">
		<Toast ref={toast} position="top-center"/>
		
		    
				<div className="text-center mb-5">            
				    <div className="text-900 text-3xl font-medium mb-1">{localized.Welcome}</div>
				    <span className="text-600 font-medium line-height-3">{localized.RegisterAccount}</span>			
				</div>

				<div>
				    <label htmlFor="email" className="block text-900 font-medium mb-2">{localized.YourEmail}</label>
				    {getFormErrorMessage('email')}
				    <Controller name="email" control={control} rules={{ required: localized.EmailRequired, pattern: { value: EMAIL_RE, message: localized.EmailInvalid }}}
				     render={({ field, fieldState }) => (
				      <InputText id={field.name} type="email" {...field} autoFocus className={classNames('w-full', 'mb-3', { 'p-invalid': fieldState.invalid })} />
				    )} />
				    

				    <label htmlFor="password" className="block text-900 font-medium mb-2">{localized.ChoosePassword}</label>
				    {getFormErrorMessage('password')}
				    <Controller name="password" control={control} rules={{ required: localized.PasswordRequired, validate: validatePassword }}
				     render={({ field, fieldState }) => (
					<Password id={field.name} {...field} toggleMask footer={passwordFooter} className={classNames('w-full', 'mb-5', { 'p-invalid': fieldState.invalid })} />
				    )} />
				    

				    <Button type="submit" label={localized.Register}  className="w-full p-button-rounded" disabled={submitting}>
				      <i className="pi pi-arrow-right"></i>
				    </Button>
				</div>
		    
		
	</form>);
} );

export default RegisterForm;
