import React, { useState } from 'react';
import {Button} from 'primereact/button';
import { useProductStore } from '../hooks/use-stores';

const BannerCatalog = () => {

    const productStore = useProductStore();
    const lang = productStore.rootStore.appLang;
    
    return (

        <div className="catalog-launch">
            {lang=="en" && <React.Fragment>
                    <h2>LACK OF IDEAS FOR YOUR CHRISTMAS GIFTS?</h2>
                    <p>Browse our Christmas catalogue, be inspired, and stop by the store to order your gifts.<br/> If you need we can ship them.</p>                
                    <a href={process.env.PUBLIC_URL + "/pdf/ER-Natale2024.pdf"} target="_blank" className="p-button p-component btn-honey-bunny">
                        <span className="p-button-icon p-c p-button-icon-right pi pi-arrow-right"></span>
                        <span className="p-button-label p-c">Download the catalogue</span>
                    </a>
                    

            </React.Fragment> }
            {lang!="en" && <React.Fragment>
                <h2>A CORTO DI IDEE PER I TUOI REGALI DI NATALE?</h2>
                <p>Sfoglia il nostro catlaogo di Natale, lasciati ispirare, e passa in negozio a ordinare i tuoi regali. <br/>Se hai bisogno li possiamo spedire noi.</p>
                    <a href={process.env.PUBLIC_URL + "/pdf/ER-Natale2024.pdf"} target="_blank" className="p-button p-component btn-honey-bunny">
                        <span className="p-button-icon p-c p-button-icon-right pi pi-arrow-right"></span>
                        <span className="p-button-label p-c">Scarica il catalogo</span>
                    </a>
            </React.Fragment> }
        </div>
       
    );
}
export default BannerCatalog;
