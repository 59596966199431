import { observer } from 'mobx-react-lite';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import localized from '../common/localization';
import { useRootStore } from '../hooks/use-stores';

const NotFound = observer( (props) => {
	const rootStore = useRootStore();
	const lang = rootStore.appLang;

        return (
	<div>
		<ScrollToTopOnMount />
		<div className="no-results">
			<h3>{localized.NoPageFound}</h3>
		</div>
	</div>
        );
} );

export default NotFound;
