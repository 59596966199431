const data_it = `
<h1>Ordini e spedizioni</h1>
<p>Le modalità di consegna disponibili sono:</p>
<ul>
    <li>Spedizione via corriere</li>
    <li>Consegna a domicilio (solo Milano Citta)</li>
    <li>Ritiro presso il nostro punto vendita (piazza Vesuvio, 11 - Milano)</li>
</ul>

<p>Le nostre spedizioni sono sicure e veloci ,ci affidiamo a corriere espressi affidabili:<br />Dhl, Yamato</p>
<p>In Italia avvengono in 1 o 2 giorni lavorativi successivi all’ordine; nel caso fossero necessari più di 3 giorni, il Cliente acquirente sarà tempestivamente avvisato.<br />La spedizione avviene via corriere DHL ed è disponibile, oltre che per l'Italia, per l’Europa e per il resto del Mondo.<br />Per il Giappone ,Hong Kong e Singapore ci affidiamo a Yamato.</p>
<p>Per le spedizioni internazionali la spedizione è via corriere standard (3/4 giorni).</p>
<p>Tutti i pacchi sono confezionati con la massima cura, utilizzando imballaggi in grado di proteggere il contenuto anche in caso di urti o cadute accidentali. L’interno degli speciali imballaggi è in polistirolo che, oltre ad offrire un'ottima resistenza, preserva i prodotti dagli sbalzi di temperatura che potrebbero subire durante il trasporto nei mesi estivi e invernali.</p>
<p>La consegna avverrà all'indirizzo di destinazione indicato dal Cliente acquirente nel modulo d'ordine.<br />Se al momento della consegna il destinatario è assente, l'incaricato lascerà un messaggio per concordare un altro orario di consegna o permettere il ritiro del pacco presso la sede del corriere più vicina.<br />Qualora l'imballaggio presentasse evidenti segni di manomissione o deformazioni che possano far presumere qualsiasi danneggiamento o alterazione del contenuto, il Cliente acquirente potrà rifiutare il pacco oppure, in alternativa, accettarlo firmando la bolla di consegna con la dicitura "Con riserva di controllo", in modo da poter controllare il contenuto del pacco e accertarsi del buono stato della merce.<br />Nel caso che uno o più prodotti fossero non in perfette condizioni perché danneggiatisi durante il trasporto, il Cliente acquirente non dovrà fare altro che informarci via mail, fax o telefono: provvederemo a nostre spese e nel più breve tempo possibile a sostituire i prodotti danneggiati o, qualora ciò non fosse possibile, a rimborsare del loro importo.</p>
<p>La consegna a domicilio è disponibile in Milano città. Scegliendo questa modalità di consegna, dopo l'ordine contatteremo il Cliente acquirente per concordare il giorno e l'orario della consegna a domicilio.<br />In alternativa alla spedizione o consegna a domicilio, offriamo la possibilità di ritirare personalmente la merce presso il punto vendita, di Milano in piazza Vesuvio 11, inserendo questo stesso indirizzo nell'apposito campo Dati Spedizione.<br />La consegna è gratuita a Milano città per ordini superiori a 150€, la consegna viene effettuata in giornata per gli ordini pervenuti entro le ore 12.00.</p>
<p>La consegna è gratuita in Italia per ordini superiori a 300€.</p>
<h2>Tariffe di spedizione </h2>
<p>I prezzi di spedizione di seguito riportati sono un contributo al costo che sosteniamo per imballare e spedire la merce e sono sempre iva inclusa.</p>
<p><strong>Spedizioni in Italia</strong></p>

<ul class="orders-page-list">
        <li>Da 1 a 3 bt: 12,00 €</li>
        <li>Da 4 a 6 bt: 15,00 €</li>
        <li>Da 6 a 12 bt: € 20,00</li>
        <li>Da 12 a 18 bt: € 30,00</li>
    </ul>

</p>
<p>
    <p><small>Prezzi con IVA.</small></p>
</p>
<p>
    <p><strong>Per i seguenti paesi, eventuali tasse doganali sono a carico del destinatario</strong></p>
    <p>Svizzera</p>
    <ul class="orders-page-list">
        <li>1 bottiglia: 47,00 €</li>
        <li>2/3 bottiglie: 50,00 €</li>
        <li>6 bottiglie: € 65,00</li>
        <li>12 bottiglie: € 70,00</li>
    </ul>
    <p>Hong Kong / Singapore</p>
    <ul class="orders-page-list">
        <li>1 bottiglia: 83,00 €</li>
        <li>2/3 bottiglie: 100,00 €</li>
        <li>6 bottiglie: € 196,00</li>
        <li>12 bottiglie: € 300,00</li>
    </ul>
    <p>Giappone</p>
    <ul class="orders-page-list">
        <li>1 bottiglia: 83,00 €</li>
        <li>2/3 bottiglie: 100,00 €</li>
        <li>6 bottiglie: € 200,00</li>
        <li>12 bottiglie: € 310,00</li>
    </ul>
</p>

    <p>Per qualunque dubbio o per la quotazione di spedizioni in Stati non indicati nella tabella, <a href="/shop/contact-us">Contattaci</a></p>



`;

const data_en = `
<h1>Orders and shipments</h1>
<p>Shipments available are as follows:</p>
<ul>
    <li>By courier</li>
    <li>Home delivery</li>
    <li>Pick up from our point of sale (piazza Vesuvio, 11 - Milano).</li>
</ul>
<p>Our shipments are safe and quick, we use trustworthy couriers:</p>
<ul>
    <li>Dhl</li>
    <li>Yamato</li>
</ul>
<p>Shipments in Italy take place one or two working days after the order. If more than three days are necessary, the Customer will be promptly notified.<br />Items are shipped by DHL courier in Italy, Europe and in the rest of the world.<br />For shipments to Japan, Hong Kong and Singapore we use Yamato.</p>
<p>For international shipments the option available is shipment by standard courier (3/4 days).</p>
<p>All parcels are packaged with the utmost care, using packaging that can protect the content also in the event of accidental impact and falls. The special packaging is filled with polystyrene that, in addition to being very strong and durable, protects the products from temperature changes that may occur during transport in winter and summer months.</p>
<p>Delivery shall be made to the destination address indicated by the Customer on the order form.<br />If the recipient is not available at the time of delivery, the delivery person will leave a message to agree on another delivery time or arrange for the parcel to be picked up at the nearest office of the courier’s.<br />If the packaging shows clear signs of tampering or deformations that may suggest damage or alteration to the contents, the Customer has the right to refuse the package or, alternatively, accept it by signing the delivery note adding: "Acceptance subject to control", so as to check the content of the package and verify that it is in good condition.<br />If one or more products are not in perfect condition due to damage suffered during transport, the Customer shall inform Ricciardi Srl via email, fax or telephone: we will arrange for the replacement of the damaged products at its own expense and in the shortest possible time or, if that is not possible, to refund the relevant amount.</p>
<p>Home delivery is available in the city of Milan only. By choosing this type of delivery, once the order has been placed, we will contact the Customer to make an appointment for a specific day and time for home delivery.<br />As an alternative to shipment or home delivery, Ricciardi Srl offers customers the possibility to pick up their purchases at its store in Milan, piazza Vesuvio 11. Just put this address in the Shipment Details field.<br />Orders for over 150€ are eligible for free delivery in the city of Milan, with same-day delivery for orders received before 12 noon.</p>
<p>Delivery is free in all of Italy for orders over 300€.</p>
<h2>SHIPMENT TARIFFS</h2>
<p>The shipment costs mentioned below are a contribution to the cost we have to package and ship the goods and always include VAT.</p>
<p><strong>Shipments in Italy</strong></p>
<ul class="orders-page-list">
    <li>From 1 to 3 btl: 12,00 €</li>
    <li>From 4 to 6 btl: 15,00 €</li>
    <li>From 6 to 12 btl: € 20,00</li>
    <li>From 12 to 18 btl: € 30,00</li>
</ul>
<p><strong> For the following countries, any customs duties are paid by the recipient</strong></p>

<p>Switzerland</p>
<ul class="orders-page-list">
    <li>1 bottle: 47,00 €</li>
    <li>2/3 bottles: 50,00 €</li>
    <li>6 bottles: € 65,00</li>
    <li>12 bottles: € 70,00</li>
</ul>
<p>Hong Kong / Singapore</p>
<ul class="orders-page-list">
    <li>1 bottle: 83,00 €</li>
    <li>2/3 bottles: 100,00 €</li>
    <li>6 bottles: € 196,00</li>
    <li>12 bottles: € 300,00</li>
</ul>
<p>Japan</p>
<ul class="orders-page-list">
    <li>1 bottle: 83,00 €</li>
    <li>2/3 bottles: 100,00 €</li>
    <li>6 bottles: € 200,00</li>
    <li>12 bottles: € 310,00</li>
</ul>

<p>For any enquiries or for the prices for the shipment to countries that are not mentioned in the table please <a href="/shop/contact-us?hl=en">Send us an email.</a></p>

`;

export {data_it, data_en};

