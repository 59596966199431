import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import LoginForm from '../partials/form-login';

const SiderLogin = (props) => {
    
    const onLoginNavigate = () => {
    	if (props.onLoginNavigate) {
		props.onLoginNavigate();
	}
    }
      

    const customIcons = (
        <React.Fragment>
            <button className="p-sidebar-icon p-link mr-1">
                <span className="pi pi-print" />
            </button>
            <button className="p-sidebar-icon p-link mr-1">
                <span className="pi pi-arrow-right" />
            </button>
        </React.Fragment>
    );

    return (
        
                

                <Sidebar className="login-sidebar" visible={props.sidebarOpen} fullScreen onHide={() => {props.onSidebarClose()}}>
                    <div className="flex align-items-center justify-content-center">                      
                      <LoginForm onNavigate={onLoginNavigate} />
                    </div>
                    
                </Sidebar>

               
         
    )
}

export default SiderLogin;
