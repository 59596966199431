import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { useAuthStore } from '../hooks/use-stores';
import { getAbsoluteURL } from '../common/common';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';

import Config from '../Config';

const Verification = (props) => {
        const authStore = useAuthStore();
        const location = useLocation();
        const redirectTo = location.state && location.state.from ? location.state.from : Config.MOUNT_POINT;
        const navigate = useNavigate();
        const params = useParams();
        const [status, setStatus] = useState(null); //'ok', 'ko', null
        const [errorMessage, setErrorMessage] = useState('');

	useEffect( () => {
		authStore.verifySignup(params.tokenId).then( () => {
			setStatus('ok');
			setTimeout( () => {
				navigate(redirectTo);
			}, 4500);
		}).catch( err => {
			console.error(err);
			setErrorMessage(err.message);
			setStatus('ko');
		});
	}, []);

	return (
	<div className="login-wrapper">
		<ScrollToTopOnMount />
		<div className="flex flex-column align-items-center justify-content-center">
			<div className="flex align-items-center justify-content-center">
				<div className=" p-4 border-round w-full">
					<div className="text-center mb-5">            
						<div className="text-900 text-3xl font-medium mb-3">Verifica mail</div>
					</div>
					<div>
						{ status === null && <div>
							Verifica email in corso... <ProgressSpinner />
						</div>}
						{ status === 'ok' &&
							<Message severity="success" text="Mail verificata con successo"></Message>
						}
						{ status === 'ko' &&
							<Message severity="error" text={`Verifica fallita, riprova a verificare la tua mail. Errore: ${errorMessage}`}></Message>
						}
						<div>
							<Link to={getAbsoluteURL('/auth/login', authStore.rootStore.appLang)}>Login</Link>
							<Link to={getAbsoluteURL('/auth/password-forgotten', authStore.rootStore.appLang)}>Password dimenticata?</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	);
}

export default Verification;
