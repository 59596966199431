const Defs = {
        IN_STORE_ORDER: 'in-store'
        ,ECOMMERCE_SNIPCART_ORDER: 'snipcart'
        ,DISCOUNT_FAKE_ID: '_discount_'
	,PRODUCT_LOW_STOCK_LIMIT: 1
	,CATEGORY_WINE: 'Vini'
	,CATEGORY_CHAMPAGNE: 'Champagne'
	,CATEGORY_SPUMANTE: 'Spumanti'
};

export default Defs;
