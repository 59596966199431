/*
 * REMEMBER TO MAP THESE URLS TO THE OLD WEBSITE URLS IN THE SERVER SIDE MIDDLEWARE
 * */
const criteria = {
	'vini-rossi-tutti': {
		label: 'f_ViniRossi'
		,filters: [ { cat: 'vini', subcat: 'rosso' }, { cat: 'vini', subcat: 'rosso frizzante' }, { cat: 'vini', subcat: 'rosato frizzante' } ]
		,localizedKeys: {
			en: 'red-wines-all'
		}
	}
	,'vini-bianchi-tutti': {
		label: 'f_ViniBianchi'
		,filters: [ { cat: 'vini', subcat: 'bianco' } ]
		,localizedKeys: {
			en: 'white-wines-all'
		}
	}
	,'spumanti': {
		label: 'f_Spumanti'
		,filters: [ { cat: 'vini', subcat: 'bianco frizzante' }, { cat: 'spumanti', subcat: 'bianco' }, { cat: 'spumanti', subcat: 'rosso' }
			,{ cat: 'spumanti', subcat: 'rosé' }, { cat: 'spumanti', subcat: 'spumanti dolci' }
		]
		,localizedKeys: {
			en: 'sparkling-wine'
		}
	}
	,'champagne': {
		label: 'Champagne'
		,filters: [ { cat: 'champagne', subcat: 'bianco' }, { cat: 'champagne', subcat: 'rosé' } ]
		,localizedKeys: {
			en: 'champagne'
		}
	}
	,'altro': {
		label: 'f_Altro'
		,filters: [ { cat: 'vini', subcat: 'rosato' }, { cat: 'vini', subcat: 'rosé' }, { cat: 'vini', subcat: 'bianco dolce' }, , { cat: 'vini', subcat: 'rosso dolce' }
			, { cat: 'spumanti', subcat: 'spumanti dolci' }, { cat: 'vini', subcat: 'liquoroso-fortificato' }
			, { cat: 'distillati', subcat: 'sidro' }, { cat: 'distillati', subcat: 'saké' }
		]
		,localizedKeys: {
			en: 'others'
		}
	}
	,'superalcolici': {
		label: 'f_Superalcolici'
		,filters: [ { cat: 'distillati', subcat: 'whisky' }, { cat: 'distillati', subcat: 'whiskey' }, { cat: 'distillati', subcat: 'vodka' }
			, { cat: 'distillati', subcat: 'tequila' }, { cat: 'distillati', subcat: 'rum' }, { cat: 'distillati', subcat: 'mezcal' }
			, { cat: 'distillati', subcat: 'liquore' }, { cat: 'distillati', subcat: 'grappa' }, { cat: 'distillati', subcat: 'gin' }
			, { cat: 'distillati', subcat: 'cognac' }, { cat: 'distillati', subcat: 'calvados' }, { cat: 'distillati', subcat: 'cachaca' }
			, { cat: 'distillati', subcat: 'brandy' }, { cat: 'distillati', subcat: 'bas-armagnac' }, { cat: 'distillati', subcat: 'armagnac' }
		]
		,localizedKeys: {
			en: 'spirits'
		}
	}
	,'vino-rosso': {
		label: 'Vino Rosso'
		,filters: [ { cat: 'vini', subcat: 'rosso' } ]
		,localizedKeys: {
			en: 'red-wine'
		}
	}
	,'vino-rosso-frizzante': {
		label: 'Vino Rosso Frizzante'
		,filters: [ { cat: 'vini', subcat: 'rosso frizzante' } ]
		,localizedKeys: {
			en: 'sparkling-red-wine'
		}
	}
	,'vino-rosato-frizzante': {
		label: 'Vino Rosato Frizzante'
		,filters: [ { cat: 'vini', subcat: 'rosato frizzante' } ]
		,localizedKeys: {
			en: 'sparkling-rose-wine'
		}
	}
	,'vino-bianco': {
		label: 'Vino Bianco'
		,filters: [ { cat: 'vini', subcat: 'bianco' } ]
		,localizedKeys: {
			en: 'white-wine'
		}
	}
	,'spumante-bianco': {
		label: 'Spumante Bianco'
		,filters: [ { cat: 'vini', subcat: 'bianco frizzante' }, { cat: 'spumanti', subcat: 'bianco' } ]
	}
	,'spumante-rosso': {
		label: 'Spumante Rosso'
		,filters: [ { cat: 'spumanti', subcat: 'rosso' } ]
	}
	,'spumante-rose': {
		label: 'Spumante Rosé'
		,filters: [ { cat: 'spumanti', subcat: 'rosé' } ]
	}
	,'spumante-dolce': {
		label: 'Spumante Dolce'
		,filters: [ { cat: 'spumanti', subcat: 'spumanti dolci' } ]
	}
	,'champagne-bianco': {
		label: 'Champagne Bianco'
		,filters: [ { cat: 'champagne', subcat: 'bianco' } ]
	}
	,'champagne-rose': {
		label: 'Champagne Rosé'
		,filters: [ { cat: 'champagne', subcat: 'rosé' } ]
	}
	,'vino-rosato': {
		label: 'Vino Rosato'
		,filters: [ { cat: 'vini', subcat: 'rosato' }, { cat: 'vini', subcat: 'rosé' } ]
	}
	,'vino-dolce': {
		label: 'Vino Dolce'
		,filters: [ { cat: 'vini', subcat: 'bianco dolce' }, , { cat: 'vini', subcat: 'rosso dolce' }, { cat: 'spumanti', subcat: 'spumanti dolci' } ]
	}
	,'vino-liquoroso-fortificato': {
		label: 'Vino Liquoroso / Fortificato'
		,filters: [ { cat: 'vini', subcat: 'liquoroso-fortificato' } ]
	}
	,'sidro': {
		label: 'Sidro'
		,filters: [ { cat: 'distillati', subcat: 'sidro' } ]
	}
	,'sake': {
		label: 'Saké'
		,filters: [ { cat: 'distillati', subcat: 'saké' } ]
	}
	,'superalcolici-whisky': {
		label: 'Whisky'
		,filters: [ { cat: 'distillati', subcat: 'whisky' } ]
	}
	,'superalcolici-whiskey': {
		label: 'Whiskey'
		,filters: [ { cat: 'distillati', subcat: 'whiskey' } ]
	}
	,'superalcolici-vodka': {
		label: 'Vodka'
		,filters: [ { cat: 'distillati', subcat: 'vodka' } ]
	}
	,'superalcolici-tequila': {
		label: 'Tequila'
		,filters: [ { cat: 'distillati', subcat: 'tequila' } ]
	}
	,'superalcolici-rum': {
		label: 'Rum'
		,filters: [ { cat: 'distillati', subcat: 'rum' } ]
	}
	,'superalcolici-mezcal': {
		label: 'Mezcal'
		,filters: [ { cat: 'distillati', subcat: 'mezcal' } ]
	}
	,'superalcolici-liquore': {
		label: 'Liquori'
		,filters: [ { cat: 'distillati', subcat: 'liquore' } ]
	}
	,'superalcolici-grappa': {
		label: 'Grappa'
		,filters: [ { cat: 'distillati', subcat: 'grappa' } ]
	}
	,'superalcolici-gin': {
		label: 'Gin'
		,filters: [ { cat: 'distillati', subcat: 'gin' } ]
	}
	,'superalcolici-cognac': {
		label: 'Cognac'
		,filters: [ { cat: 'distillati', subcat: 'cognac' } ]
	}
	,'superalcolici-calvados': {
		label: 'Calvados'
		,filters: [ { cat: 'distillati', subcat: 'calvados' } ]
	}
	,'superalcolici-cachaca': {
		label: 'Cachaca'
		,filters: [ { cat: 'distillati', subcat: 'cachaca' } ]
	}
	,'superalcolici-brandy': {
		label: 'Brandy'
		,filters: [ { cat: 'distillati', subcat: 'brandy' } ]
	}
	,'superalcolici-bas-armagnac': {
		label: 'Bas-Armagnac'
		,filters: [ { cat: 'distillati', subcat: 'bas-armagnac' } ]
	}
	,'superalcolici-armagnac': {
		label: 'Armagnac'
		,filters: [ { cat: 'distillati', subcat: 'armagnac' } ]
	}
}

const fullCriteriaObj = Object.keys(criteria).reduce( (acc, currVal) => {
	let o = criteria[currVal];
	acc[currVal] = o;
	if (o.localizedKeys) {
		Object.keys(o.localizedKeys).forEach( lang => {
			acc[ o.localizedKeys[lang] ] = {
				...o
				,_lang: lang
				,_mainKey: currVal
			};
		});
	}
	return acc;
}, {});

const getLocalizedFilterClass = (key, lang) => {
	return fullCriteriaObj[key] ?
		(fullCriteriaObj[key].localizedKeys && fullCriteriaObj[key].localizedKeys[lang] || key)
		: key;
}

//console.log('full criteria obj', fullCriteriaObj);

export default criteria;
export { fullCriteriaObj, getLocalizedFilterClass };
