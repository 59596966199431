
const data_it = `
<h1>Domande frequenti</h1>
<h2>Prodotti</h2>
<p><strong>Tutti i prodotti presenti sul nostro shop sono sempre disponibili?</strong><br />Si, salvo diversa specifica, i vini acquistabili sullo notro shop sono disponibili per la vendita e presenti in negozio.<br /><br /></p>
<h2>Ordine</h2>
<p><strong>Come effettuo un ordine?</strong><br />Puoi effettuare un ordine cercando i prodotti di tuo interesse attraverso il motore di ricerca dell'e-commerce o attraverso i filtri di selezione. Dopo aver cercato i prodotti di tuo interesse, occorrerà cliccare sul pulsante "aggiungi al carrello" e in seguito cliccare su procedi all’acquisto per procedere con l’ordine.<br /><br /></p>
<p><strong>Devo registrarmi per ordinare?</strong><br />Non è richiesta una registrazione completa per effettuare un ordine, ti basterà lasciare il tuo indirizzo mail e proseguire con l’acquisto. Successivamente ti verrà inviata una mail contenete una password per effettuare i futuri accessi e gestire il tuo account.<br /><br /></p>
<h2>Pagamenti</h2>
<p><strong>Come posso pagare?</strong><br />I metodi di pagamento accettati sono: Carta di credito, Bonifico Bancario e Paypal.<br /><br /></p>
<p><strong>Accettate il pagamento in contrassegno?</strong><br />No, non è previsto il pagamento in contrassegno.<br /><br /></p>
<h2>Fattura</h2>
<p><strong>Potete farmi fattura?</strong><br />Se hai bisogno della fattura, nel processo d'acquisto, ricordati di inserire anche l'indirizzo di fatturazione compilando l'apposito form. La fattura verrà emessa successivamente al pagamento e consegna dell'ordine. Non è possibile richiedere la fattura in momenti non contestuali all'acquisto on-line.<br /><br /></p>
<h2>Spedizioni</h2>
<p><strong>Come avviene la consegna?</strong><br />La consegna avviene tramite corriere espresso DHL per la consegne internazionali e nazionali, e tramite il nostro staff nella città di Milano. Ti verrà chiesto, nel processo di acquisto, di inserire un tuo numero di telefono così da poter essere facilmente reperibile alla consegna.<br /><br /></p>
<p><strong>E se il corriere non trova nessuno a casa?</strong><br />Nel caso in cui, il corriere non trovasse nessuno in casa, lascerà un avviso e ritenterà la consegna nei giorni successivi. Per maggiori informazioni sulle spedizioni clicca <Navlink to="orders">qui</Navlink>.<br /><br /></p>
<h2>Regalo</h2>
<p><strong>Posso spedire un prodotto presso un indirizzo diverso dal mio domicilio?</strong><br />Puoi acquistare i prodotti on-line e scegliere una diversa destinazione rispetto ad una inserita precedentemente. I prodotti verranno recapitati, nei tempi previsti, all'indirizzo da te specificato.<br /><br /></p>
<p><strong>Posso spedire un prodotto con una confezione regalo?</strong><br />Si, la confezione regalo è però disponibile solo per le spedizioni nella città di Milano, con un piccolo sovrapprezzo.<br /><br /></p>
<p><strong>Come posso inviare uno o più regali?</strong><br />Se vuoi organizzare uno o più regali puoi utilizzare l'apposito form che trovi a questo link, specificando i tuoi dati personali, il budget a disposizione, il numero di confezioni, le tempistiche di consegna, le preferenze sui prodotti e un messaggio da allegare. Potrai inoltre richiedere un aiuto al nostro staff, sarà lieto di consigliarti i migliori prodotti che rispondano alle tue esigenze.<br /><br /></p>
`;

const data_en = `
<h1>FAQ</h1>
<h2>Products</h2>
<p><strong>Are all products on our website always available?</strong><br />Yes, unless otherwise specified. Our wines are available for sale online, and they can also be found in the store.<br /><br /></p>
<h2>Order</h2>
<p><strong>How can I place an order?</strong><br />You can place an order by searching for products that interest you with the website search engine and the selection filters. After finding the products you want, you can click the "Add to cart" button, and when you are ready, click on “Proceed to Purchase” to pay for your order.<br /><br /></p>
<p><strong>Do I have to register to place an order?</strong><br />No, you do not have to enter a complete registration to place an order. Just leave your email address and continue with the purchase. Later on, you will receive an email with a password to log in to your account in the future to use it.<br /><br /></p>
<h2>Payments</h2>
<p><strong>Do you accept payments in cash?</strong><br />We accept credit cards, bank transfers and PayPal.<br /><br /></p>
<p><strong>Accettate il pagamento in contrassegno?</strong><br />No, we do not allow payment in cash on delivery (COD).<br /><br /></p>
<h2>Invoice</h2>
<p><strong>Can you give me an invoice?</strong><br />If you need an invoice, remember to enter the billing address for your invoice during the purchase by filling the specific form. The invoice will be issued after payment and delivery of your order. It is only possible to ask for an invoice during the on-line purchase.<br /><br /></p>
<h2>Shipments</h2>
<p><strong>How are deliveries made?</strong><br />International and national deliveries are handled by DHL express courier, and deliveries in the city of Milan are handled by our staff. During the purchase process, you will be asked to enter your phone number so that we can call you if we need to when delivering your order.<br /><br /></p>
<p><strong>What if nobody is home when the courier comes?</strong><br />If nobody is home to receive your order, the courier will leave a notice and will try again in a few days. For more information about shipments, please click <a href="#">here</a>.<br /><br /></p>
<h2>Gifts</h2>
<p><strong>Can I send a product to a different address?</strong><br />Yes. You can purchase products online and choose a different destination from the one you entered as your address. Your order will be delivered, in the established time frame, to the address you specified.<br /><br /></p>
<p><strong>Can I send a product with a gift hamper?</strong><br />Yes, but gift hampers are available only for shipments in the city of Milan, for a small additional charge.<br /><br /></p>
<p><strong>How can I send one or more gifts?</strong><br />If you want to organize one or more gifts, you can use the form at this link. Specify your personal data, budget, the number of packages, the delivery time frame, your preference of products and a message that you want to send. You can also ask our staff to help you. We would be happy to suggest the best products that meet your needs.<br /><br /></p>
`;


export {data_it, data_en};

