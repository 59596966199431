import React, { useState, useMemo, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useSearchParams, useMatch, useNavigate } from "react-router-dom";
import { useRootStore } from '../hooks/use-stores';
import localized from '../common/localization';

import { getAbsoluteURL } from '../common/common';

const TopSearch = () => {
	const rootStore = useRootStore();
	const productsMatch = useMatch(getAbsoluteURL('/products/*'));
	const productViewMatch = useMatch(getAbsoluteURL('/products/view/*'));
	const [searchParams, setSearchParams] = useSearchParams();
	const [inputVal, setInputVal] = useState('');
	const navigate = useNavigate();

	useEffect( () => {
		console.log('effect search params', searchParams.get('q'));
		let q = searchParams.get('q');
		if (!q) {
			setInputVal('');
		} else if (inputVal != q) {
			setInputVal(q);
		}
	}, [searchParams]);

	const onKeyPress = (e) => {
                if (e.key === 'Enter') {
			search();
                }
	}

	const search = () => {
		console.log('setting search params', { ...searchParams, q: inputVal });
		searchParams.set('q', inputVal);
		/*if (productsMatch && !productViewMatch) {
			setSearchParams(searchParams);
		}
		else {
			//we have to go to /products
			navigate( getAbsoluteURL('/products?'+ searchParams.toString(), rootStore.appLang ) );
		}*/
		navigate( getAbsoluteURL('/products?'+ searchParams.toString(), rootStore.appLang ) );
	}


	return (

		<div className="search-bar">
			<div className="p-inputgroup">
				<InputText value={inputVal} className="" placeholder={localized.Find} onKeyPress={onKeyPress} onChange={(e) => setInputVal(e.target.value)}/>
				<Button icon="pi pi-search" className="" onClick={search} aria-label={localized.Find}/>
			</div>
		</div>

	)
}

export default TopSearch;
