import React, { useState, Component } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, Routes, Route, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { ScrollTop } from 'primereact/scrolltop';
import SiderLogin from './sider-login';
import { getAbsoluteURL } from '../common/common';
import localized from '../common/localization';

import { useAuthStore, useSnipcartStore } from '../hooks/use-stores';
import useOtherLangUrls from '../hooks/use-otherlang-urls';

const ServiceColumn = observer( () => {
	const authStore = useAuthStore();
	const snipcartStore = useSnipcartStore();
	const [getCurrentUrlLocalized, currUrl] = useOtherLangUrls();
	const rootStore = authStore.rootStore;
	const [searchParams, setSearchParams] = useSearchParams();
	const [visibleFullScreen, setVisibleFullScreen] = useState(false);
	const onSidebarClose = () => { 
		setVisibleFullScreen(false)
	}

	return (
	    <React.Fragment>
		<div className="service-column">
		    <div className="fixed">
			{/*<div className="lang-switcher">
			    <Button label="ITA" className={rootStore.appLang == 'it' && 'active-lang'} onClick={() => setLang('it') } aria-label="Italiano"  />
			    <Button label="ENG" className={rootStore.appLang == 'en' && 'active-lang'} onClick={() => setLang('en') } aria-label="English"  />
			</div>*/}
			<div className="lang-switcher-plain">
			    <Link to={getCurrentUrlLocalized(rootStore.appLang == 'it' ? 'en' : 'it')}>
			    	<img src={`${process.env.PUBLIC_URL}/img/globe.svg`} alt="globe" /> {rootStore.appLang == 'it' ? 'English' : 'Italiano'}
			    </Link>
			</div>
		    
			<div className="service-buttons">
			    <a className="service-buttons--button service-buttons--button-cart  p-overlay-badge snipcart-checkout">
				{snipcartStore.itemsCount > 0 &&
				<Badge value={snipcartStore.itemsCount} severity="success" />
				}
				<img src={`${process.env.PUBLIC_URL}/img/cart-circle.svg`} alt=""/>
				<span>{localized.Cart}</span>
			    </a>
			    {authStore.isAuthenticated === false &&
			    <a className="service-buttons--button"  onClick={() => setVisibleFullScreen(true)}>
				<img src={`${process.env.PUBLIC_URL}/img/login-circle.svg`} alt=""/>
				<span>{localized.Login}</span>
			    </a>
			    }
			    {authStore.isAuthenticated &&
			    <Link to={getAbsoluteURL('/profile', authStore.rootStore.appLang)} className="service-buttons--button">
				<img src={`${process.env.PUBLIC_URL}/img/login-circle-logged.svg`} alt=""/>
				<span>{localized.Profile}</span>
			    </Link>
			    }
			</div>
			<ScrollTop  threshold={200} behavior="smooth"/>
		    </div>
		</div>
		<SiderLogin sidebarOpen={visibleFullScreen} onSidebarClose={onSidebarClose} onLoginNavigate={onSidebarClose} />   
	    </React.Fragment>
	)
} );

export default ServiceColumn;
