import React, { useState, useEffect, useMemo, useRef, Component } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import { useAuthStore } from '../hooks/use-stores';
import {Button} from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { getAbsoluteURL, EMAIL_RE } from '../common/common';
import localized from '../common/localization';
import { observer } from 'mobx-react-lite'; 
import { useRootStore } from '../hooks/use-stores';


const RecoverForm = observer( (props) => {

	const rootStore = useRootStore()
    	const lang = useMemo(() => rootStore.appLang, [rootStore.appLang])
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const authStore = useAuthStore();
	const navigate = useNavigate();
	const defaultValues = {
		email: ''
	}
	const { control, formState: { errors }, handleSubmit, reset } = useForm(defaultValues);
	const toast = useRef(null);

        useEffect( () => {
                if (isSubmitted) {
                        navigate(getAbsoluteURL('/auth/login', authStore.rootStore.appLang));
                }
        }, [isSubmitted]);

	const getFormErrorMessage = (name) => {
		return errors[name] && <small className="p-error">{errors[name].message}</small>
	};

	const onSubmit = values => {
		setSubmitting(true);
		authStore.sendPasswordResetEmail(values.email).then( () => {
			toast.current.show({
				severity: 'info'
				,summary: localized.PasswordResetRequested
				,detail: localized.PasswordResetRequestedDetail
				,life: 6500
			});
			setIsSubmitted(true);
		}).catch( (err) => {
			toast.current.show({
				severity: 'warn'
				,summary: localized.Error 
				,detail: localized.PasswordResetError
				,life: 5000
			});
		}).then( () => {
			setSubmitting(false);
		});
	};


	return (<form onSubmit={handleSubmit(onSubmit)} className="">
	<Toast ref={toast} position="top-center"/>
	
	    
		<div className="text-center mb-5">            
		    <div className="text-900 text-3xl font-medium mb-3">{localized.ForgotYourPassword}</div>
		    <span className="text-600 font-medium line-height-3">{localized.WriteYourEmail}</span>
		   
		</div>

		<div>
		    <label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
		    {getFormErrorMessage('email')}
		    <Controller name="email" control={control} rules={{ required: localized.EmailRequired, pattern: { value: EMAIL_RE, message: localized.EmailInvalid }}}
		     render={({ field, fieldState }) => (
                      <InputText id={field.name} type="email" {...field} autoFocus className={classNames('w-full', 'mb-3', { 'p-invalid': fieldState.invalid })} />
		    )} />
		    


		    <Button type="submit" label={localized.Submit} className="w-full p-button-rounded" disabled={submitting}>
		      <i className="pi pi-arrow-right"></i>
		    </Button>
		</div>
	  
	
	</form>)
});

export default RecoverForm;
