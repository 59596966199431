import { useMemo } from 'react';
import { useParams } from "react-router-dom";

const useUrlParamsMap = () => {
	const params = useParams();

	const urlParamsMap = useMemo( () => {
		let toks = params['*'].split(/\/+/);
		return new Map(
			toks.flatMap( (t, ndx, arr) => {
				if ( !t || !arr.hasOwnProperty(ndx+1) || !arr[ndx+1] ) {
					return [];
				}
				if (ndx % 2 == 0) {
					return [ [t, (arr.hasOwnProperty(ndx+1) ? arr[ndx+1] : '-')] ];
				}
				return [];
			})
		);
	}, [params]);

	return [urlParamsMap, params];
}

export default useUrlParamsMap;
