import Breadcrumbs from '../components/breadcrumbs';
import { useRootStore } from '../hooks/use-stores';
import { observer } from 'mobx-react-lite';
import { Helmet } from "react-helmet-async";
import localized from '../common/localization';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';

import { data_it, data_en } from './AboutUs-html';

const AboutUs = observer( function(props) {
	const rootStore = useRootStore();
	const data = rootStore.appLang == 'it' ? data_it:data_en;
	return (
	<>
		<Helmet>
			<title>{localized.AboutUs} | Enoteca Ricciardi Milano</title>
			<script type="application/ld+json">{
			JSON.stringify ({
				"@context": "https://schema.org",
				"@type": "AboutPage",
				"@id": "AboutPage",
				"name": "Chi siamo - Enoteca Ricciardi Milano",
				"description": `${localized.HomeDesc}`
			})
			}</script>
		</Helmet>
		<ScrollToTopOnMount />
		<Breadcrumbs></Breadcrumbs>
		<div className="page-content-container">
			<div className="page-content">
				<div dangerouslySetInnerHTML={{__html: data }}>
				</div>
			</div>			
		</div>
	</>
	);
})

export default AboutUs;
