import React, { useState, useMemo, useEffect, useRef } from 'react';
import localized from '../common/localization';
import { observer } from 'mobx-react-lite'; 
import { useRootStore } from '../hooks/use-stores';
import { Link, useSearchParams } from 'react-router-dom';
import { getAbsoluteURL } from '../common/common';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';

const REDIRECT_DELAY = 6000;

const PaymentResult = observer( (props) => {
	const rootStore = useRootStore()
	const lang = useMemo(() => rootStore.appLang, [rootStore.appLang])
	const [searchParams, setSearchParams] = useSearchParams();
	const redirTimeoutRef = useRef();

	const redir = useMemo( () => {
		return searchParams.get('redir');
	}, [searchParams]);

	const resultStatus = useMemo( () => {
		let r = String(searchParams.get('esito')).toUpperCase();
		return r == 'OK' ? 'success' : (r == 'ANNULLO' ? 'cancelled' : 'error')
	}, [searchParams]);

	useEffect( () => {
		if (resultStatus != 'success') {
			return;
		}
		let t = redirTimeoutRef.current = setTimeout( () => {
			console.log('redirecting to', redir);
			window.location.href = redir;
		}, REDIRECT_DELAY)

		return () => {
			clearTimeout(t);
		}
	}, [searchParams]);

	const clearRedirTimeout = () => {
		clearTimeout(redirTimeoutRef.current);
	}

	return (
	<div className="payment-result-wrapper">
		<ScrollToTopOnMount />
		{resultStatus == 'success' &&
		<div className="payment-result">
			<img src={`${process.env.PUBLIC_URL}/img/green-check.svg`} alt=""/>
			<h2>{localized.PaymentOk}</h2>
			<a onClick={clearRedirTimeout} href={redir} className="p-button p-component mt-4">
				<span className="p-button-icon p-c p-button-icon-left pi pi-arrow-left"></span>
				<span className="p-button-label p-c">Torna al sito</span>
			</a>
		</div>
		}
		{resultStatus == 'cancelled' &&
		<div className="payment-result">
			<img src={`${process.env.PUBLIC_URL}/img/attention.svg`} alt=""/>
			<h2>{localized.PaymentNulled}</h2>
			<a href={redir} className="p-button p-component mt-4">
				<span className="p-button-icon p-c p-button-icon-left pi pi-arrow-left"></span>
				<span className="p-button-label p-c">Torna al carrello</span>
			</a>
		</div>
		}
		{resultStatus == 'error' &&
		<div className="payment-result">
			<img src={`${process.env.PUBLIC_URL}/img/error.svg`} alt=""/>
			<h2>{localized.PaymentError}</h2>
			<a href={redir} className="p-button p-component mt-4">
				<span className="p-button-icon p-c p-button-icon-left pi pi-arrow-left"></span>
				<span className="p-button-label p-c">Torna al carrello</span>
			</a>
		</div>
		}
	</div>
	);
});

export default PaymentResult;
