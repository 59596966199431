import { makeObservable, observable, computed, action } from 'mobx'
import BaseStore from './base-store';

const SERVICE_NAME = 'products';

class ProductStore extends BaseStore {
	filters = [];
	_lastFetch = null;
	_lastLang = null;

	constructor(rootStore, opts) {
		super(rootStore, opts);
		makeObservable(this, {
			// new
			filters: observable.shallow
			,setFilters: action
			,removeFilterByNdx: action
		})
	}

	loadImplementation(opt) {
		return this.stdServiceFindAndSet({
			service: SERVICE_NAME
			,...opt
		}).then( data => {
			this._lastFetch = new Date();
			if (opt.query?._hl) {
				this._lastLang = opt.query?._hl;
			}
			return data;
		});
	}

	getImplementation(id, opt) {
		return this.stdServiceGet(id, {
			service: SERVICE_NAME
			,...opt
		});
	}

	createImplementation(data, params) {
		return this.stdServiceCreate(data, params, { service: SERVICE_NAME });
	}

	patchImplementation(id, data, params) {
		return this.stdServicePatch(id, data, params, { service: SERVICE_NAME });
	}

	removeImplementation(id) {
		return this.stdServiceRemove(id, { service: SERVICE_NAME });
	}

	setFilters(f) {
		this.filters = f;
	}

	removeFilterByNdx(ndx) {
		this.filters.remove( this.filters[ndx] );
	}
}

export default ProductStore;
